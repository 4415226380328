import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../store/action/index";
import { useHistory } from "react-router-dom";
import Terms from "../Assets/Images/term-condition.pdf";
import { Typography } from "@material-ui/core";
import classes from "./Layout.module.css";

const LayoutOwner = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(actionCreator.populateUserData());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length && userData.type !== "owner") {
      //window?.setUser(userData);
      history.push("/dashboard");
    }
  }, [userData]);

  return (
    <>
      {children}{" "}
      <div
        style={{ paddingBottom: 10, textAlign: "center" }}
        className={classes.footerStyle}
      >
        <a
          href={Terms}
          target="_blank"
          style={{ color: "#99999 !important", textDecoration: "none" }}
        >
          <Typography
            component="span"
            style={{ color: "#99999 !important", cursor: "pointer" }}
          >
            © Kisoigne 2021 | Conditions générales de vente
          </Typography>
        </a>
      </div>
    </>
  );
};

export default LayoutOwner;
