import React from "react";
import { Grid, Typography, Card } from "@material-ui/core";
import moment from "moment";

const CardMission = ({ renderRatingStar, mission }) => {
  return (
    <>
      <Card style={{ padding: 20 }}>
        <Grid container>
          <Grid items xs={12} style={{ marginTop: 10 }} align="left">
            <Typography
              component="span"
              style={{ color: "#2A3254", fontSize: 18, fontWeight: "bolder" }}
            >
              {mission?.title}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left">
            <Typography component="span" style={{ color: "#868CA6" }}>
              {`${moment(mission.ending_date).diff(
                moment(mission.starting_date),
                "days"
              )} days`}
              , {mission?.business?.address}
            </Typography>
          </Grid>
          <Grid
            items
            xs={12}
            style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 15 }}
          />
          <Grid items xs={12} align="left" style={{ marginTop: 20 }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: 10,
                }}
              >
                <Typography component="span" style={{ fontWeight: "bolder" }}>
                  {mission?.responsable?.name} {mission?.responsable?.surname}
                </Typography>
              </div>
              {renderRatingStar(
                mission?.reviews?.length && mission?.reviews[0]?.rating
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CardMission;
