import React, { useEffect, useState } from "react";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import { Grid, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import EmptyMission from "../../../Components/BusinessComponents/Missions/EmptyMission";
import { getMissions } from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingApi from "../../../Components/Commons/LoadingButton/LoadingButton";
import CardMissionDraftTemplate from "../../../Components/BusinessComponents/Missions/CardMissionDraftTemplate";
import CardMissionCompletedNotStarted from "../../../Components/BusinessComponents/Missions/CardMissionCompletedNotStarted";
import HeaderSection from "../../../Components/BusinessComponents/HeaderSection";

const Missions = () => {
  const [section, setSection] = useState("not_started");
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [draftMission, setDraftMission] = useState([]);
  const [completedMission, setCompletedMission] = useState([]);
  const [notStartedMission, setNotStartedMission] = useState([]);
  const [templateMission, setTemplateMission] = useState([]);
  const [header, setHeader] = useState([
    {
      action: "not_started",
      text: text.missions.pendingMission,
    },
    {
      action: "completed",
      text: text.missions.holdMission,
    },
    {
      action: "templates",
      text: text.missions.templateMission,
    },
    {
      action: "draft",
      text: text.missions.draft,
    },
  ]);

  const renderEmptyText = () => {
    if (section === "not_started") {
      return text.missions.missingPendingMission;
    }
    if (section === "completed") {
      return text.missions.missingHoldMission;
    }
    if (section === "templates") {
      return text.missions.missingTemplateMission;
    }
    if (section === "draft") {
      return text.missions.missingDraftMission;
    }
  };

  const renderButtonText = () => {
    if (section === "not_started") {
      return text.missions.buttonMission;
    }
    if (section === "completed") {
      return text.missions.buttonMission;
    }
    if (section === "templates") {
      return text.missions.createMission;
    }
    if (section === "draft") {
      return text.missions.createMission;
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (
      (section === "not_started" && !notStartedMission.length) ||
      (section === "draft" && !draftMission.length) ||
      (section === "templates" && !templateMission.length) ||
      (section === "completed" && !completedMission.length)
    ) {
      setErrorApi(false);
      setLoadingApi(true);
      getMissions(section)
        .then((response) => {
          setLoadingApi(false);
          section === "not_started" && setNotStartedMission(response.data);
          section === "draft" && setDraftMission(response.data);
          section === "templates" && setTemplateMission(response.data);
          section === "completed" && setCompletedMission(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    }
  }, [section]);

  const renderCoponentMissions = () => {
    let component = [];
    if (section === "draft" && draftMission.length) {
      component = draftMission.map((mission) => {
        return (
          <Grid
            items
            xs={12}
            md={6}
            style={{ marginTop: 20 }}
            className={"paddingCardMap"}
          >
            <CardMissionDraftTemplate mission={mission} section={section} />
          </Grid>
        );
      });
    }
    if (section === "templates" && templateMission.length) {
      component = templateMission.map((mission) => {
        return (
          <Grid
            items
            xs={12}
            md={6}
            style={{ marginTop: 20 }}
            className={"paddingCardMap"}
          >
            <CardMissionDraftTemplate mission={mission} section={section} />
          </Grid>
        );
      });
    }

    if (section === "not_started" && notStartedMission.length) {
      component = notStartedMission.map((mission) => {
        return (
          <Grid
            items
            xs={12}
            md={6}
            style={{ marginTop: 20 }}
            className={"paddingCardMap"}
          >
            <CardMissionCompletedNotStarted
              mission={mission}
              section={section}
            />
          </Grid>
        );
      });
    }
    if (section === "completed" && completedMission.length) {
      component = completedMission.map((mission) => {
        return (
          <Grid
            items
            xs={12}
            md={6}
            style={{ marginTop: 20 }}
            className={"paddingCardMap"}
          >
            <CardMissionCompletedNotStarted
              mission={mission}
              section={section}
            />
          </Grid>
        );
      });
    }

    if (
      (section === "draft" && !draftMission.length) ||
      (section === "templates" && !templateMission.length) ||
      (section === "not_started" && !notStartedMission.length) ||
      (section === "completed" && !completedMission.length)
    ) {
      return (
        <Grid items xs={12}>
          <EmptyMission
            text={renderEmptyText()}
            buttonText={renderButtonText()}
          />
        </Grid>
      );
    }

    return component;
  };

  return (
    <BusinessLayout xs={12} md={12} lg={10}>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      <Grid
        container
        style={{
          padding: "20px 20px 20px 20px",
        }}
      >
        <HeaderSection
          xs={6}
          md={3}
          setSection={setSection}
          section={section}
          header={header}
        />
        <Grid items xs={12}>
          {!loadingApi ? (
            <Grid container style={{ marginTop: 20 }}>
              {renderCoponentMissions()}
            </Grid>
          ) : (
            <LoadingApi />
          )}
        </Grid>
      </Grid>
    </BusinessLayout>
  );
};

export default Missions;
