import React from "react";
import { Grid, Typography, Card } from "@material-ui/core";
import text from "../../../utils/text";
import { ChevronRight } from "../../../utils/icons";
import moment from "moment";
import { Star, StarEmpty } from "../../../utils/icons";
import { Link, useHistory } from "react-router-dom";
import { SiSymantec } from "react-icons/si";

const CardMissionCompletedNotStarted = ({ mission, section }) => {
  const history = useHistory();

  const renderRatingStar = (rating) => {
    let item = [];
    let itemToAdd = null;
    if (rating) {
      for (let i = 1; i <= 5; i++) {
        if (rating >= i) {
          itemToAdd = (
            <div>
              <Star fill={"#2A3254"} />
            </div>
          );
        } else {
          itemToAdd = (
            <div>
              <StarEmpty />
            </div>
          );
        }

        item.push(itemToAdd);
      }
    }
    return item;
  };

  return (
    <Card style={{ padding: "10px 20px 10px 20px" }}>
      <Grid container>
        <Grid items xs={9} align="left">
          <Grid container>
            <Grid items xs={12} align="left">
              <Typography component="h1" variant="h5">
                {mission?.title}
              </Typography>
            </Grid>
            <Grid items xs={12} align="left">
              <Typography component="span">
                {mission?.business?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          items
          xs={3}
          align="right"
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/mission/${mission.id}`)}
          >
            <ChevronRight fill={"#2A3254"} />
          </div>
        </Grid>
        <Grid
          items
          xs={12}
          style={{
            marginTop: 10,
            backgroundColor: "#F9F9FA",
            padding: "10px 20px 10px 20px",
          }}
        >
          <Grid container>
            <Grid
              items
              xs={12}
              sm={section === "completed" ? 4 : 8}
              align="left"
            >
              <Grid container>
                <Grid items xs={12}>
                  <Typography
                    component="span"
                    style={{ fontSize: 11, color: "#868CA6" }}
                  >
                    {text.missions.referent}
                  </Typography>
                </Grid>
                <Grid items xs={12}>
                  <Typography component="span">
                    {mission?.responsable?.name} {mission?.responsable?.surname}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {section === "completed" ? (
              <Grid items xs={12} md={4} align="left">
                <Grid container>
                  <Grid items xs={12}>
                    <Typography
                      component="span"
                      style={{ fontSize: 11, color: "#868CA6" }}
                    >
                      {text.missions.missionLength}
                    </Typography>
                  </Grid>
                  <Grid items xs={12}>
                    <Typography component="span">
                      {mission.hours} {text.missions.hours}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid items xs={12} sm={4} align="left">
              <Grid container>
                <Grid items xs={12}>
                  <Typography
                    component="span"
                    style={{ fontSize: 11, color: "#868CA6" }}
                  >
                    {text.missions.startMission}
                  </Typography>
                </Grid>
                <Grid items xs={12}>
                  <Typography component="span">
                    {moment(mission?.starting_date).format("DD MMMM yy")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} items style={{ marginTop: 20 }}>
          {section === "not_started" ? (
            <Grid container>
              <Grid xs={1} items align="left">
                <Star
                  fill={
                    mission?.bookings?.filter(
                      (booking) => booking.accepted === "pending"
                    )?.length
                      ? "#FD4953"
                      : "#AEB2C3"
                  }
                />
              </Grid>
              <Grid
                xs={11}
                items
                align="left"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                <Typography
                  component="span"
                  style={{
                    color: mission?.bookings?.filter(
                      (booking) => booking.accepted === "pending"
                    )?.length
                      ? "#FD4953"
                      : "#AEB2C3",
                  }}
                >
                  {mission?.bookings?.filter(
                    (booking) => booking.accepted === "pending"
                  )?.length ? (
                    <Link
                      to={`/mission/${mission.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {
                        mission?.bookings?.filter(
                          (booking) => booking.accepted === "pending"
                        )?.length
                      }{" "}
                      {text.missions.interestedProfessionel}{" "}
                    </Link>
                  ) : (
                    text.missions.professionistNotResponded
                  )}
                </Typography>
              </Grid>
              {mission?.bookings
                ?.filter((booking) => booking.accepted === "accepted")
                ?.map((booking) => {
                  return (
                    <Grid xs={12} style={{ marginTop: 5 }}>
                      <Grid container>
                        <Grid>
                          <SiSymantec color={"green"} size={22} />
                        </Grid>
                        <Grid style={{ marginTop: 5 }}>
                          <Typography>
                            {`${booking?.client?.name} ${booking?.client?.surname} ( candidat sélectionné )`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            <Grid container>
              {mission?.clients.map((client) => {
                return (
                  <>
                    <Grid
                      xs={client.picture ? 3 : 1}
                      items
                      align="left"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {client.picture ? (
                        <img
                          src={"data:image/png;base64," + client.picture}
                          alt="client"
                          style={{ width: 60, height: 60, borderRadius: 100 }}
                        />
                      ) : (
                        <Star fill="red" />
                      )}
                    </Grid>
                    <Grid
                      xs={client.picture ? 9 : 11}
                      items
                      align="left"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            paddingRight: 10,
                          }}
                        >
                          <Typography
                            component="span"
                            style={{
                              color: "#FD4953",
                              fontWeight: "bolder",
                            }}
                          >
                            {client.name}
                            {client.surname}
                          </Typography>
                        </div>
                        {client?.review?.rating ? (
                          renderRatingStar(client?.review?.rating)
                        ) : (
                          <div>
                            <Link
                              to={`/review/mission/${mission.id}/client/${client.id}`}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography
                                component="span"
                                style={{
                                  color: "#AEB2C3",
                                }}
                              >
                                {text.missions.waitEvaluation}
                              </Typography>
                            </Link>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardMissionCompletedNotStarted;
