import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getMissionDetail,
  getMissionDetailByClient,
  getMissionWithClientApproved,
  getMissionWithClientToApproved,
  getMissionWithClientToBeApproved,
} from "../../../Axios/Call/AuthApi";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import text from "../../../utils/text";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import MissionDetailComponent from "../../../Components/BusinessComponents/MissionDetail/MissionDetail";

const MissionDetail = () => {
  const [mission, setMission] = useState({});
  const [clients, setClients] = useState([]);
  const [clientsApproved, setClientsApproved] = useState([]);
  const [client, setClient] = useState({});
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [isToBeAccetpted, setIsTooBeAccepted] = useState(false);
  const { missionId } = useParams();
  const [errorApi, setErrorApi] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    if (missionId) {
      getMissionDetail(missionId)
        .then((response) => {
          setMission(response.data);
        })
        .catch((error) => {});
    }
  }, [missionId]);

  useEffect(() => {
    if (mission.status === "not_started") {
      setLoadingCalendar(true);
      getMissionWithClientToBeApproved(mission.id)
        .then((response) => {
          getMissionWithClientApproved(mission.id)
            .then((response) => {
              setClientsApproved(response.data.clients);
            })
            .catch((error) => {
              setLoadingCalendar(false);
              setErrorApi(true);
            });
          setLoadingCalendar(false);
          setMission({
            ...mission,
            ...response.data.mission,
            clients: response.data.clients,
          });
          setClients(response.data.clients);
        })
        .catch((error) => {
          setLoadingCalendar(false);
          setErrorApi(true);
        });
    }
  }, [mission.status]);

  const changeProfessionistHanlder = (clientId) => {
    setLoadingCalendar(true);
    getMissionDetailByClient(missionId, clientId)
      .then((response) => {
        setLoadingCalendar(false);
        setMission({ ...mission, ...response.data.mission, clients: clients });
        setClient(response.data.client);
        setIsTooBeAccepted(true);
      })
      .catch((error) => {
        setLoadingCalendar(false);
        setErrorApi(true);
      });
  };

  const resetMissionToLive = () => {
    setLoadingCalendar(true);
    getMissionWithClientToBeApproved(mission.id)
      .then((response) => {
        setLoadingCalendar(false);
        setMission({
          ...mission,
          ...response.data.mission,
          clients: response.data.clients,
        });
        setClients(response.data.clients);
        setIsTooBeAccepted(false);
      })
      .catch((error) => {
        setLoadingCalendar(false);
        setErrorApi(true);
      });
  };

  return (
    <BusinessLayout xs={12} md={12} lg={10}>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      <MissionDetailComponent
        mission={mission}
        client={client}
        clients={clients}
        changeProfessionistHanlder={changeProfessionistHanlder}
        isToBeAccetpted={isToBeAccetpted}
        resetMissionToLive={resetMissionToLive}
        loadingCalendar={loadingCalendar}
        clientsApproved={clientsApproved}
      />
    </BusinessLayout>
  );
};

export default MissionDetail;
