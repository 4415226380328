import React, { useState } from "react";
import { Modal, Grid, Button, Typography } from "@material-ui/core";
import classes from "./Businesses.module.css";
import text from "../../../utils/text";
import { useForm } from "react-hook-form";
import TextBoxCustom from "../../FormComponents/TextBoxCustom";
import CommonButton from "../../FormComponents/CommonButton";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import { storeContact } from "../../../Axios/Call/AuthApi";

const ContactModal = ({ isOpenModal, onCloseModal }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  const { register, handleSubmit, errors, control, setError, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormContact = (data) => {
    setLoadingApi(true);
    storeContact({ ...data })
      .then(() => {
        setLoadingApi(false);
        onCloseModal();
      })
      .catch(() => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      <Modal open={isOpenModal} size={"lg"} onClose={() => onCloseModal()}>
        <Grid container className={classes.modalWrapperWeb} justify="center">
          <Grid item xs={12} align="center">
            <Typography component="h1" variant="h5">
              {text.ContactForm.title}
            </Typography>
          </Grid>

          <Grid item xs={12} align="center" style={{ marginTop: 20 }}>
            <Typography component="span">
              {text.ContactForm.description}
            </Typography>
          </Grid>

          <Grid item xs={12} align="center" style={{ marginTop: 20 }}>
            <form onSubmit={handleSubmit(sendFormContact)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextBoxCustom
                    labelCustom={text.ContactForm.placeHolder}
                    type="input"
                    ref={register({
                      required: text.General.required,
                    })}
                    defaultValue=""
                    name="description"
                    error={errors.description}
                  />
                </Grid>
                {errorApi && (
                  <Grid item xs={12} md={12} align="center">
                    <Typography component="span" style={{ color: "red" }}>
                      {text.ContactForm.error}
                    </Typography>
                  </Grid>
                )}
                {loadingApi ? (
                  <LoadingButton />
                ) : (
                  <>
                    <Grid item xs={12} md={6} align="center">
                      <CommonButton text={text.ContactForm.contactUs} />
                    </Grid>
                    <Grid item xs={12} md={6} align="center">
                      <CommonButton
                        text={text.General.anull}
                        isGreyStyle
                        onClick={() => onCloseModal()}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default ContactModal;
