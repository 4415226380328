import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CommonButton from "../../FormComponents/CommonButton";
import { useHistory } from "react-router-dom";
import text from "../../../utils/text";

const Step8 = () => {
  const history = useHistory();
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid xs={12} md={10}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid xs={12} md={12} style={{ marginTop: 30 }}>
            <Typography component="h1" variant="h5" style={{ fontSize: 26 }}>
              {text.createMission.titleNotRegister}
            </Typography>
          </Grid>
          <Grid xs={12} md={6} style={{ marginTop: 20, marginBottom: 30 }}>
            <CommonButton
              text={text.General.finish}
              onClick={() => history.push("/dashboard")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Step8;
