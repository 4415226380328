import React, { useRef, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import classes from "./BusinessComponents.module.css";
import RootRef from "@material-ui/core/RootRef";

const HeaderSection = ({ xs, md, header, section, setSection }) => {
  let textRef = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (textRef?.current?.offsetWidth) {
      setWidth(textRef?.current?.offsetWidth);
    }
  }, [textRef?.current?.offsetWidth]);

  console.log(textRef?.current?.offsetWidth);

  return (
    <>
      {header.map((item, index) => {
        return (
          <Grid
            items
            align="left"
            style={{ position: "relative", paddingLeft: index ? 30 : 0 }}
          >
            {item.action === section ? (
              <RootRef rootRef={textRef}>
                <Typography
                  component="span"
                  onClick={() => setSection(item.action)}
                  style={{
                    color: section === item.action ? "#2A3254" : "#8F96A4",
                    cursor: "pointer",
                  }}
                >
                  {item.text}
                </Typography>
              </RootRef>
            ) : (
              <Typography
                component="span"
                onClick={() => setSection(item.action)}
                style={{
                  color: section === item.action ? "#2A3254" : "#8F96A4",
                  cursor: "pointer",
                }}
              >
                {item.text}
              </Typography>
            )}
            {section === item.action && width ? (
              <div
                className={classes.borderHeaderStyle}
                style={{
                  width: width,
                }}
              />
            ) : null}
          </Grid>
        );
      })}

      <Grid
        items
        xs={12}
        style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 10 }}
      />
    </>
  );
};

export default HeaderSection;
