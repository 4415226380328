import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RecoverPasswordStep1 from "../../../Components/AuthComponents/RecoverPasswordStep1";
import RecoverPasswordStep2 from "../../../Components/AuthComponents/RecoverPasswordStep2";
import { ResetPasswordCheck } from "../../../Axios/Call/ClientCredential";
import AuthLayout from "../../../Components/AuthComponents/AuthLayout";

const RecoverPassword = () => {
  const { token } = useParams();
  const [step, setStep] = useState(1);
  const [checkTokenError, setCheckTokenError] = useState(false);

  useEffect(() => {
    ResetPasswordCheck({ reset_token: token })
      .then(() => {})
      .catch(() => {
        setCheckTokenError(true);
      });
  }, [token]);

  return (
    <AuthLayout md={8} lg={step === 1 ? 7 : 5}>
      {step === 1 && (
        <RecoverPasswordStep1
          continueAction={() => setStep(2)}
          checkTokenError={checkTokenError}
        />
      )}
      {step === 2 && <RecoverPasswordStep2 />}
    </AuthLayout>
  );
};

export default RecoverPassword;
