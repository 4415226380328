import { Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMissionWithClientToBeApproved } from "../../../Axios/Call/AuthApi";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import ClientCard from "../../../Components/BusinessComponents/BusinessDetail/ClientCard";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import text from "../../../utils/text";
import { Back } from "../../../utils/icons";
import { useHistory } from "react-router-dom";

const ProfessionistInterested = () => {
  const { missionId } = useParams();
  const [mission, setMission] = useState({});
  const [clients, setClients] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scroll(0, 0);
    if (missionId) {
      setLoadingApi(true);
      getMissionWithClientToBeApproved(missionId)
        .then((response) => {
          setLoadingApi(false);
          setMission({
            ...response.data.mission,
            clients: response.data.clients,
          });
          setClients(response.data.clients);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  }, [missionId]);

  return (
    <BusinessLayout xs={12} md={12} lg={8}>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {loadingApi ? (
        <LoadingButton />
      ) : (
        <Grid container>
          <div style={{ display: "flex" }}>
            <div
              onClick={() => history.push("/announces")}
              style={{ cursor: "pointer" }}
            >
              <Back fill="#AEB2C3" />
            </div>
            <div>
              <Typography
                component="span"
                style={{
                  textTransform: "uppercase",
                  fontSize: 11,
                  color: "#868CA6",
                }}
              >
                {text.MissionDetailByClient.announces}
              </Typography>
            </div>
          </div>
          <Grid items xs={12} align="left">
            <Typography component="h1" variant="h5">
              {text.ProfessionistIterested.title} {mission?.title}
            </Typography>
          </Grid>
          {clients?.map((client) => {
            return (
              <Grid items xs={12} md={6} style={{ marginTop: 30 }}>
                <ClientCard
                  mission={mission}
                  section="iterest"
                  client={client}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </BusinessLayout>
  );
};

export default ProfessionistInterested;
