import React, { Fragment, useState } from "react";
//------------------ Import Css ----------------------
import classes from "./ChoosingAddress.module.css";
import AlertGeneral from "../AlertGeneral/AlertGeneral";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import text from "../../../utils/text";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontWeight: 700,
    color: "#2A3254",
  },
  input: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontSize: 14,
  },
});

const ChooseAddress = ({ field, error }) => {
  const classes = useStyles();
  const [suggestedAddressByResearch, setSuggestedAddressByResearch] = useState(
    []
  );
  const [address, setAddress] = useState("");
  const [errorApi, setErrorApi] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const onChangeAddressHandler = (query) => {
    fetch(
      "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=" +
        query +
        "&maxSuggestions=5&f=json",
      { method: "GET" }
    )
      .then((res) => (res.ok ? res.json() : null))
      .then((result) => {
        if (result) {
          if ("suggestions" in result) {
            setSuggestedAddressByResearch(result.suggestions);
          } else {
            setSuggestedAddressByResearch([]);
          }
        } else {
        }
      })
      .catch((error) => {
        setErrorApi(true);
      });

    setAddress(query);
  };

  const onPickAddressHandler = (address) => {
    fetch(
      " https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?outSr=4326&forStorage=false&outFields=*&maxLocations=5&singleLine=" +
        address.text +
        "&magicKey=" +
        address.magicKey +
        "&f=json",
      {
        method: "GET",
      }
    )
      .then((res) => (res.ok ? res.json() : null))
      .then((result) => {
        if (result) {
          field.onChange({
            address: result.candidates[0].address,
            longitude: result.candidates[0].location.x,
            latitude: result.candidates[0].location.y,
            city: result.candidates[0].attributes.City,
            cap: result.candidates[0].attributes.Postal,
            region: result.candidates[0].attributes.Region,
          });
          setIsEdit(false);
        } else {
          setErrorApi(true);
        }
      })
      .catch((error) => {
        setErrorApi(true);
        console.log(error);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          description="C'e' stato un errore"
          setTrigger={setErrorApi}
        />
      )}
      <Grid container>
        <Grid items xs={12} align="left">
          <Typography component="span" className={classes.label}>
            {text.CreateBusiness.address}
          </Typography>
        </Grid>
        <Grid items xs={12} align="left">
          {field?.value?.address && !isEdit ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: 10,
              }}
            >
              <div>
                <FaEdit
                  style={{ cursor: "pointer" }}
                  size={22}
                  onClick={() => setIsEdit(true)}
                  color={"#868CA6"}
                />
              </div>
              <div>
                <Typography
                  style={{
                    paddingLeft: 10,
                    color: "#868CA6",
                    fontFamily: '"Helvetica Neue", "Roboto", "-apple-system"',
                  }}
                  component="span"
                >
                  {field?.value?.address}
                </Typography>{" "}
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              {field?.value?.address && (
                <div className="my-auto">
                  <RiDeleteBinLine
                    style={{ cursor: "pointer" }}
                    size={25}
                    color={"red"}
                    onClick={() => setIsEdit(false)}
                  />
                </div>
              )}
              <div style={{ width: "98%" }}>
                <Autocomplete
                  name={"address"}
                  options={suggestedAddressByResearch}
                  getOptionLabel={(option) => option.text}
                  noOptionsText={"pas d'adresse"}
                  onChange={(event, newValue) =>
                    newValue && onPickAddressHandler(newValue)
                  }
                  className={classes.input}
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                  onInputChange={(event) =>
                    onChangeAddressHandler(event.target.value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      name={"address"}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ChooseAddress;
