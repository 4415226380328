import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import text from "../../../utils/text";
import Background from "../../../Assets/Images/Illu-9.png";
import CommonButton from "../../FormComponents/CommonButton";
import { useHistory } from "react-router-dom";

const DashboardRight = () => {
  const history = useHistory();

  return (
    <>
      <Card style={{ padding: "40px 20px 40px 20px" }}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} lg={12} alignItems="center">
            <Typography
              component="span"
              style={{ color: "#2A3254", fontSize: 38 }}
            >
              {text.Dashboard.welcomeToKisogne}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            alignItems="center"
            style={{ marginTop: 20 }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                padding: 40,
              }}
            >
              <Grid item xs={12} sm={8} alignItems="center">
                <Typography component="span" style={{ color: "#3F53AA" }}>
                  {text.Dashboard.noBusinessDescription}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} alignItems="center">
                <CommonButton
                  text={text.Dashboard.startAddBusiness}
                  onClick={() => history.push("/businesses")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default DashboardRight;
