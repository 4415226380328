import React, { useState, useEffect } from "react";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import { Grid, Card, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import Profile from "../../../Components/BusinessComponents/ProfileSection/Profile";
import AccountSettings from "../../../Components/BusinessComponents/ProfileSection/AccountSettings";
import HeaderSection from "../../../Components/BusinessComponents/HeaderSection";
import { useParams } from "react-router-dom";

const ProfileSection = () => {
  const { sectionProfile } = useParams();
  const [section, setSection] = useState("profile");
  const [header, setHeader] = useState([
    {
      action: "profile",
      text: text.ProfileSection.profile,
    },
    {
      action: "account",
      text: text.ProfileSection.accountParameter,
    },
  ]);

  useEffect(() => {
    if (sectionProfile) {
      if (sectionProfile === "account") {
        setSection("account");
      } else {
        setSection("profile");
      }
    }
  }, [sectionProfile]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [section]);

  return (
    <BusinessLayout xs={12} md={12} lg={8}>
      <Grid
        container
        spacing={3}
        style={{
          padding: "20px 20px 20px 20px",
        }}
      >
        <HeaderSection
          xs={6}
          md={3}
          setSection={setSection}
          section={section}
          header={header}
        />
        <Grid items xs={12} md={8} style={{ marginTop: 40 }}>
          {section === "profile" && <Profile />}
          {section === "account" && <AccountSettings />}
        </Grid>
      </Grid>
    </BusinessLayout>
  );
};

export default ProfileSection;
