import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import HeaderSection from "../../../Components/BusinessComponents/HeaderSection";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import LoadingApi from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import EmptyMission from "../../../Components/BusinessComponents/Missions/EmptyMission";
import { getAllClientMissions } from "../../../Axios/Call/AuthApi";
import ClientCard from "../../../Components/BusinessComponents/BusinessDetail/ClientCard";

const Professionists = () => {
  const [section, setSection] = useState("not_started");
  const [missionNotStarted, setMissionNotStarted] = useState([]);
  const [missionLive, setMissionLive] = useState([]);
  const [missionDone, setMissionDone] = useState([]);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [header, setHeader] = useState([
    {
      action: "not_started",
      text: text.Professionists.missionSelected,
    },
    {
      action: "active",
      text: text.OneBusiness.nowInMission,
    },
    {
      action: "completed",
      text: text.OneBusiness.missionDone,
    },
  ]);

  useEffect(() => {
    window.scroll(0, 0);
    if (
      (section === "completed" && !missionDone.length) ||
      (section === "active" && !missionLive.length) ||
      (section === "not_started" && !missionNotStarted.length)
    ) {
      setErrorApi(false);
      setLoadingApi(true);
      getAllClientMissions(section, "accepted")
        .then((response) => {
          section === "active" && setMissionLive(response.data);
          section === "completed" && setMissionDone(response.data);
          section === "not_started" && setMissionNotStarted(response.data);
          setLoadingApi(false);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    }
  }, [section]);

  const renderComponentBusinessDetail = () => {
    let component = [];

    if (
      section === "active" &&
      missionLive.find((mission) => mission.clients.length)
    ) {
      component = missionLive.map((mission) => {
        return mission.clients.map((client) => {
          return (
            <Grid items xs={12} md={6}>
              <ClientCard mission={mission} section={section} client={client} />
            </Grid>
          );
        });
      });
    }

    if (
      section === "completed" &&
      missionDone.find((mission) => mission.clients.length)
    ) {
      component = missionDone.map((mission) => {
        return mission.clients.map((client) => {
          return (
            <Grid items xs={12} md={6}>
              <ClientCard mission={mission} section={section} client={client} />
            </Grid>
          );
        });
      });
    }

    if (
      section === "not_started" &&
      missionNotStarted.find((mission) => mission.clients.length)
    ) {
      component = missionNotStarted.map((mission) => {
        return mission.clients.map((client) => {
          return (
            <Grid items xs={12} md={6}>
              <ClientCard mission={mission} section={section} client={client} />
            </Grid>
          );
        });
      });
    }

    if (
      (section === "active" &&
        !missionLive.find((mission) => mission.clients.length)) ||
      (section === "completed" &&
        !missionDone.find((mission) => mission.clients.length)) ||
      (section === "not_started" &&
        !missionNotStarted.find((mission) => mission.clients.length))
    ) {
      return (
        <Grid items xs={12}>
          <EmptyMission
            text={renderEmptyText()}
            buttonText={renderButtonText()}
          />
        </Grid>
      );
    }

    return component;
  };

  console.log(missionNotStarted?.filter((mission) => mission.clients.length));

  const renderEmptyText = () => {
    if (section === "completed") {
      return text.Professionists.noClient;
    }
    if (section === "active" || section === "not_started") {
      return text.Professionists.noClient;
    }
  };

  const renderButtonText = () => {
    if (section === "completed" || section === "not_started") {
      return text.Professionists.createMission;
    }
    if (section === "active") {
      return text.Professionists.createMission;
    }
  };

  return (
    <BusinessLayout xs={12} md={12} lg={9}>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      <Grid
        container
        style={{
          padding: "20px 20px 20px 20px",
        }}
      >
        <HeaderSection
          xs={6}
          md={3}
          setSection={setSection}
          section={section}
          header={header}
        />
        <Grid items xs={12}>
          {!loadingApi ? (
            <Grid container style={{ marginTop: 20 }}>
              {renderComponentBusinessDetail()}
            </Grid>
          ) : (
            <LoadingApi />
          )}
        </Grid>
      </Grid>
    </BusinessLayout>
  );
};

export default Professionists;
