import { clientCredentialAxios } from "../AxiosInstance";

export const login = (data) => {
  return clientCredentialAxios.post("login", data);
};

export const registerOwner = (data) => {
  return clientCredentialAxios.post("register-owner", data);
};

export const confirmEmail = (data) => {
  return clientCredentialAxios.post("confirm-email", data);
};

export const ResetPasswordEmail = (data) => {
  return clientCredentialAxios.post("reset-password/email", data);
};

export const ResetPasswordCheck = (data) => {
  return clientCredentialAxios.post("reset-password/check", data);
};

export const ResetPassword = (data) => {
  return clientCredentialAxios.post("reset-password", data);
};

//--------------- public api ----------------

export const getSectors = () => {
  return clientCredentialAxios.get("sectors");
};

export const getRoles = () => {
  return clientCredentialAxios.get("roles");
};

export const getJobType = () => {
  return clientCredentialAxios.get("job-types");
};

export const getSectorActivity = () => {
  return clientCredentialAxios.get("sectors-activity");
};
