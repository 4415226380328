import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import text from "../../../utils/text";
import { useForm, Controller } from "react-hook-form";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker";
import moment from "moment";
import { add } from "ramda";

const Step2 = ({
  setStep,
  mission,
  setMission,
  templateMission,
  sectors,
  jobs,
  setChoosenTemplate,
  choosenTemplate,
  setShifts,
}) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setError,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const step2Handler = (data) => {
    let isError = false;
    if (data.starting_date >= data.ending_date) {
      setError("starting_date", {
        message: text.createMission.startingDateError,
      });
      setError("ending_date", { message: text.createMission.endingDateError });
      isError = true;
    }
    if (moment(data.starting_date) < moment()) {
      setError("starting_date", {
        message: text.createMission.startingDateError2,
      });
      isError = true;
    }
    if (!data.sector_activity_id) {
      setError("sector_activity_id", { message: text.General.required });
      isError = true;
    }
    if (!data.job_type_id) {
      setError("job_type_id", { message: text.General.required });
      isError = true;
    }

    if (isError) {
      return;
    }

    setErrorApi(true);
    setLoadingApi(true);
    setMission({
      ...mission,
      ...data,
      job_type: jobs.find(
        (job) =>
          job?.id?.toString() === data.job_type_id ||
          job?.id === data.job_type_id
      ),
    });
    setStep(3);
  };

  useEffect(() => {
    if (Object.keys(mission).length && !Object.keys(choosenTemplate).length) {
      reset({ ...mission });
    }
  }, [mission]);

  useEffect(() => {
    if (watch("template")) {
      setChoosenTemplate({
        ...templateMission.find(
          (template) =>
            template?.id?.toString() === watch("template") ||
            template?.id === watch("template")
        ),
      });
    }
  }, [watch("template")]);

  useEffect(() => {
    if (
      moment(watch("starting_date")) &&
      moment(watch("starting_date")).format("dd mm yyyy") !==
        moment(mission.starting_date).format("dd mm yyyy") &&
      moment(watch("starting_date")).format("dd mm yyyy") !==
        moment().add(1, "days").format("dd mm yyyy")
    ) {
      console.log("iam resetting");
      setShifts([]);
    }
  }, [watch("starting_date")]);

  useEffect(() => {
    if (
      moment(watch("ending_date")) &&
      moment(watch("ending_date")).format("dd mm yyyy") !==
        moment(mission.ending_date).format("dd mm yyyy") &&
      moment(watch("ending_date")).format("dd mm yyyy") !==
        moment().add(10, "days").format("dd mm yyyy")
    ) {
      console.log("iam resetting");
      setShifts([]);
    }
  }, [watch("ending_date")]);

  useEffect(() => {
    if (Object.keys(choosenTemplate).length) {
      setValue("job_type_id", choosenTemplate.job_type_id.toString());
      setValue(
        "sector_activity_id",
        choosenTemplate.sector_activity_id.toString()
      );
      setValue("template", choosenTemplate.id);
    }
  }, [choosenTemplate]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      <Grid items xs={12}>
        <Typography component="h1" variant="h5">
          {mission?.title}
        </Typography>
      </Grid>
      <Grid items xs={12} style={{ marginTop: 40 }}>
        <Card style={{ padding: "40px 20px 60px 20px" }}>
          <form
            onSubmit={handleSubmit(step2Handler)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container align="center" justify="center">
              <Grid items xs={12} sm={8} align="center">
                <Grid container align="center" justify="center">
                  <Grid items xs={12} align="left">
                    {templateMission.length ? (
                      <Controller
                        render={(field) => (
                          <CommonSelect
                            field={field}
                            name={"template"}
                            labelCustom={text.createMission.labelModel}
                            options={templateMission.map((template) => {
                              return { name: template.title, id: template.id };
                            })}
                            error={errors?.template}
                          />
                        )}
                        name="template"
                        defaultValue={""}
                        rules={{}}
                        control={control}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid items xs={12} align="left">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_template"
                          color="primary"
                          inputRef={register({})}
                          value={true}
                          defaultChecked={false}
                        />
                      }
                      label={text.createMission.createModel}
                    />
                  </Grid>
                  <Grid items xs={12} align="left" style={{ marginTop: 15 }}>
                    <Controller
                      render={(field) => (
                        <CommonSelect
                          field={field}
                          name={"job_type_id"}
                          labelCustom={text.createMission.rolesLabel}
                          options={jobs}
                          error={errors?.job_type_id}
                        />
                      )}
                      name="job_type_id"
                      defaultValue={mission.job_type_id ?? ""}
                      rules={{
                        required: text.General.required,
                      }}
                      control={control}
                    />
                  </Grid>
                  <Grid items xs={12} align="left" style={{ marginTop: 15 }}>
                    <Controller
                      render={(field) => (
                        <CommonSelect
                          field={field}
                          name={"sector_activity_id"}
                          labelCustom={text.createMission.activitySectorLabel}
                          options={sectors}
                          error={errors?.sector_activity_id}
                        />
                      )}
                      name="sector_activity_id"
                      defaultValue={mission.sector_activity_id ?? ""}
                      rules={{
                        required: text.General.required,
                      }}
                      control={control}
                    />
                  </Grid>
                  <Grid items xs={12} align="left" style={{ marginTop: 15 }}>
                    <Typography
                      component="span"
                      style={{ fontWeight: "bolder" }}
                    >
                      {text.createMission.datesLabel}
                    </Typography>
                  </Grid>
                  <Grid items xs={12} sm={5} align="left">
                    <Controller
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          name={"starting_date"}
                          error={errors?.starting_date}
                        />
                      )}
                      defaultValue={
                        mission.starting_date ?? moment().add(1, "days")
                      }
                      name={"starting_date"}
                      control={control}
                    />
                  </Grid>
                  <Grid items xs={12} sm={1} align="left" />
                  <Grid items xs={12} sm={5} align="left">
                    <Controller
                      render={(field) => (
                        <CommonDateTimePicker
                          name={"ending_date"}
                          field={field}
                          error={errors?.ending_date}
                        />
                      )}
                      defaultValue={
                        mission.ending_date ?? moment().add(10, "days")
                      }
                      name={"ending_date"}
                      control={control}
                    />
                  </Grid>
                  <Grid items xs={12}>
                    <CommonButton
                      text={text.General.forward}
                      loading={loadingApi}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </>
  );
};

export default Step2;
