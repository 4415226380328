import text from "./text";

// ----------------- Hook Form Validation ---------------

export const formatErrorLetters = (value) => {
  return (
    /^[A-Za-ëíìîïóñòôøõöúùûüßÿçÇÀÁÂÃÄÅÉÈÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÆÑØ][a-zA-Z'áàâåãäæéèêëíìîïóñòôøõöúùûüßÿçÇÀÁÂÃÄÅÉÈÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÆÑØ ]*$/.test(
      value
    ) || text.General.formatError
  );
};

export const formatErrorPhoneNumber = (value) => {
  if (value) {
    return /^\d{9,11}$/.test(value) || text.General.formatError;
  } else {
    return null;
  }
};

export const formatErrorSiret = (value) => {
  return /^\d{14}$/.test(value) || text.General.formatError;
};

export const formatErrorApec = (value) => {
  return value.length === 5 || text.General.formatError;
};

export const formatErrorEmail = (value) => {
  return (
    /^[a-zA-Z0-9]([a-zA-Z0-9._-]+)@(([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,4}|[0-9]{1,3})$/.test(
      value
    ) || text.General.formatError
  );
};

export const emailNotSame = (emailConfirmation, value) => {
  if (value.trim().toLowerCase() !== emailConfirmation.trim().toLowerCase()) {
    return text.General.emailNotMatching;
  }

  return null;
};
