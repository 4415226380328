import React, { useState } from "react";
import { Grid, Typography, Card } from "@material-ui/core";
import text from "../../../utils/text";
import moment from "moment";
import Map from "../../Commons/Map/Map";
import CommonButton from "../../FormComponents/CommonButton";
import Calendar from "../../Commons/Calendar/Calendar";
import { Check, Ban } from "../../../utils/icons";
import {
  createMission,
  createShiftInMission,
  editMissions,
} from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import classes from "./Steps.module.css";

const Step5 = ({ mission, setStep, shifts }) => {
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const isLongTerm =
    moment(mission.ending_date).diff(moment(mission.starting_date), "days") > 7;
  const createNewMissionHandler = () => {
    setLoadingApi(true);
    if (!mission.id) {
      createMission({
        ...mission,
        responsable_id: mission.contact_id,
        is_template: mission.is_template === "true",
        is_long_term_contract: isLongTerm,
        is_draft: false,
        shifts: shifts,
      })
        .then((response) => {
          setLoadingApi(false);
          setStep(6);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    } else {
      editMissions(mission.id, {
        ...mission,
        responsable_id: mission.contact_id,
        is_draft: false,
        is_template: mission.is_template === "true",
        is_long_term_contract: isLongTerm,
        shifts: shifts,
      })
        .then((response) => {
          setLoadingApi(false);
          setStep(6);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  };

  return (
    <Grid container>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      <Grid item xs={12}>
        <Card className={classes.cardStyle}>
          <Grid container>
            <Grid item xs={12} align="left">
              <Typography component="h1" variant="h5">
                {mission?.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              align="left"
              style={{
                marginTop: 30,
                padding: "20px 30px 20px 30px",
                backgroundColor: "#F9F9FA",
              }}
            >
              <Typography component="span">{mission?.description}</Typography>
            </Grid>
            <Grid item xs={12} md={6} align="left" style={{ marginTop: 20 }}>
              <Grid container>
                <Grid item xs={12} align="left">
                  <Typography component="span" style={{ fontWeight: "bolder" }}>
                    {text.createMission.rolesLabel}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography component="span" style={{ color: "#868CA6" }}>
                    {mission?.job_type?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} align="left" style={{ marginTop: 20 }}>
              <Grid container>
                <Grid item xs={12} align="left">
                  <Typography component="span" style={{ fontWeight: "bolder" }}>
                    {text.createMission.datesLabel}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography component="span" style={{ color: "#868CA6" }}>
                    {`${moment(mission.starting_date).format(
                      "dddd DD MMMM yy"
                    )} - ${moment(mission.ending_date).format(
                      "dddd DD MMMM yy"
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 20 }}
            />
            <Grid item xs={12} align="left" style={{ marginTop: 30 }}>
              <Typography component="span" style={{ fontWeight: "bolder" }}>
                {text.createMission.missionHours}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Typography component="span" style={{ color: "#868CA6" }}>
                {text.createMission.missionHoursDescription}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
              <Calendar
                startingDate={mission.starting_date}
                endingDate={mission.ending_date}
                shifts={shifts}
              />
            </Grid>
            <Grid item xs={12} align="left" style={{ marginTop: 30 }}>
              <Typography component="span" style={{ fontWeight: "bolder" }}>
                {text.createMission.businesses}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Typography component="span" style={{ color: "#868CA6" }}>
                {mission?.business?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Typography component="span" style={{ color: "#868CA6" }}>
                {mission?.business?.address}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
              <Map
                latitude={mission?.business?.latitude}
                longitude={mission?.business?.longitude}
              />
            </Grid>
            {mission?.security_description ? (
              <>
                <Grid item xs={12} align="left" style={{ marginTop: 30 }}>
                  <Typography component="span" style={{ fontWeight: "bolder" }}>
                    {text.createMission.securiteLabelPreview}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography component="span">
                    {mission?.security_description}
                  </Typography>
                </Grid>
              </>
            ) : null}
            {mission?.security_description ? (
              <>
                <Grid item xs={12} align="left" style={{ marginTop: 30 }}>
                  <Typography component="span" style={{ fontWeight: "bolder" }}>
                    {text.createMission.accessModalityLabel}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography component="span">
                    {mission?.security_description}
                  </Typography>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} align="left" style={{ marginTop: 20 }}>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  {isLongTerm ? (
                    <Check fill={"#3F53AA"} />
                  ) : (
                    <Ban fill={"#FD4953"} />
                  )}
                </div>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Typography component="span" style={{ color: "#3F53AA" }}>
                    {isLongTerm
                      ? text.MissionDetailByClient.longTerm
                      : text.MissionDetailByClient.notLongTerm}
                  </Typography>
                </div>
              </div>
            </Grid>
            {isLongTerm && (
              <>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    {mission?.weekend_frequency && (
                      <>
                        <Grid xs={12} align="left" style={{ marginTop: 10 }}>
                          <Typography
                            component="span"
                            style={{ fontWeight: "bolder" }}
                          >
                            {text.createMission.weekendFrequency}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="left">
                          <Typography component="span">
                            {mission?.weekend_frequency}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {mission?.weekend_frequency_other && (
                      <Grid item xs={12} align="left">
                        <Typography component="span">
                          {mission?.weekend_frequency_other}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    {mission?.split_time_x_week && (
                      <>
                        <Grid xs={12} align="left" style={{ marginTop: 10 }}>
                          <Typography
                            component="span"
                            style={{ fontWeight: "bolder" }}
                          >
                            {text.createMission.splitTimeLabel}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="left">
                          <Typography component="span">
                            {mission?.split_time_x_week}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {mission?.split_time_x_week_other && (
                      <Grid item xs={12} align="left">
                        <Typography component="span">
                          {mission?.split_time_x_week_other}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    {mission?.job_frequency && (
                      <>
                        <Grid xs={12} align="left" style={{ marginTop: 10 }}>
                          <Typography
                            component="span"
                            style={{ fontWeight: "bolder" }}
                          >
                            {text.createMission.jobTypeLength}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="left">
                          <Typography component="span">
                            {mission?.job_frequency === "full-time"
                              ? text.createMission.fullTime
                              : text.createMission.partTime}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    {mission?.hours_x_week && (
                      <>
                        <Grid xs={12} align="left" style={{ marginTop: 10 }}>
                          <Typography
                            component="span"
                            style={{ fontWeight: "bolder" }}
                          >
                            {text.createMission.hoursWeekly}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="left">
                          <Typography component="span">
                            {mission?.hours_x_week}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  items
                  xs={12}
                  style={{
                    height: 1,
                    backgroundColor: "#D7D9E1",
                    marginTop: 20,
                  }}
                />
              </>
            )}
            <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  {mission?.is_cloths_available ? (
                    <Check fill={"#3F53AA"} />
                  ) : (
                    <Ban fill={"#FD4953"} />
                  )}
                </div>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Typography component="span" style={{ color: "#3F53AA" }}>
                    {mission?.is_cloths_available
                      ? text.MissionDetailByClient.areClothsAvailable
                      : text.MissionDetailByClient.notAreClothsAvailable}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 40 }}
            />
            <Grid item xs={12} align="center">
              <CommonButton
                text={text.createMission.addMission}
                onClick={() => createNewMissionHandler()}
                loading={loadingApi}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <CommonButton
                text={text.createMission.addMissionLate}
                isGreyStyle
                onClick={() => setStep(7)}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Step5;
