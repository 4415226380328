import React from "react";
import { Grid, Card, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import classes from "./Dashboard.module.css";
import ClientCard from "./ClientCard";
import { Link } from "react-router-dom";

const DashboardRightLogin = ({ firstRowData, clients }) => {
  return (
    <Grid container>
      <Grid items xs={12}>
        <Card style={{ padding: 20 }}>
          <Grid container>
            <Grid xs={12} align="left">
              <Typography component="h1" variant="h5">
                {text.Dashboard.missions}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              align="left"
              style={{
                backgroundColor: "#F9F9FA",
                padding: 20,
                paddingLeft: 10,
              }}
              className={classes.marginTopMobile}
            >
              <Grid container>
                <Grid items xs={12}>
                  <Typography
                    component="span"
                    style={{ fontSize: 38, color: "#252C4A" }}
                  >
                    {firstRowData?.number_mission ?? 0}
                  </Typography>
                </Grid>
                <Grid items xs={12}>
                  <Typography component="span" style={{ color: "#353F6A" }}>
                    {text.Dashboard.missionDone}
                  </Typography>
                </Grid>
                <Grid items xs={12}>
                  <Typography component="span" style={{ color: "#AEB2C3" }}>
                    {text.Dashboard.monthsLeft}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              align="left"
              style={{ backgroundColor: "#F9F9FA", padding: 20 }}
              className={classes.marginTopMobile}
            >
              <Grid container>
                <Grid items xs={12}>
                  <Typography
                    component="span"
                    style={{ fontSize: 38, color: "#252C4A" }}
                  >
                    {firstRowData?.hours ?? 0}
                  </Typography>
                </Grid>
                <Grid items xs={12}>
                  <Typography component="span" style={{ color: "#353F6A" }}>
                    {text.Dashboard.hoursMission}
                  </Typography>
                </Grid>
                <Grid items xs={12}>
                  <Typography component="span" style={{ color: "#AEB2C3" }}>
                    {text.Dashboard.monthsLeft}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              align="left"
              style={{ backgroundColor: "#F9F9FA", padding: 20 }}
              className={classes.marginTopMobile}
            >
              <Grid container>
                <Grid items xs={12}>
                  <Typography
                    component="span"
                    style={{ fontSize: 38, color: "#252C4A" }}
                  >
                    {firstRowData?.clientNumber ?? 0}
                  </Typography>
                </Grid>
                <Grid items xs={12}>
                  <Typography component="span" style={{ color: "#353F6A" }}>
                    {text.Dashboard.professionist}
                  </Typography>
                </Grid>
                <Grid items xs={12}>
                  <Typography component="span" style={{ color: "#AEB2C3" }}>
                    {text.Dashboard.monthsLeft}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid items xs={12} style={{ marginTop: 20 }}>
        <Card style={{ padding: 20 }}>
          <Grid container>
            <Grid xs={9}>
              <Typography
                component="h1"
                variant="h5"
                align="left"
                style={{ fontSize: 18 }}
              >
                {text.Dashboard.professionistSection}
              </Typography>
            </Grid>
            <Grid xs={3}>
              <Link to="/professionist" style={{ textDecoration: "none" }}>
                <Typography
                  component="span"
                  align="left"
                  style={{ color: "#FD4953", cursor: "pointer" }}
                >
                  {text.Dashboard.allProfessionist}
                </Typography>
              </Link>
            </Grid>
            <Grid xs={12} style={{ height: 1, backgroundColor: "#F9F9FA" }} />

            <Grid xs={12} style={{ marginTop: 10 }}>
              {clients?.map((client) => {
                return <ClientCard client={client} />;
              })}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DashboardRightLogin;
