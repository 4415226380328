import React, { useEffect, useState } from "react";
import CreateBusinessLayout from "../../../Components/BusinessComponents/CreateBusinessLayout";
import { Button, Grid, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { useForm, Controller } from "react-hook-form";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import {
  getBusiness,
  addBusinessSector,
  editBusinessSector,
  deleteBusinessSector,
} from "../../../Axios/Call/AuthApi";
import { getSectors } from "../../../Axios/Call/ClientCredential";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import { useHistory, useParams } from "react-router-dom";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import ModalGeneral from "../../../Components/Commons/ModalGeneral/ModalGeneral";
import { FaEdit } from "react-icons/fa";
import { IoTrashBinSharp } from "react-icons/io5";

const BusinessesSector = () => {
  const [sectors, setSectors] = useState([]);
  const [sectorsData, setSectorsData] = useState([]);
  const [choosenSector, setChoosenSector] = useState(null);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApiPost, setLoadingApiPost] = useState(false);
  const [errorApiPost, setErrorApiPost] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const history = useHistory();
  const { uuid } = useParams();
  const { register, handleSubmit, errors, control, setError, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormSector = (data) => {
    if (!data?.sector) {
      setError("sector", {
        message: text.General.required,
      });

      return false;
    }
    setLoadingApiPost(true);
    if (choosenSector) {
      editBusinessSector(uuid, choosenSector.business_sector_id, {
        bed_number: data.bed_number,
        sector_id: data.sector,
      })
        .then((response) => {
          setLoadingApiPost(false);
          setSectorsData({
            ...sectorsData,
            sector: sectorsData.sector.map((sector) =>
              sector.business_sector_id === choosenSector.business_sector_id
                ? { ...response.data }
                : { ...sector }
            ),
          });
          setChoosenSector(null);
        })
        .catch((error) => {
          setErrorApiPost(true);
          setLoadingApiPost(false);
        });
    } else {
      addBusinessSector(uuid, {
        bed_number: data.bed_number,
        sector_id: data.sector,
      })
        .then((response) => {
          setLoadingApiPost(false);
          setSectorsData({
            ...sectorsData,
            sector: sectorsData.sector.concat({ ...response.data }),
          });
        })
        .catch((error) => {
          setErrorApiPost(true);
          setLoadingApiPost(false);
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingApi(true);
    getBusiness(uuid)
      .then((response1) => {
        getSectors()
          .then((response2) => {
            setSectors(response2.data);
            setSectorsData(response1.data);
            setLoadingApi(false);
          })
          .catch(() => {
            setErrorApi(true);
            setLoadingApi(false);
          });
      })
      .catch(() => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  }, []);

  useEffect(() => {
    if (choosenSector) {
      reset({
        bed_number: choosenSector.bed_number,
        sector: choosenSector.sector.id,
      });
    }
  }, [choosenSector]);

  const onDeleteSectorHandler = () => {
    deleteBusinessSector(openDeleteModal)
      .then(() => {
        setSectorsData({
          ...sectorsData,
          sector: sectorsData.sector.filter(
            (sector) => sector.business_sector_id !== openDeleteModal
          ),
        });
        setOpenDeleteModal(false);
        setChoosenSector(null);
      })
      .catch(() => {
        setErrorApi(true);
        setOpenDeleteModal(false);
      });
  };

  return (
    <CreateBusinessLayout md={10} lg={8}>
      {openDeleteModal && (
        <ModalGeneral
          title={text.CreateBusiness.modalTitleDelete}
          description={text.CreateBusiness.modalDescriptionDelete}
          isOpenModal={openDeleteModal}
          button1Text={text.General.yes}
          button2Text={text.General.no}
          onClick1={() => onDeleteSectorHandler()}
          onClick2={() => setOpenDeleteModal(false)}
        />
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {errorApiPost && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApiPost}
          description={text.General.alertGeneralError}
        />
      )}
      <Grid container spacing={3} direction="row">
        {loadingApi ? (
          <Grid items xs={12} align="center">
            <LoadingButton />
          </Grid>
        ) : (
          <>
            <Grid items xs={12} align="left">
              <Typography component="h1" variant="h5" align="left">
                {`${text.CreateBusiness.sectorTitle} ${sectorsData.name}`}
              </Typography>
            </Grid>
            <Grid items xs={12} md={6} align="left" style={{ marginTop: 5 }}>
              <Typography
                component="span"
                align="left"
                style={{ color: "#868CA6" }}
              >
                {text.CreateBusiness.sectorDescription}
              </Typography>
            </Grid>
            <Grid items xs={12} md={6} align="left"></Grid>
            <Grid items xs={12} md={6} align="left">
              <form
                onSubmit={handleSubmit(sendFormSector)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container>
                  <Grid items xs={12} md={10} align="left">
                    <Controller
                      render={(field) => (
                        <CommonSelect
                          field={field}
                          name={"sector"}
                          labelCustom={text.CreateBusiness.sectorActivityLabel}
                          options={sectors}
                          error={errors?.sector}
                        />
                      )}
                      name="sector"
                      defaultValue={""}
                      rules={register({
                        required: text.General.required,
                      })}
                      control={control}
                    />
                  </Grid>

                  <Grid item xs={12} md={10} style={{ marginTop: 10 }}>
                    <TextFieldCustom
                      labelCustom={text.CreateBusiness.numberOfSitsLabel}
                      type="number"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="bed_number"
                      error={errors.bed_number}
                    />
                  </Grid>

                  <Grid items xs={12} md={10} align="left">
                    <CommonButton
                      text={text.General.add}
                      loading={loadingApiPost}
                      isGreyStyle
                    />
                  </Grid>
                  <Grid items xs={12} md={10} align="left">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={"buttonGeneralStyle"}
                      style={{ marginTop: 20 }}
                      onClick={() =>
                        history.push(`/business/${uuid}/add-contact`)
                      }
                    >
                      {text.General.forward}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid items xs={12} md={6} style={{ marginTop: 30 }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#EFF0F3",
                  alignItems: "center",
                  padding: 20,
                }}
              >
                {loadingApi ? (
                  <div style={{ paddingTop: "25%", paddingBottom: "25%" }}>
                    <LoadingButton />
                  </div>
                ) : sectorsData?.sector?.length ? (
                  <>
                    <Grid container>
                      <Grid xs={6} items align="left">
                        <Typography
                          component="span"
                          style={{ fontSize: 11, color: "#868CA6" }}
                        >
                          {text.CreateBusiness.sectorActivity}
                        </Typography>
                      </Grid>
                      <Grid xs={6} items align="right">
                        <Typography
                          component="span"
                          style={{ fontSize: 11, color: "#868CA6" }}
                        >
                          {text.CreateBusiness.numberBed}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        items
                        style={{
                          height: 1,
                          backgroundColor: "#868CA6",
                          marginTop: 10,
                          opacity: "50%",
                          marginBottom: 10,
                        }}
                      />
                      {sectorsData?.sector?.map((sector) => {
                        return (
                          <>
                            <Grid xs={6} items align="left" key={sector.id}>
                              <Typography
                                component="span"
                                onClick={() => setChoosenSector(sector)}
                                style={{ cursor: "pointer", color: "#2A3254" }}
                              >
                                {sector.sector.name}
                              </Typography>
                            </Grid>
                            <Grid xs={6} items align="right">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Typography
                                  component="span"
                                  style={{ color: "#2A3254" }}
                                >
                                  {sector.bed_number}
                                </Typography>

                                <div
                                  style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    paddingLeft: 20,
                                  }}
                                >
                                  <IoTrashBinSharp
                                    color="#FD4953"
                                    onClick={() =>
                                      setOpenDeleteModal(
                                        sector.business_sector_id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                    size={18}
                                  />
                                </div>
                                <div
                                  style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginLeft: 10,
                                  }}
                                >
                                  <FaEdit
                                    color="#2A3254"
                                    size={18}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setChoosenSector(sector)}
                                  />
                                </div>
                              </div>
                            </Grid>

                            <Grid
                              xs={12}
                              items
                              style={{
                                height: 1,
                                backgroundColor: "#868CA6",
                                marginTop: 10,
                                opacity: "50%",
                                marginBottom: 10,
                              }}
                            />
                          </>
                        );
                      })}
                    </Grid>
                  </>
                ) : (
                  <div style={{ paddingTop: "30%", paddingBottom: "30%" }}>
                    <Typography component="span" style={{ color: "#868CA6" }}>
                      {`${text.CreateBusiness.sectorMissing}${sectorsData.name}`}
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </CreateBusinessLayout>
  );
};

export default BusinessesSector;
