import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import AuthLayout from "../../../Components/AuthComponents/AuthLayout";
import text from "../../../utils/text";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import Illustration from "../../../Assets/Images/Illu-8.png";
import Header from "../../../Components/Commons/Header";
import { Face1, Face2, Face3, Face4, Face5 } from "../../../utils/icons";
import { useForm } from "react-hook-form";
import TextBoxCustom from "../../../Components/FormComponents/TextBoxCustom";
import { useHistory, useParams } from "react-router-dom";
import { getQuestions, createQuestion } from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";

const Review = () => {
  const [step, setStep] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [client, setClient] = useState({});
  const [errorApi, setErrorApi] = useState(false);
  const [errorGetQuestion, setErrorGetQuestion] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApiPost, setErrorApiPost] = useState(false);
  const { clientId, missionId } = useParams();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  useEffect(() => {
    if (clientId && missionId) {
      window.scroll(0, 0);
      setLoadingApi(true);
      getQuestions(clientId, missionId)
        .then((response) => {
          setLoadingApi(false);
          setQuestions(
            response.data?.questions?.map((question, index) => {
              return { ...question, value: 0, step: index + 2 };
            })
          );
          setClient(response.data.client);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
          setErrorGetQuestion(true);
        });
    }
  }, [clientId, missionId]);

  const changeValueHander = (value, step) => {
    setQuestions(
      questions.map((question) =>
        question.step === step ? { ...question, value: value } : question
      )
    );
  };

  const renderColor = (value, step) => {
    if (questions.find((question) => question.step === step)?.value === value) {
      return "#3F53AA";
    }

    return "#AEB2C3";
  };

  const sendFormReview = (data) => {
    setLoadingApi(true);
    createQuestion(clientId, missionId, {
      description: data.description,
      ratings: JSON.stringify(questions),
    })
      .then((response) => {
        setLoadingApi(false);
        history.push("/dashboard");
      })
      .catch(() => {
        setLoadingApi(false);
        setErrorApiPost(true);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {errorApiPost && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApiPost}
          description={text.General.alertGeneralError}
        />
      )}
      {step === 1 && (
        <AuthLayout md={8} lg={6}>
          {loadingApi ? (
            <LoadingButton />
          ) : (
            <Grid container justify="center">
              {errorGetQuestion ? (
                ""
              ) : (
                <>
                  <Grid items xs={12} md={6}>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ fontSize: 28 }}
                    >
                      {`${text.Review.theMission} ${client?.name} ${text.Review.isFinish}`}
                    </Typography>
                  </Grid>
                  <Grid items xs={12} md={8} style={{ marginTop: 20 }}>
                    <Typography component="span" style={{ color: "#3F53AA" }}>
                      {text.Review.reviewDescription}
                    </Typography>
                  </Grid>
                </>
              )}
              {errorGetQuestion ? (
                ""
              ) : (
                <Grid items xs={12} md={6} style={{ marginTop: 20 }}>
                  <CommonButton
                    text={text.Review.buttonText}
                    onClick={() => setStep(2)}
                  />
                </Grid>
              )}
              <Grid xs={12} style={{ marginTop: 20 }}>
                <img
                  src={Illustration}
                  alt="placeholder"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          )}
        </AuthLayout>
      )}
      {step > 1 && step < 6 && (
        <>
          <Header />
          <Grid container justify="center" style={{ marginTop: 40 }}>
            <Grid item xs={12} md={4}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ fontSize: 26 }}
                  >
                    {
                      questions.find((question) => question.step === step)
                        ?.title
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 30,
                    }}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => changeValueHander(1, step)}
                    >
                      <Face1 fill={renderColor(1, step)} />
                    </div>
                    <div
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => changeValueHander(2, step)}
                    >
                      <Face2 fill={renderColor(2, step)} />
                    </div>
                    <div
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => changeValueHander(3, step)}
                    >
                      <Face3 fill={renderColor(3, step)} />
                    </div>
                    <div
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => changeValueHander(4, step)}
                    >
                      <Face4 fill={renderColor(4, step)} />
                    </div>
                    <div
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => changeValueHander(5, step)}
                    >
                      <Face5 fill={renderColor(5, step)} />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ marginTop: 20 }}>
                  <CommonButton
                    text={text.Review.buttonQuestion}
                    onClick={() => setStep(step + 1)}
                    disabled={
                      !questions.find((question) => question.step === step)
                        ?.value
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {step === 6 && (
        <>
          <Header />
          <form
            onSubmit={handleSubmit(sendFormReview)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container justify="center" style={{ marginTop: 40 }}>
              <Grid item xs={12} md={4}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ fontSize: 26 }}
                    >
                      {text.Review.descriptionReview}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <TextBoxCustom
                      labelCustom={""}
                      placeholder={text.Review.descriptionReview}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="description"
                      error={errors.description}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <CommonButton
                      text={text.Review.finishEvaluation}
                      loading={loadingApi}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
};

export default Review;
