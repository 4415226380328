import { authApiAxios } from "../AxiosInstance";

export const getPublicKeys = async () =>
  (await authApiAxios.get("/public-keys")).data;

/**
 * Send a request to the server to update user's subscription.
 *
 * @param {PushSubscription} subscription
 */
export const updateSubscription = (subscription) => {
  const key = subscription.getKey("p256dh");
  const token = subscription.getKey("auth");
  const contentEncoding = (PushManager.supportedContentEncodings || [
    "aesgcm",
  ])[0];

  const data = {
    endpoint: subscription.endpoint,
    publicKey: key
      ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
      : null,
    authToken: token
      ? btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
      : null,
    contentEncoding,
  };

  return authApiAxios.put("/business/webpush/subscriptions", data);
};

export const me = () => {
  return authApiAxios.get("me");
};

export const checkPassword = (data) => {
  return authApiAxios.post("business/check-password", data);
};

export const getNotifications = () => {
  return authApiAxios.get("me/notifications");
};

export const readNotification = (uid) => {
  return authApiAxios.put(`me/notifications/${uid}`);
};

export const getSettings = () => {
  return authApiAxios.get("me/notification-settings");
};

export const editSettings = (data) => {
  return authApiAxios.put("me/notification-settings", data);
};

export const businesses = () => {
  return authApiAxios.get("business/businesses");
};

export const createBusiness = (data) => {
  return authApiAxios.post("business/business", data);
};

export const editBusiness = (uuid, data) => {
  return authApiAxios.post(`business/business/${uuid}`, data);
};

export const deleteBusiness = (uuid) => {
  return authApiAxios.delete(`business/business/${uuid}`);
};

export const getBusiness = (uuid) => {
  return authApiAxios.get(`business/business/${uuid}`);
};

export const addBusinessSector = (uuid, data) => {
  return authApiAxios.post(`business/business/sector/${uuid}`, data);
};

export const editBusinessSector = (uuid, id, data) => {
  return authApiAxios.post(`business/business/${uuid}/sector/${id}`, data);
};

export const deleteBusinessSector = (id) => {
  return authApiAxios.delete(`business/business/sector/${id}`);
};

export const addResponsable = (data) => {
  return authApiAxios.post(`register-responsable`, data);
};

export const editResponsable = (id, data) => {
  return authApiAxios.post(`/business/edit/responsable/${id}`, data);
};

export const deleteResponsable = (id) => {
  return authApiAxios.delete(`/business/delete/responsable/${id}`);
};

export const getUserPicture = () => {
  return authApiAxios.get(`user/picture`);
};

export const editUser = (data) => {
  return authApiAxios.post(`business/edit/user`, data);
};

export const deleteUser = () => {
  return authApiAxios.delete(`business/delete/user`);
};

export const getDashboard = () => {
  return authApiAxios.get(`business/dashboard`);
};

export const getMissions = (params) => {
  if (params === "not_started" || params === "templates") {
    return authApiAxios.get(`/business/mission?${params}=true&draft=false`);
  } else {
    if (params === "completed") {
      return authApiAxios.get(
        `/business/mission?${params}=true&status=accepted`
      );
    } else {
      return authApiAxios.get(`/business/mission?${params}=true`);
    }
  }
};

export const getMissionsOneBusiness = (params, businessId) => {
  return authApiAxios.get(
    `/business/mission?${params}=true&business_id=${businessId}`
  );
};

export const getClientMissions = (params, businessId, statusClient) => {
  return authApiAxios.get(
    `/business/mission?${params}=true&business_id=${businessId}&status=${statusClient}`
  );
};

export const getAllClientMissions = (params, statusClient) => {
  return authApiAxios.get(
    `/business/mission?${params}=true&status=${statusClient}`
  );
};

export const getMissionWithClientToBeApproved = (missionId) => {
  return authApiAxios.get(
    `/business/mission/${missionId}/clients?status=pending`
  );
};

export const getMissionWithClientApproved = (missionId) => {
  return authApiAxios.get(
    `/business/mission/${missionId}/clients?status=accepted`
  );
};

export const getMissionDetailByClient = (missionId, clientId) => {
  return authApiAxios.get(`/business/mission/${missionId}/clients/${clientId}`);
};

export const getMissionDetail = (missionId) => {
  return authApiAxios.get(`/business/mission/${missionId}`);
};

//Professionist api
export const getProfessionist = (clientId) => {
  return authApiAxios.get(`/business/client/${clientId}`);
};

export const getProfessionistQualificationFile = (qualificationId) => {
  return authApiAxios.get(`/business/client/qualification/${qualificationId}`);
};

export const getProfessionistDocumentFile = (documentId) => {
  return authApiAxios.get(`/business/client/document/${documentId}`);
};

export const getQuestions = (clientId, missionId) => {
  return authApiAxios.get(
    `/business/questions/mission/${missionId}/client/${clientId}`
  );
};

export const createQuestion = (clientId, missionId, data) => {
  return authApiAxios.post(
    `/business/questions/mission/${missionId}/client/${clientId}`,
    data
  );
};

export const createMission = (data) => {
  return authApiAxios.post("/business/mission", data);
};

export const editMissions = (missionId, data) => {
  return authApiAxios.put(`/business/mission/${missionId}`, data);
};

export const createShiftInMission = (missionId, data) => {
  return authApiAxios.post(`/business/mission/${missionId}/shift`, data);
};

export const acceptRefuseClient = (missionId, bookingId, data) => {
  return authApiAxios.put(
    `/business/mission/${missionId}/booking/${bookingId}`,
    data
  );
};

//------- Contact form ------------

export const storeContact = (data) => {
  return authApiAxios.post("/business/contact", data);
};
