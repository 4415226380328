import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import HeaderSection from "../../../Components/BusinessComponents/HeaderSection";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import LoadingApi from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import EmptyMission from "../../../Components/BusinessComponents/Missions/EmptyMission";
import CardMissionCompletedNotStarted from "../../../Components/BusinessComponents/Missions/CardMissionCompletedNotStarted";
import {
  getBusiness,
  getMissionsOneBusiness,
  getClientMissions,
} from "../../../Axios/Call/AuthApi";
import { useParams } from "react-router-dom";
import { Back } from "../../../utils/icons";
import ClientCard from "../../../Components/BusinessComponents/BusinessDetail/ClientCard";
import { useHistory } from "react-router-dom";

const BusinessDetail = () => {
  const [section, setSection] = useState("active");
  const [business, setBusiness] = useState(null);
  const [missionNotStarted, setMissionNotStarted] = useState([]);
  const [missionLive, setMissionLive] = useState([]);
  const [missionDone, setMissionDone] = useState([]);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const history = useHistory();
  const { uuid } = useParams();

  const [header, setHeader] = useState([
    {
      action: "active",
      text: text.OneBusiness.nowInMission,
    },
    {
      action: "completed",
      text: text.OneBusiness.missionDone,
    },
    {
      action: "not_started",
      text: text.OneBusiness.missionLive,
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBusiness(uuid)
      .then((response) => {
        setBusiness(response.data);
      })
      .catch(() => {
        setErrorApi(true);
      });
  }, []);

  useEffect(() => {
    if (
      (section === "not_started" && !missionNotStarted.length) ||
      (section === "completed" && !missionDone.length) ||
      (section === "active" && !missionLive.length)
    ) {
      setErrorApi(false);
      setLoadingApi(true);
      if (section === "not_started") {
        getMissionsOneBusiness(section, business?.id)
          .then((response) => {
            setLoadingApi(false);
            setMissionNotStarted(response.data);
          })
          .catch((error) => {
            setLoadingApi(false);
            setErrorApi(true);
          });
      } else {
        getClientMissions(section, business?.id, "accepted")
          .then((response) => {
            section === "active" && setMissionLive(response.data);
            section === "completed" && setMissionDone(response.data);
            setLoadingApi(false);
          })
          .catch((error) => {
            setLoadingApi(false);
            setErrorApi(true);
          });
      }
    }
  }, [section, business]);

  const renderComponentBusinessDetail = () => {
    let component = [];
    if (section === "not_started" && missionNotStarted.length) {
      component = missionNotStarted.map((mission) => {
        return (
          <Grid items xs={12} md={6} style={{ marginTop: 10, paddingLeft: 15 }}>
            <CardMissionCompletedNotStarted
              mission={mission}
              section={section}
            />
          </Grid>
        );
      });
    }

    if (
      section === "active" &&
      missionLive.find((mission) => mission.clients.length)
    ) {
      component = missionLive.map((mission) => {
        return mission.clients.map((client) => {
          return (
            <Grid items xs={12} md={6} style={{ marginTop: 10 }}>
              <ClientCard mission={mission} section={section} client={client} />
            </Grid>
          );
        });
      });
    }

    if (
      section === "completed" &&
      missionDone.find((mission) => mission.clients.length)
    ) {
      component = missionDone.map((mission) => {
        return mission.clients.map((client) => {
          return (
            <Grid items xs={12} md={6} style={{ marginTop: 10 }}>
              <ClientCard mission={mission} section={section} client={client} />
            </Grid>
          );
        });
      });
    }

    if (
      (section === "not_started" && !missionNotStarted.length) ||
      (section === "active" &&
        !missionLive.find((mission) => mission.clients.length)) ||
      (section === "completed" &&
        !missionDone.find((mission) => mission.clients.length))
    ) {
      return (
        <Grid items xs={12}>
          <EmptyMission
            text={renderEmptyText()}
            buttonText={renderButtonText()}
          />
        </Grid>
      );
    }

    return component;
  };

  const renderEmptyText = () => {
    if (section === "not_started") {
      return text.missions.missingPendingMission;
    }
    if (section === "completed") {
      return text.missions.missingHoldMission;
    }
    if (section === "active") {
      return text.missions.missingTemplateMission;
    }
  };

  const renderButtonText = () => {
    if (section === "not_started") {
      return text.missions.buttonMission;
    }
    if (section === "completed") {
      return text.missions.buttonMission;
    }
    if (section === "active") {
      return text.missions.createMission;
    }
  };

  return (
    <BusinessLayout xs={12} md={12} lg={9}>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      <Grid
        container
        style={{
          padding: "20px 20px 20px 20px",
        }}
      >
        <Grid items xs={12} align="left" style={{ marginLeft: "-25px" }}>
          <div style={{ display: "flex" }}>
            <div
              onClick={() => history.push("/businesses/detail")}
              style={{ cursor: "pointer" }}
            >
              <Back fill="#AEB2C3" />
            </div>
            <div>
              <Typography
                component="span"
                style={{
                  textTransform: "uppercase",
                  fontSize: 11,
                  color: "#868CA6",
                }}
              >
                {text.OneBusiness.business}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid items xs={12} align="left" style={{ marginBottom: 30 }}>
          <Typography component="h1" variant="h5" style={{ fontSize: 38 }}>
            {business?.name}
          </Typography>
        </Grid>
        <HeaderSection
          xs={6}
          md={3}
          setSection={setSection}
          section={section}
          header={header}
        />
        <Grid items xs={12}>
          {!loadingApi ? (
            <Grid container style={{ marginTop: 20 }}>
              {renderComponentBusinessDetail()}
            </Grid>
          ) : (
            <LoadingApi />
          )}
        </Grid>
      </Grid>
    </BusinessLayout>
  );
};

export default BusinessDetail;
