import React, { useState, useEffect } from "react";
import { Grid, Typography, Card } from "@material-ui/core";
import text from "../../../utils/text";
import { useForm } from "react-hook-form";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";

const Step1 = ({ setStep, mission, setMission, choosenTemplate }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const { register, handleSubmit, errors, control, setError, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const step1Handler = (data) => {
    console.log(data);
    setLoadingApi(true);
    setErrorApi(true);
    setMission({ ...mission, ...data });
    setStep(2);
  };

  useEffect(() => {
    if (Object.keys(mission).length) {
      reset({ ...mission });
    }
  }, [mission]);

  useEffect(() => {
    if (Object.keys(choosenTemplate).length) {
      reset({ ...choosenTemplate });
    }
  }, [choosenTemplate]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      <Grid items xs={12}>
        <Typography component="h1" variant="h5" style={{ fontSize: 26 }}>
          {text.createMission.step1Title}
        </Typography>
      </Grid>
      <Grid items xs={12} style={{ marginTop: 40 }}>
        <Card style={{ padding: "40px 20px 60px 20px" }}>
          <form onSubmit={handleSubmit(step1Handler)} noValidate>
            <Grid container align="center" justify="center">
              <Grid items xs={12} sm={8} align="left" style={{ marginTop: 20 }}>
                <TextFieldCustom
                  labelCustom={text.createMission.labelTitle}
                  type="input"
                  ref={register({
                    required: text.General.required,
                  })}
                  place
                  name="title"
                  error={errors.title}
                  placeHolder={"Ex : Aide soignant"}
                />
              </Grid>
              <Grid items xs={8}>
                <CommonButton
                  text={text.General.forward}
                  loading={loadingApi}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </>
  );
};

export default Step1;
