import React from "react";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Logo from "../../Assets/Images/Logo.svg";

const useStyles = makeStyles(() => ({
  root: {
    color: "#FD4953",
    cursor: "pointer",
  },
  contain: {
    paddingTop: 50,
  },
}));

const Header = () => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <div className={styles.contain}>
      <a href="https://www.kisoigne.com" target="_blank">
        <img src={Logo} />
      </a>
    </div>
  );
};

export default Header;
