import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CommonButton from "../../FormComponents/CommonButton";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreator from "../../../store/action/index";

const EmptyMission = ({ text, buttonText }) => {
  const history = useHistory();
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid xs={12} style={{ marginTop: 50 }}>
          <Typography
            component="h1"
            variant="h4"
            style={{ color: "#AEB2C3", fontWeight: "thin", fontSize: 40 }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid xs={12} md={4} style={{ marginTop: 30 }}>
          <CommonButton
            text={buttonText}
            onClick={() => {
              if (!userData.has_to_pay) {
                history.push("/create-mission");
              } else {
                dispatch(actionCreator.setModalToPay(true));
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EmptyMission;
