import React from "react";
import { Typography, Card, Grid } from "@material-ui/core";

const IntentionLetter = ({ intention }) => {
  return (
    <Card style={{ padding: 20 }}>
      <Grid container>
        <Grid items xs={12} align="left">
          <span
            dangerouslySetInnerHTML={{
              __html: intention,
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default IntentionLetter;
