import React, { useState, useEffect } from "react";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import text from "../../../utils/text";
import {
  Grid,
  Typography,
  Card,
  FormControlLabel,
  Checkbox,
  Modal,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { addResponsable, getBusiness } from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import TextBoxCustom from "../../FormComponents/TextBoxCustom";
import TextFieldCustom from "../../FormComponents/TextFieldCustom";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import classes from "./Steps.module.css";
import FormAddContact from "../BusinessContact/FormAddContant";
import { getRoles } from "../../../Axios/Call/ClientCredential";
import moment from "moment";

const Step4 = ({ setStep, setMission, choosenTemplate, mission }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApiPost, setLoadingApiPost] = useState(false);
  const [errorApiPost, setErrorApiPost] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [contacts, setContact] = useState([]);
  const [addNewResponsable, setAddNewResponsable] = useState(false);
  const [roles, setRoles] = useState([]);
  const [contactHasBeenFetched, setContactHasBeenFetched] = useState(false);
  const businesses = useSelector((state) => state.user?.userData?.businesses);
  const userData = useSelector((state) => state.user?.userData);
  const isLongTerm =
    moment(mission.ending_date).diff(moment(mission.starting_date), "days") > 7;
  const weekendFrequency = [
    { id: "Pas de weekend travaillé", name: "Pas de weekend travaillé" },
    { id: "1 sur 2", name: "1 sur 2" },
    { id: "2 sur 3", name: "2 sur 3" },
    { id: "Autre (à préciser)", name: "Autre (à préciser)" },
  ];
  const sliptTimes = [
    { id: "Pas de fractionné", name: "Pas de fractionné" },
    { id: "Tous les jours", name: "Tous les jours" },
    { id: "Seulement le weekend", name: "Seulement le weekend" },
    { id: "Autre (à préciser)", name: "Autre (à préciser)" },
  ];

  const {
    register,
    handleSubmit,
    errors,
    control,
    setError,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const step4Handler = (data) => {
    if (!data.contact_id && userData?.type === "owner") {
      setError("contact_id", { message: text.General.required });
      return;
    }

    if (!data.business_id) {
      setError("business_id", { message: text.General.required });
      return;
    }

    setMission({
      ...mission,
      ...data,
      business: businesses.find(
        (business) =>
          business?.id?.toString() === data.business_id ||
          business?.id === data.business_id
      ),
    });
    setStep(5);
  };

  useEffect(() => {
    //setLoadingApi(true);
    getRoles()
      .then((response2) => {
        setRoles(response2.data);
        //setLoadingApi(false);
      })
      .catch(() => {
        setErrorApi(true);
        //setLoadingApi(false);
      });
  }, []);

  useEffect(() => {
    if (watch("business_id") && userData?.type === "owner") {
      const business = businesses.find(
        (element) =>
          element.id.toString() === watch("business_id") ||
          element.id === watch("business_id")
      );
      setContactHasBeenFetched(false);
      setContact([]);
      setLoadingApi(true);
      getBusiness(business?.uuid)
        .then((response) => {
          setContactHasBeenFetched(true);
          setLoadingApi(false);
          setContact(
            response.data.contacts.map((contact) => {
              return {
                name: `${contact.name} ${contact.surname}`,
                id: contact.id,
              };
            })
          );
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  }, [watch("business_id")]);

  useEffect(() => {
    if (Object.keys(choosenTemplate).length) {
      reset({
        ...choosenTemplate,
      });
    }
  }, [choosenTemplate]);

  useEffect(() => {
    if (Object.keys(mission).length && !Object.keys(choosenTemplate).length) {
      reset({
        ...mission,
      });
    }
  }, [mission]);

  useEffect(() => {
    if (contacts.length) {
      if (Object.keys(choosenTemplate).length) {
        setValue("contact_id", choosenTemplate?.responsable?.id);
      }
      if (Object.keys(mission).length && !Object.keys(choosenTemplate).length) {
        setValue("contact_id", mission?.responsable?.id);
      }
    }
  }, [contacts]);

  const addContactHandler = () => {
    setAddNewResponsable(true);
  };

  const sendFormContact = (data) => {
    setLoadingApiPost(true);
    addResponsable({
      ...data,
      role_id: data.role,
      business_id: watch("business_id"),
      //can_publish_job: data.can_publish_job === "true",
    })
      .then((response) => {
        setLoadingApiPost(false);
        setContact(
          contacts.concat({
            name: `${response.data.name} ${response.data.surname}`,
            id: response.data.id,
          })
        );
        setAddNewResponsable(false);
        setIsEmailError(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setIsEmailError(true);
          setLoadingApiPost(false);
        } else {
          setLoadingApiPost(false);
          setErrorApiPost(true);
          setAddNewResponsable(false);
          setIsEmailError(false);
        }
      });
  };

  return (
    <>
      {errorApiPost && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApiPost}
          description={text.General.alertGeneralError}
        />
      )}
      {addNewResponsable && (
        <Modal
          open={addNewResponsable}
          size={"lg"}
          onClose={() => setAddNewResponsable(false)}
        >
          <Grid container className={classes.modalWrapperWeb} justify="center">
            <Grid xs={12} align="center">
              <Typography
                component="span"
                style={{ fontSize: 18, fontWeight: "bolder" }}
              >
                {`${text.CreateBusiness.contactTitle} 
              ${
                businesses.find(
                  (element) =>
                    element.id.toString() === watch("business_id") ||
                    element.id === watch("business_id")
                )?.name
              }`}
              </Typography>
            </Grid>
            <FormAddContact
              roles={roles}
              isModal
              setAddNewResponsable={setAddNewResponsable}
              sendFormContact={sendFormContact}
              loadingApiPost={loadingApiPost}
              isEmailError={isEmailError}
            />
          </Grid>
        </Modal>
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      <Grid container>
        <Grid items xs={12}>
          <Typography component="h1" variant="h5">
            {mission?.title}
          </Typography>
        </Grid>
        <Grid items xs={12} style={{ marginTop: 40 }}>
          <Card style={{ padding: "40px 20px 60px 20px" }}>
            <form
              onSubmit={handleSubmit(step4Handler)}
              style={{ marginTop: 20 }}
              noValidate
            >
              <Grid container align="center" justify="center">
                <Grid item xs={12} md={8}>
                  <Grid container>
                    <Grid items xs={12} align="left">
                      <Controller
                        render={(field) => (
                          <CommonSelect
                            field={field}
                            name={"business_id"}
                            labelCustom={text.createMission.businessLabel}
                            options={businesses ?? []}
                            error={errors?.business_id}
                          />
                        )}
                        name="business_id"
                        defaultValue={""}
                        rules={{
                          required: text.General.required,
                        }}
                        control={control}
                      />
                    </Grid>
                    <Grid items xs={12} align="left" style={{ marginTop: 15 }}>
                      {loadingApi ? (
                        <LoadingButton />
                      ) : contacts.length && contactHasBeenFetched ? (
                        <Controller
                          render={(field) => (
                            <CommonSelect
                              field={field}
                              name={"contact_id"}
                              labelCustom={text.createMission.contanctReferent}
                              options={contacts ?? []}
                              error={errors?.contact_id}
                            />
                          )}
                          name="contact_id"
                          defaultValue={""}
                          rules={{
                            required: text.General.required,
                          }}
                          control={control}
                        />
                      ) : (
                        ""
                      )}

                      {!contacts.length && contactHasBeenFetched ? (
                        <Typography component="span">
                          {text.createMission.needAContact}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    {userData?.type === "owner" && watch("business_id") ? (
                      <Grid
                        items
                        xs={12}
                        align="center"
                        style={{
                          marginTop: 10,
                          padding: "5px 10px 5px 10px",
                          backgroundColor: "#EFF0F3",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          component="span"
                          onClick={() => addContactHandler()}
                        >
                          {text.createMission.addNewContact}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
                      <TextBoxCustom
                        labelCustom={text.createMission.descriptionLabel}
                        placeholder={text.createMission.descriptionPlaceholder}
                        type="input"
                        ref={register({
                          required: text.General.required,
                        })}
                        defaultValue=""
                        name="description"
                        error={errors.description}
                      />
                    </Grid>
                    <Grid items xs={12} align="left" style={{ marginTop: 15 }}>
                      <TextBoxCustom
                        labelCustom={text.createMission.accessModalityLabel}
                        placeholder={text.createMission.accessPlaceholder}
                        type="input"
                        ref={register({
                          required: text.General.required,
                        })}
                        defaultValue=""
                        name="access_modality"
                        error={errors.access_modality}
                      />
                    </Grid>
                    <Grid items xs={12} align="left" style={{ marginTop: 15 }}>
                      <TextBoxCustom
                        labelCustom={text.createMission.securiteLabel}
                        placeholder={text.createMission.securitePlaceholder}
                        type="input"
                        ref={register({})}
                        defaultValue=""
                        name="security_description"
                        error={errors.security_description}
                      />
                    </Grid>
                    {isLongTerm && (
                      <>
                        <Grid
                          items
                          xs={12}
                          align="left"
                          style={{ marginTop: 20 }}
                        >
                          <Controller
                            render={(field) => (
                              <CommonSelect
                                field={field}
                                name={"weekend_frequency"}
                                labelCustom={
                                  text.createMission.weekendFrequency
                                }
                                options={weekendFrequency ?? []}
                                error={errors?.weekend_frequency}
                              />
                            )}
                            name="weekend_frequency"
                            defaultValue={""}
                            rules={{
                              required: text.General.required,
                            }}
                            control={control}
                          />
                        </Grid>
                        {watch("weekend_frequency") ===
                          "Autre (à préciser)" && (
                          <Grid
                            items
                            xs={12}
                            align="left"
                            style={{ marginTop: 20 }}
                          >
                            <TextBoxCustom
                              placeholder={
                                text.createMission.weekendFrequencyPlaceholder
                              }
                              type="input"
                              ref={register({
                                required: text.General.required,
                              })}
                              defaultValue=""
                              name="weekend_frequency_other"
                              error={errors.weekend_frequency_other}
                            />
                          </Grid>
                        )}
                        <Grid
                          items
                          xs={12}
                          align="left"
                          style={{ marginTop: 20 }}
                        >
                          <Controller
                            render={(field) => (
                              <CommonSelect
                                field={field}
                                name={"split_time_x_week"}
                                labelCustom={text.createMission.splitTimeLabel}
                                options={sliptTimes ?? []}
                                error={errors?.split_time_x_week}
                              />
                            )}
                            name="split_time_x_week"
                            defaultValue={""}
                            rules={{
                              required: text.General.required,
                            }}
                            control={control}
                          />
                        </Grid>
                        {watch("split_time_x_week") ===
                          "Autre (à préciser)" && (
                          <Grid
                            items
                            xs={12}
                            align="left"
                            style={{ marginTop: 20 }}
                          >
                            <TextBoxCustom
                              placeholder={
                                text.createMission.splitTimePlaceholder
                              }
                              type="input"
                              ref={register({
                                required: text.General.required,
                              })}
                              defaultValue=""
                              name="split_time_x_week_other"
                              error={errors.split_time_x_week_other}
                            />
                          </Grid>
                        )}
                        <Grid
                          items
                          xs={12}
                          align="left"
                          style={{
                            marginTop: 50,
                            height: 1,
                            backgroundColor: "#D7D9E1",
                          }}
                        />
                        <Grid
                          item
                          xs={12}
                          align="left"
                          style={{ marginTop: 35 }}
                        >
                          <Controller
                            render={(field) => (
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginRight: 15,
                                  }}
                                >
                                  <Typography
                                    component="span"
                                    style={{ fontWeight: "bolder" }}
                                  >
                                    {text.createMission.jobTypeLength}
                                  </Typography>
                                </div>
                                <div
                                  className={
                                    field.value === "full-time"
                                      ? classes.yesChoosen
                                      : classes.yesNoChoosen
                                  }
                                  onClick={() => field.onChange("full-time")}
                                >
                                  <Typography
                                    component="span"
                                    className={
                                      field.value === "full-time"
                                        ? classes.textChoosen
                                        : classes.textNoChoosen
                                    }
                                  >
                                    {text.createMission.fullTime}
                                  </Typography>
                                </div>
                                <div
                                  className={
                                    field.value === "part-time"
                                      ? classes.noChoosen
                                      : classes.noNoChoosen
                                  }
                                  onClick={() => field.onChange("part-time")}
                                >
                                  <Typography
                                    component="span"
                                    className={
                                      field.value === "part-time"
                                        ? classes.textChoosen
                                        : classes.textNoChoosen
                                    }
                                  >
                                    {text.createMission.partTime}
                                  </Typography>
                                </div>
                              </div>
                            )}
                            name="job_frequency"
                            control={control}
                            defaultValue={mission.job_frequency ?? "full-time"}
                          />
                        </Grid>
                        {watch("job_frequency") === "part-time" && (
                          <Grid
                            items
                            xs={12}
                            sm={9}
                            align="left"
                            style={{ marginTop: 20 }}
                          >
                            <TextFieldCustom
                              labelCustom={text.createMission.hoursWeekly}
                              type="input"
                              ref={register({
                                required: text.General.required,
                              })}
                              place
                              name="hours_x_week"
                              error={errors.hours_x_week}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    <Grid
                      items
                      xs={12}
                      align="left"
                      style={{
                        marginTop: 50,
                        height: 1,
                        backgroundColor: "#D7D9E1",
                      }}
                    />

                    <Grid item xs={12} align="left" style={{ marginTop: 35 }}>
                      <Controller
                        render={(field) => (
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginRight: 15,
                              }}
                            >
                              <Typography
                                component="span"
                                style={{ fontWeight: "bolder" }}
                              >
                                {text.createMission.hasDresses}
                              </Typography>
                            </div>
                            <div
                              className={
                                field.value
                                  ? classes.yesChoosen
                                  : classes.yesNoChoosen
                              }
                              onClick={() => field.onChange(true)}
                            >
                              <Typography
                                component="span"
                                className={
                                  field.value
                                    ? classes.textChoosen
                                    : classes.textNoChoosen
                                }
                              >
                                OUI
                              </Typography>
                            </div>
                            <div
                              className={
                                !field.value
                                  ? classes.noChoosen
                                  : classes.noNoChoosen
                              }
                              onClick={() => field.onChange(false)}
                            >
                              <Typography
                                component="span"
                                className={
                                  !field.value
                                    ? classes.textChoosen
                                    : classes.textNoChoosen
                                }
                              >
                                NON
                              </Typography>
                            </div>
                          </div>
                        )}
                        name="is_cloths_available"
                        control={control}
                        defaultValue={mission.is_cloths_available}
                      />
                    </Grid>
                    {/*<Grid item xs={12} align="left" style={{ marginTop: 35 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="is_long_term_contract"
                            color="primary"
                            inputRef={register({})}
                            value={true}
                            defaultChecked={mission.is_long_term_contract}
                          />
                        }
                        label={
                          <Typography component="span">
                            {text.createMission.longTermJob}
                          </Typography>
                        }
                      />
                      </Grid>*/}
                    <Grid items xs={12} align="left" style={{ marginTop: 15 }}>
                      <CommonButton
                        text={text.General.finish}
                        loading={false}
                        disabled={
                          !contacts.length && userData?.type === "owner"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Step4;
