import React, { useEffect, useState } from "react";
import { Grid, Card, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ModalGeneral from "../../Commons/ModalGeneral/ModalGeneral";
import {
  deleteUser,
  getSettings,
  editSettings,
  getPublicKeys,
  updateSubscription,
} from "../../../Axios/Call/AuthApi";
import { useDispatch } from "react-redux";
import * as actionCreator from "../../../store/action/index";
import { useHistory } from "react-router-dom";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import CommonButton from "../../FormComponents/CommonButton";
import {
  getBroserPushSupport,
  urlBase64ToUint8Array,
} from "../../../utils/utils";

const AccountSettings = () => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [successApi, setSuccessApi] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [settings, setSettings] = useState([
    {
      text: text.AccountSettings.newAnnounceByCollaborator,
      name: "colegue_published_mission",
      email: false,
      database: false,
    },
    {
      text: text.AccountSettings.deleteAnnounce,
      name: "mission_deleted",
      email: false,
      database: false,
    },
    {
      text: text.AccountSettings.noResponse,
      name: "mission_no_attention",
      email: false,
      database: false,
    },
    {
      text: text.AccountSettings.professionistInterese,
      name: "client_interested",
      email: false,
      database: false,
    },
    {
      text: text.AccountSettings.recruited,
      name: "client_hiered",
      email: false,
      database: false,
    },
    {
      text: text.AccountSettings.missionFinishOk,
      name: "mission_finished_ok",
      email: false,
      database: false,
    },
    {
      text: text.AccountSettings.missionFinish,
      name: "mission_finished",
      email: false,
      database: true,
    },
    {
      text: text.AccountSettings.reminderEvaluation,
      name: "can_review",
      email: false,
      database: true,
    },
  ]);

  useEffect(() => {
    getSettings()
      .then((response) => {
        let newSettings = [...settings];
        const obj = response.data;
        Object.keys(obj).forEach((key) => {
          newSettings = newSettings.map((setting) =>
            setting.name === key
              ? {
                  ...setting,
                  database: obj[key].find((el) => el === "database")
                    ? true
                    : false,
                  email: obj[key].find((el) => el === "mail") ? true : false,
                }
              : { ...setting }
          );
        });
        console.log("new setting", newSettings);
        setSettings(newSettings);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  const onClickDeleteAccountHandler = () => {
    deleteUser()
      .then((response) => {
        dispatch(actionCreator.cleanUserData());
        history.push("/");
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const changeSetting = (type, name, value) => {
    setSettings(
      settings.map((setting) =>
        setting.name === name ? { ...setting, [type]: value } : { ...setting }
      )
    );
  };

  const onSendSettingHandler = () => {
    let payload = {};
    settings.forEach((setting) => {
      let arraySetting = [];
      if (setting.database) {
        arraySetting.push("database");
      }
      if (setting.email) {
        arraySetting.push("mail");
      }
      payload = { ...payload, [setting.name]: arraySetting };
    });

    setLoadingApi(true);
    editSettings({ settings: payload })
      .then((response) => {
        setLoadingApi(false);
        setSuccessApi(true);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  };

  const handleActivatePush = async () => {
    setSubscribing(true);
    const { vapid: vapidPublicKey } = await getPublicKeys();
    if (!vapidPublicKey) {
      throw new Error("No vapid public key on the server");
    }

    navigator.serviceWorker.register("/sw.js");
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
    });

    await updateSubscription(subscription);
    setSubscribing(false);
  };

  return (
    <Grid container>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {successApi && (
        <AlertGeneral
          setTrigger={setSuccessApi}
          description={text.AccountSettings.alertSuccessChangeSettings}
        />
      )}
      <ModalGeneral
        isOpenModal={isOpenDeleteModal}
        title={text.AccountSettings.deleteAccountTitleModal}
        description={text.AccountSettings.deleteAccountDescriptionModal}
        button1Text={text.AccountSettings.deleteAccountCta}
        button2Text={text.AccountSettings.deleteAccountCta2}
        onClick1={() => onClickDeleteAccountHandler()}
        onClick2={() => setIsOpenDeleteModal(false)}
      />
      {/*
      <Grid items xs={12} md={8} style={{ marginTop: 30 }} align="left">
        <Typography component="span" style={{ fontWeight: "bolder" }}>
          {text.AccountSettings.pushTitle}
        </Typography>
      </Grid>
      <Grid items xs={12} md={12} align="left">
        <Typography component="span" style={{ color: "#868CA6" }}>
          {text.AccountSettings.pushDescription}
        </Typography>
      </Grid> */}
      {getBroserPushSupport() && Notification.permission === "default" && (
        <Grid items xs={12} md={8} align="left" style={{ marginTop: 20 }}>
          <Typography
            onClick={handleActivatePush}
            component="span"
            style={{
              padding: "10px 15px 10px 15px",
              backgroundColor: "white",
              borderRadius: 10,
              border: "1px solid grey",
              cursor: "pointer",
            }}
          >
            {text.AccountSettings.activePush}
          </Typography>
        </Grid>
      )}
      <Grid
        items
        xs={12}
        style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 30 }}
      />
      <Grid items xs={12} md={10} align="left" style={{ marginTop: 20 }}>
        <Typography component="span" style={{ fontWeight: "bolder" }}>
          {text.AccountSettings.notificationTitle}
        </Typography>
      </Grid>
      <Grid
        items
        xs={12}
        md={2}
        align="right"
        style={{ marginTop: 20, marginLeft: -25 }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            component="span"
            style={{ color: "#868CA6", fontSize: 11 }}
          >
            {text.AccountSettings.email}
          </Typography>
          <Typography
            component="span"
            style={{ color: "#868CA6", paddingLeft: 10, fontSize: 11 }}
          >
            {text.AccountSettings.push}
          </Typography>
        </div>
      </Grid>
      {settings.map((setting) => {
        return (
          <>
            <Grid items xs={8} md={10} align="left" style={{ marginTop: 10 }}>
              <Typography component="span" style={{ color: "#868CA6" }}>
                {setting.text}
              </Typography>
            </Grid>
            <Grid items xs={4} md={2} align="right" style={{ paddingLeft: 20 }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="allowRememberMe"
                        color="primary"
                        value={setting.email}
                        checked={setting.email}
                        onChange={(event) =>
                          changeSetting(
                            "email",
                            setting.name,
                            event.target.checked
                          )
                        }
                      />
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="allowRememberMe"
                        color="primary"
                        value={setting.database}
                        checked={setting.database}
                        onChange={(event) =>
                          changeSetting(
                            "database",
                            setting.name,
                            event.target.checked
                          )
                        }
                      />
                    }
                  />
                </div>
              </div>
            </Grid>
          </>
        );
      })}
      <Grid items xs={8} md={10} align="right" />
      <Grid items xs={4} md={2} align="right">
        <CommonButton
          text={text.General.register}
          onClick={() => onSendSettingHandler()}
          loading={loadingApi}
        />
      </Grid>
      <Grid
        items
        xs={12}
        style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 30 }}
      />
      <Grid items xs={12} md={10} align="left" style={{ marginTop: 20 }}>
        <Typography component="span" style={{ fontWeight: "bolder" }}>
          {text.AccountSettings.kisoigneTitle}
        </Typography>
      </Grid>
      <Grid items xs={10} md={10} align="left" style={{ marginTop: 5 }}>
        <Typography component="span" style={{ color: "#868CA6" }}>
          {text.AccountSettings.kisoigneDescription}
        </Typography>
      </Grid>
      <Grid items xs={2} md={2} align="right" style={{ marginTop: 5 }}>
        <Grid container>
          <Grid items xs={12} md={12} align="right">
            <Typography
              component="span"
              style={{ color: "#868CA6", fontSize: 11 }}
            >
              {text.AccountSettings.email}
            </Typography>
          </Grid>
          <Grid items xs={12} md={12} align="right">
            <FormControlLabel
              control={
                <Checkbox
                  name="allowRememberMe"
                  color="primary"
                  value="yes"
                  defaultChecked={true}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        items
        xs={12}
        style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 30 }}
      />
      <Grid items xs={12} md={10} align="left" style={{ marginTop: 20 }}>
        <Typography
          component="span"
          style={{ fontWeight: "bolder", color: "#FD4953" }}
        >
          {text.AccountSettings.deleteAccountTitle}
        </Typography>
      </Grid>
      <Grid items xs={12} md={10} align="left" style={{ marginTop: 5 }}>
        <Typography component="span" style={{ color: "#868CA6" }}>
          {text.AccountSettings.deleteAccountDesc1}
        </Typography>
      </Grid>
      <Grid items xs={12} md={10} align="left" style={{ marginTop: 10 }}>
        <Typography component="span" style={{ color: "#868CA6" }}>
          {text.AccountSettings.deleteAccountDesc2}
        </Typography>
      </Grid>
      <Grid items xs={12} md={8} align="left" style={{ marginTop: 20 }}>
        <Typography
          component="span"
          style={{
            padding: "10px 15px 10px 15px",
            backgroundColor: "white",
            borderRadius: 10,
            border: "1px solid grey",
            cursor: "pointer",
          }}
          onClick={() => setIsOpenDeleteModal(true)}
        >
          {text.AccountSettings.deleteAccountTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccountSettings;
