import { makeStyles, Menu, Typography, Button } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import text from "../../../utils/text";
import * as R from "ramda";

// import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  selectorContainer: {
    width: 400,
    height: 450,
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateRows: "42px 42px auto 50px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerCol1: {
    borderBottom: "2px solid",
    borderRight: "2px solid",
    borderBottomColor: grey[400],
    borderRightColor: grey[400],
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 1,
  },
  headerCol2: {
    borderBottom: "2px solid",
    borderBottomColor: grey[400],
    gridColumnStart: 3,
    gridColumnEnd: 5,
    gridRowStart: 1,
  },
  footer: {
    gridRowStart: 4,
    gridColumnStart: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    padding: 0,
  },
  rightBorder2: {
    borderRight: "2px solid",
    borderRightColor: grey[400],
  },
  rightBorder1: {
    borderRight: "1px solid",
    borderRightColor: grey[400],
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    borderBottom: "2px solid",
    borderBottomColor: grey[400],
    "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
    "scrollbar-width": "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& > button": {
      border: 0,
      background: "none",
      padding: theme.spacing(2),
      cursor: "pointer",
      transition: "background-color .3s",
      "&:hover": {
        backgroundColor: grey[200],
      },
      "&:disabled": {
        cursor: "not-allowed",
        "&:hover": {
          background: theme.palette.common.white,
        },
        "& > *": {
          color: grey[300],
        },
      },
    },
    "& > button$selectedButton": {
      backgroundColor: grey[300],
    },
  },
  selectedButton: {},
  verticalCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlapError: {
    gridColumnStart: 1,
    gridColumnEnd: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: red[300],
  },
}));

export default function AddHoursMenu({
  anchiorEl,
  onClose,
  onAddShift,
  addMenuTime,
  setAddMenuTime,
  activeDate,
  overlapError,
}) {
  const classes = useStyles();

  const hours = R.times(R.identity, 24);
  const minutes = R.times((i) => (i === 0 ? 0 : R.multiply(5, i)), 12);

  return (
    <Menu
      id="add-time-menu"
      anchorEl={anchiorEl}
      open={Boolean(anchiorEl)}
      onClose={onClose}
      classes={{ list: classes.list }}
    >
      <div className={classes.selectorContainer}>
        <Typography
          className={[classes.headerCol1, classes.verticalCenter].join(" ")}
        >
          {text.createMission.start}
        </Typography>
        <Typography
          className={[classes.headerCol2, classes.verticalCenter].join(" ")}
        >
          {text.createMission.end}
        </Typography>
        <Typography
          className={classes.verticalCenter}
          style={{ gridRowStart: 2 }}
        >
          {text.createMission.hour.toUpperCase()}
        </Typography>
        <Typography
          className={[classes.rightBorder2, classes.verticalCenter].join(" ")}
          style={{ gridRowStart: 2 }}
        >
          {text.createMission.minutes.toUpperCase()}
        </Typography>
        <Typography
          className={classes.verticalCenter}
          style={{ gridRowStart: 2 }}
        >
          {text.createMission.hour.toUpperCase()}
        </Typography>
        <Typography
          className={classes.verticalCenter}
          style={{ gridRowStart: 2 }}
        >
          {text.createMission.minutes.toUpperCase()}
        </Typography>

        <div
          className={[classes.itemContainer, classes.rightBorder1].join(" ")}
        >
          {hours.map((h) => (
            <button
              onClick={() =>
                setAddMenuTime({
                  ...addMenuTime,
                  startHour: h,
                  endHour: addMenuTime.endHour <= h ? -1 : addMenuTime.endHour,
                })
              }
              key={h}
              className={
                addMenuTime.startHour === h ? classes.selectedButton : undefined
              }
            >
              <Typography>{h}</Typography>
            </button>
          ))}
        </div>
        <div
          className={[classes.itemContainer, classes.rightBorder2].join(" ")}
        >
          {minutes.map((m) => (
            <button
              onClick={() => setAddMenuTime({ ...addMenuTime, startMinute: m })}
              key={m}
              className={
                addMenuTime.startMinute === m
                  ? classes.selectedButton
                  : undefined
              }
            >
              <Typography>{m}</Typography>
            </button>
          ))}
        </div>
        <div
          className={[classes.itemContainer, classes.rightBorder1].join(" ")}
        >
          {hours.map((h) => (
            <button
              disabled={h <= addMenuTime.startHour}
              onClick={() => setAddMenuTime({ ...addMenuTime, endHour: h })}
              key={h}
              className={
                addMenuTime.endHour === h ? classes.selectedButton : undefined
              }
            >
              <Typography>{h}</Typography>
            </button>
          ))}
        </div>
        <div className={classes.itemContainer}>
          {minutes.map((m) => (
            <button
              onClick={() => setAddMenuTime({ ...addMenuTime, endMinute: m })}
              key={m}
              className={
                addMenuTime.endMinute === m ? classes.selectedButton : undefined
              }
            >
              <Typography key={m}>{m}</Typography>
            </button>
          ))}
        </div>
        {overlapError && (
          <Typography className={classes.overlapError}>
            {text.createMission.overlapError}
          </Typography>
        )}
        <div className={classes.footer}>
          <Button
            disabled={
              R.any(R.equals(-1), R.values(addMenuTime)) ||
              !activeDate ||
              overlapError
            }
            onClick={onAddShift}
            style={{
              color:
                R.any(R.equals(-1), R.values(addMenuTime)) ||
                !activeDate ||
                overlapError
                  ? grey[300]
                  : "#FD4953",
              fontWeight: "bolder",
              fontFamily: '"Helvetica Neue",Roboto,-apple-system',
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </Menu>
  );
}
