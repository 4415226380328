import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initializeState = {
  dashboard: {},
  isToPay: false,
};

const reducer = (state = initializeState, action) => {
  switch (action.type) {
    case actionTypes.POPULATE_DASHBOARD:
      return updateObject(state, { dashboard: action.dashboard });
    case actionTypes.SET_MODAL_PAY:
      return updateObject(state, { isToPay: action.isToPay });
    default:
      return state;
  }
};

export default reducer;
