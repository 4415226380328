import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const MissionCard = ({ mission }) => {
  return (
    <Grid container style={{ marginTop: 10 }}>
      <Grid items xs={12} style={{ backgroundColor: "#F9F9FA" }}>
        <Grid container style={{ padding: 10 }}>
          <Grid items xs={12}>
            <Link
              to={`/mission/${mission.id}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                component="span"
                style={{ cursor: "pointer", fontWeight: "bolder" }}
              >
                {mission?.title}
              </Typography>
            </Link>
          </Grid>
          <Grid items xs={12}>
            {mission?.business?.name}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MissionCard;
