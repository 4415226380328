import "./App.css";
import Login from "./AuthPages/Login/Login";
import Registration from "./AuthPages/Registration/Registration.js";
//----------- Error ------------
import Page404 from "./ErrorPage/Page404";
import Page403 from "./ErrorPage/Page403";

/* Route */
// eslint-disable-next-line no-unused-vars
import {
  Route,
  Router as BrowserRouter,
  Switch,
  useHistory,
  Redirect,
} from "react-router-dom";

import LayoutNotLogin from "../Hoc/LayoutNotLogin";
import LayoutOwner from "../Hoc/LayoutOwner";
import RecoverPasswordEmail from "./AuthPages/RecoverPasswordEmail/RecoverPasswordEmail";
import RecoverPassword from "./AuthPages/RecoverPassword/RecoverPassword";
import EmailConfirmation from "./AuthPages/EmailConfirmation/EmailConfirmation";
import Dashboard from "./BusinessPages/Dashboard/Dashboard";
import Businesses from "./BusinessPages/Businesses/Businesses";
import LayoutOwnerResponsable from "../Hoc/LayoutOwnerResponsable";
import BusinessesSector from "./BusinessPages/BusinessSectors/BusinessSectors";
import BusinessesContact from "./BusinessPages/BusinessContact/BusinessContact";
import BusinessesDetail from "./BusinessPages/BusinessesDetail/BusinessesDetail";
import ProfileSection from "./BusinessPages/ProfileSection/ProfileSection";
import CreateMission from "./BusinessPages/CreateMission/CreateMission";
import Missions from "./BusinessPages/Missions/Missions";
import BusinessDetail from "./BusinessPages/BusinessDetail/BusinessDetail";
import Professionists from "./BusinessPages/Professionists/Professionists";
import ProfessionistInterested from "./BusinessPages/ProfessiontInterested/ProfessionistInterested";
import ProfessionistMissionDetail from "./BusinessPages/ProfessionistMissionDetail/ProfessionistMissionDetail";
import ProfessionistDetail from "./BusinessPages/ProfessionistDetail/ProfessionistDetail";
import ProfessionistMissionDetailToAccepted from "./BusinessPages/ProfessionistMissionDetailToAccept/ProfessionistMissionDetailToAccepted";
import MissionDetail from "./BusinessPages/MissionDetail/MissionDetail";
import Review from "./BusinessPages/Review/Review";
import "moment/locale/fr";

const isAuthenticated = () => localStorage.getItem("token");
const PublicRoute = (props) => <Route {...props} />;
const PrivateRoute = (props) =>
  isAuthenticated() ? <Route {...props} /> : <Redirect to={"/"} />;

const App = () => {
  const history = useHistory();

  return (
    <div className="App">
      <BrowserRouter history={history} basename={"/"}>
        <Switch>
          <PublicRoute path="/registration">
            <LayoutNotLogin>
              <Registration />
            </LayoutNotLogin>
          </PublicRoute>
          <PublicRoute path="/recover-password">
            <LayoutNotLogin>
              <RecoverPasswordEmail />
            </LayoutNotLogin>
          </PublicRoute>
          <PublicRoute path="/reset-password/:token">
            <LayoutNotLogin>
              <RecoverPassword />
            </LayoutNotLogin>
          </PublicRoute>
          <PublicRoute path="/validate-email/:token">
            <LayoutNotLogin>
              <EmailConfirmation />
            </LayoutNotLogin>
          </PublicRoute>

          <PrivateRoute path="/dashboard">
            <LayoutOwnerResponsable>
              <Dashboard />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute path="/businesses" exact>
            <LayoutOwner>
              <Businesses />
            </LayoutOwner>
          </PrivateRoute>

          <PrivateRoute path="/business/:uuid/add-sector">
            <LayoutOwner>
              <BusinessesSector />
            </LayoutOwner>
          </PrivateRoute>

          <PrivateRoute path="/business/:uuid/add-contact">
            <LayoutOwner>
              <BusinessesContact />
            </LayoutOwner>
          </PrivateRoute>

          <PrivateRoute path="/businesses/detail">
            <LayoutOwner>
              <BusinessesDetail />
            </LayoutOwner>
          </PrivateRoute>

          <PrivateRoute path="/profile/:sectionProfile">
            <LayoutOwnerResponsable>
              <ProfileSection />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute path="/create-mission/:missionId?">
            <LayoutOwnerResponsable>
              <CreateMission />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute path="/announces">
            <LayoutOwnerResponsable>
              <Missions />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute path="/business-detail/:uuid">
            <LayoutOwner>
              <BusinessDetail />
            </LayoutOwner>
          </PrivateRoute>

          <PrivateRoute path="/professionist" exact>
            <LayoutOwnerResponsable>
              <Professionists />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute path="/professionist/iterested/:missionId" exact>
            <LayoutOwnerResponsable>
              <ProfessionistInterested />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute
            path="/professionist/iterested/:missionId/client/:clientId"
            exact
          >
            <LayoutOwnerResponsable>
              <ProfessionistMissionDetailToAccepted />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute path="/professionist-dettail/:clientId" exact>
            <LayoutOwnerResponsable>
              <ProfessionistDetail />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute path="/professionist/:missionId/client/:clientId" exact>
            <LayoutOwnerResponsable>
              <ProfessionistMissionDetail />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute path="/mission/:missionId" exact>
            <LayoutOwnerResponsable>
              <MissionDetail />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PrivateRoute
            path="/review/mission/:missionId/client/:clientId"
            exact
          >
            <LayoutOwnerResponsable>
              <Review />
            </LayoutOwnerResponsable>
          </PrivateRoute>

          <PublicRoute path="/:email?" exact>
            <LayoutNotLogin>
              <Login />
            </LayoutNotLogin>
          </PublicRoute>

          {/*<PublicRoute path="/terms-and-condition" exact>
            <TermAndCondition />
  </PublicRoute>*/}

          <PublicRoute path="/403" exact component={Page403} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
