import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatErrorEmail } from "../../../utils/formValidation";
import text from "../../../utils/text";
import { login } from "../../../Axios/Call/ClientCredential";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../../../store/action/index";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import AuthLayout from "../../../Components/AuthComponents/AuthLayout";

const Login = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailConfirmationError, setEmailConfirmationError] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();
  const { email } = useParams();

  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormLogin = (data) => {
    setLoadingApi(true);
    login(data)
      .then((response) => {
        localStorage.setItem("token", "Bearer " + response.data.token);
        dispatch(actionCreator.populateUserData());
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.email_verified_at) {
        if (userData.type === "owner" || userData.type === "responsable") {
          history.push("/dashboard");
        }
      } else {
        setEmailConfirmationError(true);
        dispatch(actionCreator.cleanUserData());
      }
    }
  }, [userData]);

  useEffect(() => {
    if (
      /^[a-zA-Z0-9]([a-zA-Z0-9._-]+)@(([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,4}|[0-9]{1,3})$/.test(
        email
      )
    ) {
      setValue("email", email);
    }
  }, [email]);

  return (
    <AuthLayout
      md={8}
      lg={4}
      extra={
        <>
          <div style={{ marginTop: 20 }}>
            <Typography component="span" style={{ color: "#2A3254" }}>
              {text.Login.dontHaveAccount}
            </Typography>
          </div>
          <div>
            <Link to="/registration" style={{ textDecoration: "none" }}>
              <Typography component="span" style={{ color: "#AEB2C3" }}>
                {text.Login.register}
              </Typography>
            </Link>
          </div>
        </>
      }
    >
      {emailConfirmationError && (
        <AlertGeneral
          color="danger"
          setTrigger={setEmailConfirmationError}
          description={text.Login.alertError}
        />
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      <Typography component="h1" variant="h5">
        {text.Login.title}
      </Typography>

      <form
        onSubmit={handleSubmit(sendFormLogin)}
        style={{ marginTop: 20 }}
        noValidate
      >
        <Grid container>
          <Grid item xs={12}>
            <TextFieldCustom
              label="Email"
              type="email"
              ref={register({
                required: text.General.required,
                validate: {
                  formatErrorEmail,
                },
              })}
              defaultValue=""
              name="email"
              error={errors.email}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20 }}>
            <TextFieldCustom
              variant="outlined"
              label="Mot de passe"
              type="password"
              ref={register({ required: text.General.required })}
              defaultValue=""
              name={"password"}
              error={errors.password}
            />
          </Grid>

          <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="allowRememberMe"
                  color="primary"
                  inputRef={register({})}
                  value="yes"
                  defaultChecked={false}
                />
              }
              label={<Typography component="span">Rester connecté</Typography>}
            />
          </Grid>

          <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
            {loadingApi ? (
              <LoadingButton />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={"buttonGeneralStyle"}
              >
                {text.General.continue}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <div style={{ marginTop: 30 }}>
        <Link to={"/recover-password"} style={{ textDecoration: "none" }}>
          <Typography component="span" style={{ color: "#3F53AA" }}>
            {text.Login.forgotPassword}
          </Typography>
        </Link>
      </div>
    </AuthLayout>
  );
};

export default Login;
