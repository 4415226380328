import React, { useState, Fragment } from "react";
import moment from "moment";
import * as R from "ramda";
import { Grid, Button, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import text from "../../../utils/text";
import { format } from "date-fns";
import { acceptRefuseClient } from "../../../Axios/Call/AuthApi";
import { useParams } from "react-router-dom";
import AlertGeneral from "../AlertGeneral/AlertGeneral";
import LoadingButton from "../LoadingButton/LoadingButton";
import { useWindowWidth } from "@react-hook/window-size";

const Calendar = ({
  startingDate,
  endingDate,
  shifts,
  clientShift,
  isToBeAccetpted,
  booking,
  isDetailMissionClient,
  resetMissionToLive,
  setCurrentCustomer,
  setSelectedCustomer,
}) => {
  const [currentWeek, setCurrentWeek] = useState(0);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [dontShowAction, setDontShowAction] = useState(false);
  const { missionId } = useParams();
  const windowWidth = useWindowWidth();
  const startMoment = moment(startingDate).hour(0).minute(0);
  const endMoment = moment(endingDate).hour(0).minute(0);

  const lastSunday = moment(endMoment).endOf("week");
  const firstMonday = moment(startMoment).startOf("week");

  const totalDays = lastSunday.diff(firstMonday, "days");

  const weeks = R.splitEvery(
    7,
    R.times(R.identity, totalDays + 1).map((i) =>
      moment(firstMonday).add(i, "days")
    )
  );

  const renderBackgroundColor = (shift) => {
    if (!clientShift) {
      return "#448EF8";
    } else {
      if (clientShift?.find((cliShift) => cliShift?.id === shift?.id)) {
        return "#99CC66";
      } else {
        return "#CCCCCC";
      }
    }
  };

  const fetchShiftForThisDay = (date) => {
    let choosenShift = [];
    let renderShiftBooking = shifts;
    if (isDetailMissionClient) {
      renderShiftBooking = clientShift;
    }
    renderShiftBooking?.forEach((shift) => {
      if (
        moment(shift.start_date).format("DD MM") ===
          moment(date).format("DD MM") &&
        moment(shift.end_date).format("DD MM") === moment(date).format("DD MM")
      ) {
        choosenShift.push(
          <div
            style={{
              marginRight: 10,
              marginTop: 10,
              padding: "5px 20px 5px 20px",
              backgroundColor: renderBackgroundColor(shift),
            }}
          >
            <Typography component="span" style={{ color: "white" }}>
              {`${moment(shift.start_date).format("HH:mm")} - ${moment(
                shift.end_date
              ).format("HH:mm")}`}
            </Typography>
          </div>
        );
      }
    });

    if (choosenShift.length) {
      return choosenShift;
    } else {
      return null;
    }
  };

  const refuseOrAcceptClient = (type) => {
    let data = { accepted: "refuse" };
    if (type === "accepted") {
      data = { accepted: "accepted" };
    }

    setLoadingApi(true);
    acceptRefuseClient(missionId, booking?.id, data)
      .then((response) => {
        setLoadingApi(false);
        setApiSuccess(type === "accepted" ? "accepted" : "refuse");
        setDontShowAction(type === "accepted" ? "accepted" : "refuse");
        resetMissionToLive();
        setSelectedCustomer({});
        setCurrentCustomer(0);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  };

  return (
    <Grid container justify="center">
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {apiSuccess && (
        <AlertGeneral
          setTrigger={setApiSuccess}
          description={
            apiSuccess === "accepted"
              ? text.MissionDetailByClient.clientAccepted
              : text.MissionDetailByClient.clientRefuse
          }
        />
      )}
      {isToBeAccetpted ? (
        <>
          {loadingApi ? (
            <LoadingButton />
          ) : dontShowAction || booking?.accepted !== "pending" ? (
            <Typography component="span" style={{ marginBottom: 20 }}>
              {dontShowAction === "accepted" || booking?.accepted === "accepted"
                ? text.MissionDetailByClient.clientHasBeenAccepted
                : text.MissionDetailByClient.clientHasBeenRefused}
            </Typography>
          ) : (
            <>
              <Grid item xs={6} style={{ marginBottom: "2rem" }} align="center">
                <Button
                  size="medium"
                  style={{
                    backgroundColor: "#99CC66",
                    textTransform: "none",
                    padding: "5px 10px 5px 10px",
                  }}
                  onClick={() => refuseOrAcceptClient("accepted")}
                >
                  <Typography
                    style={{ color: "white", fontSize: 16, fontWeight: "700" }}
                    component="span"
                  >
                    {text.MissionDetailByClient.acceptShift}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: "2rem" }} align="center">
                <Button
                  size="medium"
                  style={{
                    backgroundColor: "#FD4953",
                    textTransform: "none",
                    padding: "5px 10px 5px 10px",
                  }}
                  onClick={() => refuseOrAcceptClient("rejected")}
                >
                  <Typography
                    style={{ color: "white", fontSize: 16, fontWeight: "700" }}
                    component="span"
                  >
                    {text.MissionDetailByClient.rejectShift}
                  </Typography>
                </Button>
              </Grid>
            </>
          )}
        </>
      ) : (
        ""
      )}
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item xs={1}>
            <ArrowBackIosIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                !(currentWeek === 0) && setCurrentWeek((w) => w - 1)
              }
            />
          </Grid>
          <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
            <Typography
              component="span"
              style={{ fontSize: windowWidth < 576 ? 12 : 14 }}
            >
              {text.missions.du} {moment(startingDate).format("DD/MM/YYYY")}{" "}
              {text.missions.au} {moment(endingDate).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid item>
            <ArrowForwardIosIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                !(currentWeek === weeks.length - 1) &&
                setCurrentWeek((w) => w + 1)
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {weeks[currentWeek].map((day) => (
          <Fragment key={day.unix()}>
            <Grid
              container
              style={{
                padding: "5px 10px 5px 10px",
                backgroundColor: "#F9F9FA",
                marginTop: 5,
              }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                    color: fetchShiftForThisDay(day) ? "#2A3254" : "#AEB2C3",
                  }}
                >
                  {day.format("dddd DD/MM")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {fetchShiftForThisDay(day)}
                </div>
              </Grid>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default Calendar;
