import React from "react";
import { Grid, Typography, Card, Avatar } from "@material-ui/core";
import text from "../../../utils/text";
import moment from "moment";
import { Star, ChevronRight, StarEmpty } from "../../../utils/icons";
import { useHistory } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";

const ClientCard = ({ mission, section, client, isPhone }) => {
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const renderRatingStar = () => {
    let item = [];
    let itemToAdd = null;
    if (client.current_rating_client) {
      for (let i = 1; i <= 5; i++) {
        if (client.current_rating_client >= i) {
          itemToAdd = (
            <div>
              <Star fill={"#2A3254"} />
            </div>
          );
        } else {
          itemToAdd = (
            <div>
              <StarEmpty />
            </div>
          );
        }

        item.push(itemToAdd);
      }
    } else {
      item = (
        <div
          style={{ marginTop: "auto", marginBottom: "auto", marginLeft: 10 }}
        >
          <Typography component="span" style={{ color: "#8F96A4" }}>
            {text.General.noRatingYet}
          </Typography>
        </div>
      );
    }
    return item;
  };

  const onClickChevronHandler = (clientId) => {
    if (section === "iterest") {
      history.push(`/professionist-dettail/${clientId}`);
    } else {
      history.push(`/professionist/${mission.id}/client/${clientId}`);
    }
  };

  const renderPercentage = () => {
    const missionLength = moment(mission.ending_date).diff(
      moment(mission.starting_date),
      "days"
    );

    const missionLeft = moment(mission.ending_date).diff(moment(), "days");

    return `${((missionLength - missionLeft) / missionLength) * 100}%`;
  };

  return mission?.clients?.length ? (
    <Grid container>
      <Grid
        items
        xs={12}
        md={12}
        style={{ marginTop: 20 }}
        className={"paddingCardMap"}
      >
        <Card style={{ padding: "10px 20px 10px 20px" }}>
          <Grid container>
            <Grid
              items
              xs={3}
              md={2}
              align="left"
              style={{
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Avatar
                src={client?.picture_path}
                alt="client-profile"
                style={{ width: 50, height: 50, borderRadius: 100 }}
              />
            </Grid>
            <Grid
              items
              xs={9}
              md={10}
              align="left"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <Grid container>
                <Grid
                  items
                  xs={10}
                  align="left"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  <Grid container>
                    <Grid items xs={12} align="left">
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          <Typography
                            component="span"
                            onClick={() =>
                              history.push(
                                `/professionist-dettail/${client.id}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {client.name} {client.surname}
                          </Typography>
                        </div>
                        {windowWidth > 575 && renderRatingStar(client)}
                      </div>
                    </Grid>
                    <Grid items xs={12} align="left">
                      <Typography component="span" style={{ color: "#8F96A4" }}>
                        {mission.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={2}
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                  align="right"
                >
                  <div
                    onClick={() => onClickChevronHandler(client.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <ChevronRight fill="#2A3254" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {windowWidth < 576 && (
              <Grid item xs={12} align={"left"} style={{ marginTop: 5 }}>
                <div style={{ display: "flex" }}>
                  {renderRatingStar(client)}
                </div>
              </Grid>
            )}

            <Grid items align="left" style={{ marginTop: 20 }}>
              {section !== "not_started" && (
                <Typography
                  component="span"
                  style={{ color: "#3F53AA", fontWeight: "bolder" }}
                >
                  {section === "active"
                    ? `${text.OneBusiness.whereInMission} ${mission?.business?.name}`
                    : `${text.OneBusiness.workingDays} ${moment(
                        mission.ending_date
                      ).diff(moment(mission.starting_date), "days")} ${
                        text.OneBusiness.days
                      }`}
                </Typography>
              )}
            </Grid>
            {isPhone && (
              <Grid style={{ paddingLeft: 30, marginTop: 20 }} align="right">
                <Typography
                  component="span"
                  style={{ color: "#3F53AA", fontWeight: "bolder" }}
                >
                  Mobile: {client.mobile_phone}
                </Typography>
              </Grid>
            )}

            <Grid
              items
              xs={12}
              style={{ padding: 20, backgroundColor: "#F9F9FA" }}
              align="left"
            >
              <Grid container>
                <Grid items xs={section === "active" ? 4 : 6}>
                  <Grid container>
                    <Grid items xs={12}>
                      <Typography
                        component="span"
                        style={{ color: "#868CA6", fontSize: 11 }}
                      >
                        {section === "iterest"
                          ? text.OneBusiness.booked
                          : text.OneBusiness.referent}
                      </Typography>
                    </Grid>
                    <Grid items xs={12}>
                      <Typography
                        component="span"
                        style={{ overflowWrap: "break-word" }}
                      >
                        {section === "iterest"
                          ? `${client.booked_hours} ${text.OneBusiness.hours}`
                          : `${mission?.responsable?.name} ${mission?.responsable?.surname}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid items xs={section === "active" ? 5 : 6} align="left">
                  <Grid container>
                    <Grid items xs={12}>
                      <Typography
                        component="span"
                        style={{ color: "#868CA6", fontSize: 11 }}
                      >
                        {text.OneBusiness.missioneLength}
                      </Typography>
                    </Grid>
                    <Grid items xs={12} align="left">
                      <Typography component="span">
                        {section === "active"
                          ? `${moment(mission.ending_date).diff(
                              moment(mission.starting_date),
                              "days"
                            )} ${text.OneBusiness.days}`
                          : section === "iterest"
                          ? `${mission.total_hours} ${text.OneBusiness.hours}`
                          : `${mission.hours} ${text.OneBusiness.hours}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {section === "active" ? (
                  <Grid items xs={3}>
                    <Grid container>
                      <Grid items xs={12}>
                        <Typography
                          component="span"
                          style={{ color: "#868CA6", fontSize: 11 }}
                        >
                          {text.OneBusiness.leftOver}
                        </Typography>
                      </Grid>
                      <Grid items xs={12}>
                        <Typography component="span">
                          {moment(mission.ending_date).diff(moment(), "days")}{" "}
                          {text.OneBusiness.days}`
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {section === "active" ? (
              <Grid
                items
                xs={12}
                style={{
                  borderRadius: 10,
                  backgroundColor: "#D7D9E1",
                }}
              >
                <div
                  style={{
                    width: renderPercentage(),
                    height: 3,
                    backgroundColor: "#FD4953",
                  }}
                ></div>
              </Grid>
            ) : null}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  ) : null;
};

export default ClientCard;
