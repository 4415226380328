import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import CommonButton from "../../FormComponents/CommonButton";
import { useHistory } from "react-router-dom";
import text from "../../../utils/text";
import {
  createMission,
  createShiftInMission,
  editMissions,
} from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import moment from "moment";

const Step7 = ({ setStep, mission, shifts }) => {
  const history = useHistory();
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const createNewMissionHandler = () => {
    setLoadingApi(true);
    if (!mission.id) {
      createMission({
        ...mission,
        responsable_id: mission.contact_id,
        is_draft: true,
        is_template: mission.is_template === "true",
        is_long_term_contract:
          moment(mission.ending_date).diff(
            moment(mission.starting_date),
            "days"
          ) > 7,
        shifts: shifts,
      })
        .then((response) => {
          setLoadingApi(false);
          setStep(8);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    } else {
      editMissions(mission.id, {
        ...mission,
        responsable_id: mission.contact_id,
        is_draft: true,
        is_template: mission.is_template === "true",
        is_long_term_contract:
          moment(mission.ending_date).diff(
            moment(mission.starting_date),
            "days"
          ) > 7,
        shifts: shifts,
      })
        .then((response) => {
          setLoadingApi(false);
          setStep(8);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      <Grid xs={12} md={12}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid xs={12} md={10} style={{ marginTop: 30 }}>
            <Typography component="h1" variant="h5" style={{ fontSize: 26 }}>
              {text.createMission.notTerminatedTitle}
            </Typography>
          </Grid>
          <Grid xs={12} md={8} style={{ marginTop: 10 }}>
            <Typography component="span">
              {text.createMission.notTerminatedDescription}
            </Typography>
          </Grid>
          <Grid xs={12} md={12} style={{ marginTop: 20, marginBottom: 30 }}>
            <Grid container>
              <Grid
                xs={12}
                md={2}
                style={{
                  paddingRight: 10,
                  marginTop: "auto",
                  marginBottom: 10,
                }}
              >
                <Typography
                  component="span"
                  style={{ cursor: "pointer" }}
                  onClick={() => setStep(5)}
                >
                  {text.General.precedent}
                </Typography>
              </Grid>
              <Grid
                xs={12}
                md={5}
                style={{
                  paddingRight: 10,
                  marginBottom: "auto",
                  marginTop: "auto",
                }}
              >
                <CommonButton
                  text={text.createMission.notTeminerClose}
                  onClick={() => history.push("/dashboard")}
                />
              </Grid>
              <Grid
                xs={12}
                md={5}
                style={{
                  paddingRight: 10,
                  marginBottom: "auto",
                  marginTop: "auto",
                }}
              >
                <CommonButton
                  text={text.createMission.notTermineRegister}
                  onClick={() => createNewMissionHandler()}
                  loading={loadingApi}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Step7;
