import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import { Back } from "../../../utils/icons";
import text from "../../../utils/text";
import { useHistory, useParams } from "react-router-dom";
import { Card, Avatar } from "@material-ui/core";
import { Star, StarEmpty } from "../../../utils/icons";
import CardMission from "../../../Components/BusinessComponents/ProfessionistDetail/CardMission";
import {
  getProfessionist,
  getProfessionistDocumentFile,
  getProfessionistQualificationFile,
} from "../../../Axios/Call/AuthApi";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import { useWindowWidth } from "@react-hook/window-size";
import { AiOutlineFile } from "react-icons/ai";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const ProfessionistDetail = () => {
  const history = useHistory();
  const { clientId } = useParams();
  const [client, setClient] = useState({});
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [isOpenDocument, setIsOpenDocument] = useState(true);
  const [file, setFile] = useState({});
  const screenWidth = useWindowWidth();
  const downloadRef = useRef(null);

  useEffect(() => {
    window.scroll(0, 0);
    setLoadingApi(true);
    getProfessionist(clientId)
      .then((response) => {
        setClient(response.data);
        setLoadingApi(false);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  }, []);

  const getQualificationFile = (qualificationId, name) => {
    getProfessionistQualificationFile(qualificationId)
      .then((response) => {
        setFile({ base64: response.data, name });
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const getDocumentFile = (documentId, name) => {
    getProfessionistDocumentFile(documentId)
      .then((response) => {
        setFile({ base64: response.data, name });
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  useEffect(() => {
    if (Object.keys(file).length) {
      downloadRef?.current?.click();
      setFile({});
    }
  }, [file]);

  const renderRatingStar = (rating) => {
    let item = [];
    let itemToAdd = null;
    if (rating) {
      for (let i = 1; i <= 5; i++) {
        if (rating >= i) {
          itemToAdd = (
            <div>
              <Star fill={"#2A3254"} />
            </div>
          );
        } else {
          itemToAdd = (
            <div>
              <StarEmpty />
            </div>
          );
        }

        item.push(itemToAdd);
      }
    } else {
      item = (
        <div
          style={{ marginTop: "auto", marginBottom: "auto", marginLeft: 10 }}
        >
          <Typography component="span" style={{ color: "#8F96A4" }}>
            {text.General.noRatingYet}
          </Typography>
        </div>
      );
    }
    return item;
  };

  return (
    <BusinessLayout xs={12} md={12} lg={9}>
      <a
        style={{ display: "none" }}
        ref={downloadRef}
        href={file?.base64}
        download={file?.name}
      />
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {loadingApi ? (
        <LoadingButton />
      ) : (
        <>
          <Grid container>
            <Grid xs={12} align="left" style={{ marginLeft: "-20px" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/professionist")}
                >
                  <Back fill={"#868CA6"} />
                </div>
                <div style={{ marginleft: 20 }}>
                  <Typography
                    component="span"
                    style={{ color: "#868CA6", fontSize: 12 }}
                  >
                    {text.ClientDetail.professionists}
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid xs={12} style={{ marginTop: 40 }}>
              <Card style={{ padding: 20 }}>
                <Grid container>
                  <Grid item xs={6} md={2} align="left">
                    <Avatar
                      src={client?.picture_path}
                      alt="client-profile"
                      style={{ width: 100, height: 100, borderRadius: 100 }}
                    />
                  </Grid>
                  <Grid item xs={5} align="left">
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          component="h1"
                          variant="h5"
                          style={{ fontWeight: "bolder", fontSize: 38 }}
                        >
                          {client.name} {client.surname}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="span">
                          {client?.profile?.job_type?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="span"
                          style={{ color: "#8F96A4" }}
                        >
                          {client?.profile?.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    align={screenWidth > 575 ? "right" : "center"}
                    className={"marginTopMobileNoDesktop"}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent:
                          screenWidth > 575 ? "flex-end" : "center",
                      }}
                    >
                      <Typography
                        component="span"
                        style={{
                          color: "white",
                          padding: "10px 15px 10px 15px",
                          backgroundColor: "#3F53AA",
                          borderRadius: 25,
                        }}
                      >
                        {`${client?.profile?.price}€/${text.ClientDetail.hoursNet}`}
                      </Typography>
                      <Typography
                        component="span"
                        style={{
                          color: "white",
                          padding: "10px 15px 10px 15px",
                          backgroundColor: "#3F53AA",
                          borderRadius: 25,
                          marginLeft: 10,
                        }}
                      >
                        {`${client?.profile?.experience} ${text.ClientDetail.experience}`}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: 1,
                    backgroundColor: "#D7D9E1",
                    marginTop: 15,
                  }}
                />
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Grid container>
                    <Grid
                      xs={12}
                      md={8}
                      align={screenWidth > 575 ? "left" : "center"}
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <div
                        style={{
                          display: screenWidth > 575 ? "flex" : "block",
                        }}
                      >
                        <div>
                          <Typography
                            component="span"
                            style={{ color: "#FD4953", fontWeight: "bolder" }}
                          >
                            {`${client.missions_total_kisoigne} ${text.ClientDetail.totalMission}`}
                          </Typography>
                        </div>
                        <div className={"marginTopMobileNoDesktop"}>
                          <Typography
                            component="span"
                            style={{
                              color: "#FD4953",
                              fontWeight: "bolder",
                              marginLeft: 20,
                            }}
                          >
                            {`${client.missions_total_business} ${text.ClientDetail.yourMissions}`}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      xs={12}
                      md={4}
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                      align="right"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            screenWidth > 575 ? "flex-end" : "center",
                        }}
                        className={"marginTopMobileNoDesktop"}
                      >
                        {renderRatingStar(client.current_rating_client)}
                        <div
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginLeft: 10,
                          }}
                        >
                          {client.missions_total &&
                            client.current_rating_client && (
                              <Typography
                                component="span"
                                style={{ color: "#8F96A4" }}
                              >{`${
                                client.current_rating_client
                                  ? client.current_rating_client + "/5"
                                  : ""
                              } - ${client.missions_total} ${
                                text.ClientDetail.missions
                              }`}</Typography>
                            )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: 1,
                    backgroundColor: "#D7D9E1",
                    marginTop: 15,
                  }}
                />
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ marginTop: 40 }}
                  align="left"
                >
                  <Typography component="span" style={{ color: "#353F6A" }}>
                    {client?.profile?.description}
                  </Typography>
                </Grid>
                {client?.profile?.working_preference ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 20,
                      padding: 20,
                      backgroundColor: "#EEF0F5",
                    }}
                  >
                    <Grid container>
                      <Grid xs={12} item align="left">
                        <Typography
                          component="span"
                          style={{ color: "#AEB2C3" }}
                        >
                          {text.ClientDetail.preference}
                        </Typography>
                      </Grid>
                      <Grid xs={12} item align="left">
                        <Typography
                          component="span"
                          style={{ color: "#353F6A" }}
                        >
                          {client?.profile?.working_preference}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Card>
            </Grid>

            <Grid xs={12} style={{ marginTop: 40 }}>
              <Card style={{ padding: 20 }}>
                <Grid container>
                  <Grid item xs={10} style={{ marginTop: 20 }}>
                    <Typography variant="h5" align="left">
                      {text.ClientDetail.documents}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: 20 }} align="right">
                    {isOpenDocument ? (
                      <BsChevronDown
                        size={33}
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsOpenDocument(false)}
                      />
                    ) : (
                      <BsChevronUp
                        size={33}
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsOpenDocument(true)}
                      />
                    )}
                  </Grid>
                  {isOpenDocument && (
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{
                          height: 1,
                          backgroundColor: "#D7D9E1",
                          marginTop: 15,
                        }}
                      />
                      {client?.uploaded_docs?.map((doc) => {
                        return (
                          <>
                            {doc.file_path && (
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ marginTop: 20 }}
                                align="left"
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", width: "100%" }}
                                    >
                                      <AiOutlineFile size={30} />
                                      <Typography
                                        style={{
                                          paddingLeft: 20,
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {doc?.document?.name}
                                      </Typography>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                    align="right"
                                  >
                                    <Button
                                      type="button"
                                      className={"buttonGeneralStyle"}
                                      style={{ width: 100 }}
                                      onClick={() =>
                                        getDocumentFile(
                                          doc.id,
                                          `${client.name}_${client.surname}_${doc?.document?.name}`
                                        )
                                      }
                                    >
                                      {"Gerer"}
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      height: 1,
                                      backgroundColor: "#D7D9E1",
                                      marginTop: 15,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </>
                        );
                      })}
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography variant="h5" align="left">
                          {text.ClientDetail.qualification}
                        </Typography>
                      </Grid>
                      {client?.qualifications?.map((qualification) => {
                        return (
                          <>
                            {qualification.file_path && (
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ marginTop: 20 }}
                                align="left"
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", width: "100%" }}
                                    >
                                      <AiOutlineFile size={30} />
                                      <div>
                                        <Typography
                                          style={{
                                            paddingLeft: 20,
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                          }}
                                        >
                                          {qualification?.name}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                    }}
                                    align="right"
                                  >
                                    <Button
                                      type="button"
                                      className={"buttonGeneralStyle"}
                                      style={{ width: 100 }}
                                      onClick={() =>
                                        getDocumentFile(
                                          qualification.id,
                                          `${client.name}_${client.surname}_${qualification?.name}`
                                        )
                                      }
                                    >
                                      {"Gerer"}
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      height: 1,
                                      backgroundColor: "#D7D9E1",
                                      marginTop: 15,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </>
                        );
                      })}
                    </>
                  )}
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 30 }} align="left">
              <Typography
                component="h1"
                variant="h5"
              >{`${text.ClientDetail.allMissiongOf} ${client.name} ${client.surname}`}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Grid container>
              {client?.bookings?.map((booking) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ marginTop: 20 }}
                    className={"paddingCardMap"}
                  >
                    <CardMission
                      renderRatingStar={renderRatingStar}
                      mission={booking?.mission}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </>
      )}
    </BusinessLayout>
  );
};

export default ProfessionistDetail;
