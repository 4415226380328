import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getMissionDetailByClient } from "../../../Axios/Call/AuthApi";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import text from "../../../utils/text";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import MissionDetail from "../../../Components/BusinessComponents/MissionDetail/MissionDetail";

const ProfessionistMissionDetailToAccepted = () => {
  const [mission, setMission] = useState({});
  const [client, setClient] = useState({});
  const { missionId, clientId } = useParams();
  const [errorApi, setErrorApi] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    if (missionId && clientId) {
      getMissionDetailByClient(missionId, clientId)
        .then((response) => {
          setMission(response.data.mission);
          setClient(response.data.client);
        })
        .catch((error) => {});
    }
  }, [missionId, clientId]);

  return (
    <BusinessLayout xs={12} md={12} lg={10}>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      <MissionDetail mission={mission} client={client} isToBeAccetpted={true} />
    </BusinessLayout>
  );
};

export default ProfessionistMissionDetailToAccepted;
