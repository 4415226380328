import React from "react";
import { Grid, Typography, Avatar } from "@material-ui/core";

const ContactCard = ({ contact }) => {
  return (
    <Grid container style={{ marginTop: 10 }}>
      <Grid items xs={4} align="left">
        <Avatar
          src={contact?.picture_path}
          alt="client"
          style={{ width: 50, height: 50, borderRadius: 100 }}
        />
      </Grid>
      <Grid items xs={8} align="left">
        <Grid container>
          <Grid items xs={8} align="left">
            <Typography component="span" style={{ color: "#2A3254" }}>
              {contact.name} {contact.surname}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left">
            <Typography component="span" style={{ color: "#AEB2C3" }}>
              {contact?.role?.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactCard;
