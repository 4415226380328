import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import text from "../../../utils/text";
import CommonButton from "../../FormComponents/CommonButton";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { formatErrorEmail } from "../../../utils/formValidation";
import { registerOwner } from "../../../Axios/Call/ClientCredential";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";
import Terms from "../../../Assets/Images/term-condition.pdf";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";

const pwdLengths = {
  0: { color: "white", width: "0%" },
  1: { color: red[500], width: "25%" },
  2: { color: yellow[500], width: "50%" },
  99: { color: orange[500], width: "75%" },
  3: { color: green[500], width: "100%" },
};

const useStyles = makeStyles((theme) => ({
  pwsInput: ({ strength }) => ({
    position: "relative",
    "&::before": {
      content: "' '",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: pwdLengths[strength].width,
      height: theme.spacing(1),
      backgroundColor: pwdLengths[strength].color,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: strength === 4 ? theme.shape.borderRadius : 0,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      transition: "width 1s, background-color .5s",
    },

    "&::after": {
      display: strength ? "block" : "none",
      content: "' '",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: theme.spacing(1),
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      borderTopStyle: "solid",
    },
  }),
}));

const Step3 = ({ setStep, step1Data, step2Data }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const classes = useStyles({ strength: passwordStrength });

  const { register, handleSubmit, errors, setError, watch, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  useEffect(() => {
    reset({
      email: step2Data.email_professional,
    });
  }, []);

  const sendFormCreateAccount = (data) => {
    if (passwordStrength !== 3) {
      return;
    }
    setLoadingApi(true);
    registerOwner({
      ...data,
      ...step1Data,
      ...step2Data,
      has_accepted_condition: true,
    })
      .then(() => {
        setLoadingApi(false);
        setStep(4);
      })
      .catch((error) => {
        setLoadingApi(false);
        if (error.response && error.response.status === 400) {
          setError("email", {
            message: text.General.emailTooken,
          });
        } else {
          setErrorApi(true);
        }
      });
  };

  useEffect(() => {
    if (watch("password")) {
      let countComplexity = 0;
      if (/[0-9]/.test(watch("password"))) {
        countComplexity += 1;
        console.log("hasNumber");
      }
      if (/[a-zA-Z]/.test(watch("password"))) {
        countComplexity += 1;
        console.log("hasText");
      }
      if (watch("password").length > 7) {
        countComplexity += 1;
        console.log("isLong");
      }

      setPasswordStrength(countComplexity);
    } else {
      setPasswordStrength(0);
    }
  }, [watch("password")]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      <form
        onSubmit={handleSubmit(sendFormCreateAccount)}
        style={{ marginTop: 20 }}
        noValidate
      >
        <Grid container>
          <Grid item xs={2} lg={1} style={{ marginTop: 5 }}>
            <IoChevronBackCircleOutline size={30} onClick={() => setStep(2)} />
          </Grid>
          <Grid item xs={10} lg={11} align="left">
            <Typography component="h1" variant="h5">
              {text.Registration.step3Title}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} align="left" style={{ marginTop: 10 }}>
            <Typography component="span" style={{ color: "#868CA6" }}>
              {text.Registration.step3Description}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={10} style={{ marginTop: 10 }}>
            <TextFieldCustom
              labelCustom="Email de connexion"
              type="email"
              ref={register({
                required: text.General.required,
                validate: {
                  formatErrorEmail,
                },
              })}
              defaultValue=""
              name="email"
              error={errors.email}
            />
          </Grid>

          <Grid item xs={12} lg={10} style={{ marginTop: 10 }}>
            <TextFieldCustom
              variant="outlined"
              labelCustom="Mot de passe"
              type="password"
              ref={register({ required: text.General.required })}
              defaultValue=""
              name={"password"}
              error={errors.password}
              InputProps={{
                className: classes.pwsInput,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={10} style={{ marginTop: 10 }} align="left">
            <Typography
              component="span"
              style={{ color: "#8F96A4", fontSize: 12 }}
            >
              {!watch("password") && text.Registration.password0}
              {passwordStrength === 1 && text.Registration.password1}
              {passwordStrength === 2 && text.Registration.password2}
              {passwordStrength === 3 && text.Registration.password3}
            </Typography>
          </Grid>

          <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="has_accepted_condition"
                  color="primary"
                  inputRef={register({ required: text.General.required })}
                  value={true}
                  defaultChecked={false}
                />
              }
              label={
                <>
                  <Typography component="span" style={{ color: "#2A3254" }}>
                    {text.Registration.condition1}{" "}
                  </Typography>
                  <a href={Terms} target="_blank" style={{ color: "#2A3254" }}>
                    <Typography component="span" style={{ color: "#2A3254" }}>
                      {text.Registration.condition2}
                    </Typography>
                  </a>
                </>
              }
            />
          </Grid>
          {errors?.has_accepted_condition && (
            <Grid item xs={12} align="left">
              <Typography
                component="span"
                style={{ color: "#f44336", fontSize: 12 }}
              >
                {errors.has_accepted_condition.message}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} lg={10} style={{ marginTop: 10 }}>
            <CommonButton text={text.General.continue} loading={loadingApi} />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Step3;
