import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import text from "../../../utils/text";
import CommonButton from "../../FormComponents/CommonButton";
//import //formatErrorSiret,
//formatErrorApec,
//formatErrorLetters,
//"../../../utils/formValidation";

const Step1 = ({ setStep1Data, step1Data, setStep }) => {
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  useEffect(() => {
    reset({ ...step1Data });
  }, []);

  const sendFormStep1 = (data) => {
    setStep1Data(data);
    setStep(2);
  };

  return (
    <form
      onSubmit={handleSubmit(sendFormStep1)}
      style={{ marginTop: 20 }}
      noValidate
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={9} align="left">
          <Typography component="h1" variant="h5">
            {text.Registration.title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={9} align="left">
          <Typography component="span" style={{ color: "#868CA6" }}>
            {text.Registration.description}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={10}>
          <TextFieldCustom
            labelCustom={text.Registration.labelName}
            type="input"
            ref={register({
              required: text.General.required,
            })}
            defaultValue=""
            name="company_name"
            error={errors.company_name}
          />
        </Grid>
        <Grid item xs={12} lg={10}>
          <TextFieldCustom
            labelCustom={text.Registration.labelVat}
            type="input"
            ref={register({
              required: text.General.required,
              validate: {
                //formatErrorSiret,
              },
            })}
            defaultValue=""
            name="vat"
            error={errors.vat}
          />
        </Grid>
        <Grid item xs={12} lg={10}>
          <TextFieldCustom
            labelCustom={text.Registration.labelApeNaf}
            type="input"
            ref={register({
              required: text.General.required,
              validate: {
                //formatErrorApec,
              },
            })}
            defaultValue=""
            name="code_ape_naf"
            error={errors.code_ape_naf}
          />
        </Grid>
        <Grid item xs={12} lg={10}>
          <TextFieldCustom
            labelCustom={text.Registration.labelAddress}
            type="input"
            ref={register({
              required: text.General.required,
            })}
            defaultValue=""
            name="address"
            error={errors.address}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <TextFieldCustom
            labelCustom={text.Registration.labelAddressNumber}
            type="input"
            ref={register({
              required: text.General.required,
            })}
            defaultValue=""
            name="address_number"
            error={errors.address_number}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <TextFieldCustom
            labelCustom={text.Registration.labelCodePostal}
            type="input"
            ref={register({
              required: text.General.required,
            })}
            defaultValue=""
            name="cap"
            error={errors.cap}
          />
        </Grid>
        <Grid item xs={12} lg={10}>
          <CommonButton text={text.General.continue} loading={false} />
        </Grid>
      </Grid>
    </form>
  );
};

export default Step1;
