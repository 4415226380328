import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Avatar } from "@material-ui/core";
import text from "../../../utils/text";
import ImageUploader from "react-images-upload";
import { getUserPicture, editUser } from "../../../Axios/Call/AuthApi";
import { Controller, useForm } from "react-hook-form";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import { formatErrorEmail } from "../../../utils/formValidation";
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker";
import moment from "moment";
import "moment/locale/fr";
import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { FaBullseye } from "react-icons/fa";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";

const pwdLengths = {
  0: { color: "white", width: "0%" },
  1: { color: red[500], width: "25%" },
  2: { color: yellow[500], width: "50%" },
  99: { color: orange[500], width: "75%" },
  3: { color: green[500], width: "100%" },
};

const useStyles = makeStyles({
  label: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontWeight: 700,
    color: "#2A3254",
  },
  labelAction: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    color: "#FD4953",
    cursor: "pointer",
  },
});

const useStyles2 = makeStyles((theme) => ({
  pwsInput: ({ strength }) => ({
    position: "relative",
    "&::before": {
      content: "' '",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: pwdLengths[strength]?.width,
      height: theme.spacing(1),
      backgroundColor: pwdLengths[strength]?.color,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: strength === 4 ? theme.shape.borderRadius : 0,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      transition: "width 1s, background-color .5s",
    },

    "&::after": {
      display: strength ? "block" : "none",
      content: "' '",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: theme.spacing(1),
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      borderTopStyle: "solid",
    },
  }),
}));

const Profile = () => {
  const userDataRedux = useSelector((state) => state.user.userData);
  const [userData, setUserData] = useState({});
  const [editField, setEditField] = useState("");
  const [localImage, setLocalImage] = useState(null);
  const [uploadedPicture, setUploadedPicture] = useState(null);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [successApi, setSuccessApi] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const classes = useStyles();
  const classes2 = useStyles2({ strength: passwordStrength });

  const { register, handleSubmit, errors, control, setError, reset, watch } =
    useForm({
      mode: "onBlur",
    }); // initialise the hook

  const onModifyFieldHandler = (type) => {
    setEditField(editField === type ? "" : type);
    reset({ ...userData });
  };

  const onUploadPictureHandler = (fileUploading) => {
    let file = fileUploading[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setLocalImage(reader.result);
    };
  };

  useEffect(() => {
    if (localImage) {
      setLoadingImage(true);
      editUser({ base64: localImage, ...userData })
        .then((response) => {
          setSuccessApi(true);
          setErrorApi(false);
          setLoadingImage(false);
        })
        .catch((error) => {
          setLoadingImage(false);
          setErrorApi(true);
          setSuccessApi(false);
        });
      setEditField("");
    }
  }, [localImage]);

  useEffect(() => {
    if (watch("password")) {
      let countComplexity = 0;
      if (/[0-9]/.test(watch("password"))) {
        countComplexity += 1;
      }
      if (/[a-zA-Z]/.test(watch("password"))) {
        countComplexity += 1;
      }
      if (watch("password").length > 7) {
        countComplexity += 1;
      }

      setPasswordStrength(countComplexity);
    } else {
      setPasswordStrength(0);
    }
  }, [watch("password")]);

  useEffect(() => {
    getUserPicture()
      .then((response) => {
        setUploadedPicture(response.data);
      })
      .then((error) => {});
  }, []);

  const editProfileHandler = (data) => {
    if (data.password && passwordStrength !== 3) {
      return;
    }

    setLoadingApi(true);
    editUser({
      ...userData,
      ...data,
      dob: data.dob ? moment(data.dob).format("yyyy-MM-DD") : userData.dob,
    })
      .then((response) => {
        setSuccessApi(true);
        setErrorApi(false);
        setLoadingApi(false);
        setEditField("");
        setUserData({ ...userData, ...data });
      })
      .catch(() => {
        setErrorApi(true);
        setSuccessApi(false);
        setLoadingApi(false);
      });
  };

  useEffect(() => {
    if (Object.keys(userDataRedux).length) {
      setUserData(userDataRedux);
    }
  }, [userDataRedux]);

  return (
    <Grid container>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {successApi && (
        <AlertGeneral
          setTrigger={setSuccessApi}
          description={text.General.alertGeneralSuccess}
        />
      )}
      <Grid items xs={12} align="left">
        <Typography component="span" className={classes.label}>
          {text.ProfileSection.profilePhoto}
        </Typography>
      </Grid>
      {/* PICTURE */}
      {editField === "picture" ? (
        <Grid items xs={6} align="left" style={{ marginTop: 20 }}>
          <ImageUploader
            withIcon={true}
            buttonText={"Envoyer"}
            label=""
            onChange={(event) => onUploadPictureHandler(event)}
            imgExtension={[".jpg", ".png", ".jpeg"]}
            maxFileSize={5242880}
            singleImage
            buttonClassName={"buttonGeneralStyle"}
          />
        </Grid>
      ) : (
        <>
          <Grid items xs={5} align="left" style={{ marginTop: 20 }}>
            {loadingImage ? (
              <LoadingButton />
            ) : (
              <Avatar
                src={localImage ? localImage : uploadedPicture ?? ""}
                alt="profile"
                style={{ width: 120, height: 120, borderRadius: 100 }}
              />
            )}
          </Grid>
          <Grid
            items
            xs={6}
            align="left"
            style={{ marginTop: 20, marginTop: "auto", marginBottom: "auto" }}
            onClick={() => onModifyFieldHandler("picture")}
          >
            <Typography component="span" className={classes.labelAction}>
              {text.General.modify}
            </Typography>
          </Grid>
        </>
      )}
      {/* NAME AND SURNAME */}
      <Grid
        items
        xs={12}
        style={{
          height: 1,
          backgroundColor: "#D7D9E1",
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Grid items xs={12} align="left">
        <Grid container>
          {editField === "name" ? (
            <Grid items xs={12} align="left">
              <form
                onSubmit={handleSubmit(editProfileHandler)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container>
                  <Grid items xs={5} align="left" style={{ paddingRight: 5 }}>
                    <TextFieldCustom
                      labelCustom={text.ProfileSection.nameLabel}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="name"
                      error={errors.name}
                    />
                  </Grid>
                  <Grid items xs={5} align="left" style={{ paddingLeft: 5 }}>
                    <TextFieldCustom
                      labelCustom={text.ProfileSection.surnameLabel}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="surname"
                      error={errors.surname}
                    />
                  </Grid>
                  <Grid items xs={2} align="right">
                    <Typography
                      component="span"
                      onClick={() => onModifyFieldHandler("name")}
                      className={classes.labelAction}
                    >
                      {text.General.anull}
                    </Typography>
                  </Grid>
                  <Grid items xs={6} md={2} align="left">
                    <CommonButton
                      text={text.ProfileSection.register}
                      loading={loadingApi}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          ) : (
            <>
              <Grid items xs={8} align="left">
                <Typography component="span" className={classes.label}>
                  {text.ProfileSection.nameComplete}
                </Typography>
              </Grid>
              <Grid items xs={4} align="right">
                <Typography
                  component="span"
                  onClick={() => onModifyFieldHandler("name")}
                  className={classes.labelAction}
                >
                  {text.General.modify}
                </Typography>
              </Grid>
              <Grid items xs={12} align="left">
                <Typography component="span">
                  {userData.name} {userData.surname}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* EMAIL */}
      <Grid
        items
        xs={12}
        style={{
          height: 1,
          backgroundColor: "#D7D9E1",
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Grid items xs={12} align="left">
        {editField === "email" ? (
          <form
            onSubmit={handleSubmit(editProfileHandler)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container>
              <Grid items xs={8} align="left">
                <TextFieldCustom
                  labelCustom={text.ProfileSection.emailAddress}
                  type="email"
                  ref={register({
                    required: text.General.required,
                    validate: {
                      formatErrorEmail,
                    },
                  })}
                  defaultValue=""
                  name="email_professional"
                  error={errors.email_professional}
                />
              </Grid>
              <Grid items xs={4} align="right">
                <Typography
                  component="span"
                  onClick={() => onModifyFieldHandler("email")}
                  className={classes.labelAction}
                >
                  {text.General.anull}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2} align="left">
                <CommonButton
                  text={text.ProfileSection.register}
                  loading={loadingApi}
                />
              </Grid>
            </Grid>
          </form>
        ) : (
          <Grid container>
            <Grid items xs={8} align="left">
              <Typography component="span" className={classes.label}>
                {text.ProfileSection.emailAddress}
              </Typography>
            </Grid>
            <Grid items xs={4} align="right">
              <Typography
                component="span"
                onClick={() => onModifyFieldHandler("email")}
                className={classes.labelAction}
              >
                {text.General.modify}
              </Typography>
            </Grid>
            <Grid items xs={12} align="left">
              <Typography component="span">
                {userData.email_professional}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* MOBILE PHONE */}
      <Grid
        items
        xs={12}
        style={{
          height: 1,
          backgroundColor: "#D7D9E1",
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Grid items xs={12} align="left">
        {editField === "mobile-phone" ? (
          <form
            onSubmit={handleSubmit(editProfileHandler)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container>
              <Grid items xs={8} align="left">
                <TextFieldCustom
                  labelCustom={text.ProfileSection.mobileNumber}
                  type="input"
                  ref={register({
                    required: text.General.required,
                  })}
                  defaultValue=""
                  name="mobile_phone"
                  error={errors.mobile_phone}
                />
              </Grid>
              <Grid items xs={4} align="right">
                <Typography
                  component="span"
                  onClick={() => onModifyFieldHandler("mobile-phone")}
                  className={classes.labelAction}
                >
                  {text.General.anull}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2} align="left">
                <CommonButton
                  text={text.ProfileSection.register}
                  loading={loadingApi}
                />
              </Grid>
            </Grid>
          </form>
        ) : (
          <Grid container>
            <Grid items xs={8} align="left">
              <Typography component="span" className={classes.label}>
                {text.ProfileSection.mobileNumber}
              </Typography>
            </Grid>
            <Grid items xs={4} align="right">
              <Typography
                component="span"
                onClick={() => onModifyFieldHandler("mobile-phone")}
                className={classes.labelAction}
              >
                {text.General.modify}
              </Typography>
            </Grid>
            <Grid items xs={12} align="left">
              <Typography component="span">{userData.mobile_phone}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* FIXED PHONE */}
      <Grid
        items
        xs={12}
        style={{
          height: 1,
          backgroundColor: "#D7D9E1",
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Grid items xs={12} align="left">
        {editField === "fixed-phone" ? (
          <form
            onSubmit={handleSubmit(editProfileHandler)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container>
              <Grid items xs={8} align="left">
                <TextFieldCustom
                  labelCustom={text.ProfileSection.fixedNumber}
                  type="input"
                  ref={register({
                    required: text.General.required,
                  })}
                  defaultValue=""
                  name="fixed_phone"
                  error={errors.fixed_phone}
                />
              </Grid>
              <Grid items xs={4} align="right">
                <Typography
                  component="span"
                  onClick={() => onModifyFieldHandler("fixed-phone")}
                  className={classes.labelAction}
                >
                  {text.General.anull}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2} align="left">
                <CommonButton
                  text={text.ProfileSection.register}
                  loading={loadingApi}
                />
              </Grid>
            </Grid>
          </form>
        ) : (
          <Grid container>
            <Grid items xs={8} align="left">
              <Typography component="span" className={classes.label}>
                {text.ProfileSection.fixedNumber}
              </Typography>
            </Grid>
            <Grid items xs={4} align="right">
              <Typography
                component="span"
                onClick={() => onModifyFieldHandler("fixed-phone")}
                className={classes.labelAction}
              >
                {text.General.modify}
              </Typography>
            </Grid>
            <Grid items xs={12} align="left">
              <Typography component="span">{userData.fixed_phone}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* Password */}
      <Grid
        items
        xs={12}
        style={{
          height: 1,
          backgroundColor: "#D7D9E1",
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Grid items xs={12} align="left">
        <form onSubmit={handleSubmit(editProfileHandler)} noValidate>
          <Grid container>
            <Grid items xs={6} align="left">
              <Grid container>
                <Grid items xs={12} align="left">
                  <TextFieldCustom
                    labelCustom={text.ProfileSection.password}
                    type="password"
                    ref={register({
                      required: text.General.required,
                    })}
                    defaultValue="password"
                    name="password"
                    error={errors.password}
                    readOnly={editField === "password" ? false : true}
                    InputProps={{
                      className:
                        editField === "password" ? classes2.pwsInput : "",
                    }}
                  />
                </Grid>
                {editField === "password" ? (
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{ marginTop: 10 }}
                    align="left"
                  >
                    <Typography
                      component="span"
                      style={{ color: "#8F96A4", fontSize: 12 }}
                    >
                      {!watch("password") && text.Registration.password0}
                      {passwordStrength === 1 && text.Registration.password1}
                      {passwordStrength === 2 && text.Registration.password2}
                      {passwordStrength === 3 && text.Registration.password3}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid items xs={6} align="right">
              <Typography
                component="span"
                onClick={() => onModifyFieldHandler("password")}
                className={classes.labelAction}
              >
                {editField !== "password"
                  ? text.General.modify
                  : text.General.anull}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2} align="left">
              {editField === "password" && (
                <CommonButton
                  text={text.ProfileSection.register}
                  loading={loadingApi}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
      {/*
      <Grid
        items
        xs={12}
        style={{
          height: 1,
          backgroundColor: "#D7D9E1",
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <Grid items xs={12} align="left">
        <form
          onSubmit={handleSubmit(editProfileHandler)}
          style={{ marginTop: 20 }}
          noValidate
        >
          <Grid container>
            <Grid items xs={8} align="left">
              {editField === "dob" ? (
                <Controller
                  render={(field) => (
                    <CommonDateTimePicker
                      name={"dob"}
                      field={field}
                      labelCustom={text.ProfileSection.dob}
                      error={errors?.dob}
                    />
                  )}
                  defaultValue={new Date("1992-08-10")}
                  name={"dob"}
                  rules={register({
                    required: text.General.required,
                  })}
                  control={control}
                />
              ) : (
                <Typography component="span" className={classes.label}>
                  {text.ProfileSection.dob}
                </Typography>
              )}
            </Grid>
            <Grid items xs={4} align="right">
              <Typography
                component="span"
                onClick={() => onModifyFieldHandler("dob")}
                className={classes.labelAction}
              >
                {editField !== "dob" ? text.General.modify : text.General.anull}
              </Typography>
            </Grid>
            {editField === "dob" ? (
              <Grid items xs={2} align="left">
                <CommonButton
                  text={text.ProfileSection.register}
                  loading={loadingApi}
                />
              </Grid>
            ) : (
              <Grid items xs={12} align="left">
                <Typography component="span">
                  {userData.dob
                    ? moment(userData.dob).format("DD MMMM yyyy")
                    : ""}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
                  */}
    </Grid>
  );
};

export default Profile;
