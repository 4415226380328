import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { FaBuilding } from "react-icons/fa";
import text from "../../../utils/text";
import { useHistory } from "react-router-dom";
import { Etabilissement, Organization, Annonce } from "../../../utils/icons";
import BusinessCard from "./BusinessCard";
import ContactCard from "./ContactCard";
import MissionCard from "./MissionCard";
import { useSelector } from "react-redux";

const DashboardLeft = ({ businesses, contacts, missions }) => {
  const history = useHistory();
  const userData = useSelector((state) => state.user.userData);
  const [dashboardItem, setDashboardItem] = useState([
    {
      icon: <Etabilissement fill={"#2A3254"} />,
      title: text.Dashboard.myBusiness,
      descriptionNoData: text.Dashboard.noBusiness,
      type: "businesses",
      linkSeeAll: "/businesses/detail",
    },
    {
      icon: <Organization fill={"#2A3254"} />,
      title: text.Dashboard.myResponsable,
      descriptionNoData: text.Dashboard.noResponsable,
      type: "contacts",
      linkSeeAll: "/businesses",
    },
    {
      icon: <Annonce fill={"#2A3254"} />,
      title: text.Dashboard.myMission,
      descriptionNoData: text.Dashboard.noMission,
      type: "missions",
      linkSeeAll: "/announces",
    },
  ]);

  useEffect(() => {
    if (Object.keys(userData).length && userData.type === "responsable") {
      setDashboardItem(
        dashboardItem.filter((dash, index) => dash.type !== "contacts")
      );
    }
  }, [userData]);

  return (
    <>
      {dashboardItem.map((item, index) => {
        return (
          <Grid item xs={12} style={{ marginTop: index ? 20 : 0 }}>
            <Card style={{ padding: 20 }}>
              <div align="left" style={{ display: "flex" }}>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  {item.icon}
                </div>
                <div style={{ paddingLeft: 10 }}>
                  <Typography component="h1" variant="h5" align="left">
                    {item.title}
                  </Typography>
                </div>
              </div>
              <div align="left" style={{ width: "90%", marginTop: 10 }}>
                {item.type === "businesses" && businesses.length ? (
                  businesses.map((business) => {
                    return (
                      <BusinessCard business={business} key={business.id} />
                    );
                  })
                ) : item.type === "contacts" &&
                  contacts.length &&
                  userData?.type === "owner" ? (
                  contacts.map((contact, index) => {
                    return (
                      index < 4 && (
                        <ContactCard contact={contact} key={contact.id} />
                      )
                    );
                  })
                ) : item.type === "missions" && missions.length ? (
                  missions.map((mission, index) => {
                    return (
                      index < 4 && (
                        <MissionCard mission={mission} key={mission.id} />
                      )
                    );
                  })
                ) : (
                  <Typography component="span" style={{ color: "#AEB2C3" }}>
                    {item.descriptionNoData}
                  </Typography>
                )}
              </div>
              {item.type !== "contacts" &&
              (item.type !== "businesses" ||
                userData?.type !== "responsable") ? (
                <div align="left" style={{ marginTop: 10 }}>
                  <Typography
                    component="span"
                    style={{ color: "#FD4953", cursor: "pointer" }}
                    onClick={() => history.push(item.linkSeeAll)}
                  >
                    {text.General.seeAll}
                  </Typography>
                </div>
              ) : null}
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

export default DashboardLeft;
