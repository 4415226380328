import React, { useEffect, useState } from "react";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import Grid from "@material-ui/core/Grid";
import DashboardLeft from "../../../Components/BusinessComponents/Dashboard/DashboardLeft";
import DashboardRight from "../../../Components/BusinessComponents/Dashboard/DashboardRight";
import { getDashboard } from "../../../Axios/Call/AuthApi";
import { useSelector } from "react-redux";
import DashboardRightLogin from "../../../Components/BusinessComponents/Dashboard/DashboardRightLogin";
import text from "../../../utils/text";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import { useDispatch } from "react-redux";
import * as actionCreator from "../../../store/action/index";

const Dashboard = () => {
  const [businesses, setBusinesses] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [clients, setClients] = useState([]);
  const [missions, setMissions] = useState([]);
  const [firstRowData, setFirstRowData] = useState(null);
  const [errorApi, setErrorApi] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scroll(0, 0);
    //if (!Object.keys(dashboard).length) {
    getDashboard()
      .then((response) => {
        setBusinesses(response?.data?.businesses);
        setContacts(response?.data?.contacts);
        setFirstRowData({
          clientNumber: response?.data?.clients_number,
          hours: response?.data?.hours_done,
          number_mission: response?.data?.mission_done,
        });
        setClients(response?.data?.clients);
        setMissions(response?.data?.missions);
        dispatch(actionCreator.populateDashboard(response.data));
      })
      .catch((error) => {
        setErrorApi(true);
      });
    /*} else {
      setBusinesses(dashboard.businesses);
      setContacts(dashboard.contacts);
      setFirstRowData({
        clientNumber: dashboard.clients_number,
        hours: dashboard.hours_done,
        number_mission: dashboard.mission_done,
      });
      setClients(dashboard.clients);
      setMissions(dashboard.missions);
    }*/
  }, []);

  return (
    <BusinessLayout xs={12} md={12} lg={11}>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      <Grid
        container
        spacing={3}
        style={{
          padding: "20px 20px 20px 20px",
        }}
      >
        <Grid item xs={12} md={3}>
          <DashboardLeft
            businesses={businesses}
            contacts={contacts}
            missions={missions}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {userData?.businesses?.length ? (
            <DashboardRightLogin
              firstRowData={firstRowData}
              clients={clients}
            />
          ) : (
            <DashboardRight />
          )}
        </Grid>
      </Grid>
    </BusinessLayout>
  );
};

export default Dashboard;
