import React from "react";
import { Grid, Avatar, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import { Star, StarEmpty } from "../../../utils/icons";
import { Link } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";

const ClientCard = ({ client }) => {
  const windowWidth = useWindowWidth();
  const renderRatingStar = () => {
    let item = [];
    let itemToAdd = null;
    if (client.current_rating_client) {
      for (let i = 1; i <= 5; i++) {
        if (client.current_rating_client >= i) {
          itemToAdd = (
            <div>
              <Star fill={"#2A3254"} />
            </div>
          );
        } else {
          itemToAdd = (
            <div>
              <StarEmpty />
            </div>
          );
        }

        item.push(itemToAdd);
      }
    } else {
      item = (
        <div
          style={{ marginTop: "auto", marginBottom: "auto", marginLeft: 10 }}
        >
          <Typography component="span" style={{ color: "#8F96A4" }}>
            {text.General.noRatingYet}
          </Typography>
        </div>
      );
    }
    return item;
  };

  return (
    <Grid container style={{ marginTop: 15 }}>
      <Grid items xs={4} sm={1} style={{ marginRight: 20 }}>
        <Avatar
          src={client?.picture_path}
          alt="client-profile"
          style={{ width: 60, height: 60, borderRadius: 100 }}
        />
      </Grid>
      <Grid items xs={7} sm={3}>
        <Grid container style={{ marginTop: 10 }}>
          <Grid items xs={12} align="left">
            <Link
              to={`professionist-dettail/${client.id}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                component="span"
                align="left"
                style={{ color: "#2A3254", cursor: "pointer" }}
              >
                {client.name} {client.surname}
              </Typography>
            </Link>
          </Grid>
          <Grid items xs={12} align="left">
            <Typography
              component="span"
              align="left"
              style={{ color: "#AEB2C3" }}
            >
              {client?.profile?.job_type?.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {windowWidth > 575 && (
        <Grid items xs={6} sm={2}>
          <Grid container style={{ marginTop: 10 }}>
            <Grid items xs={12} align="left">
              <Typography
                component="span"
                align="left"
                style={{ color: "#2A3254" }}
              >
                {client.mission_counts}
              </Typography>
            </Grid>
            <Grid items xs={12} align="left">
              <Typography
                component="span"
                align="left"
                style={{ color: "#AEB2C3" }}
              >
                {text.Dashboard.missions}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid items xs={6} sm={2}>
        <Grid container style={{ marginTop: windowWidth > 575 ? 10 : 20 }}>
          <Grid items xs={12} align="left">
            <Typography
              component="span"
              align="left"
              style={{ color: "#2A3254" }}
            >
              {client.hours} {text.Dashboard.hours}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left">
            <Typography
              component="span"
              align="left"
              style={{ color: "#AEB2C3" }}
            >
              {text.Dashboard.ofMission}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid items xs={6} sm={2}>
        <Grid container style={{ marginTop: 10 }}>
          <Grid items xs={12} align="left">
            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                <Typography
                  component="span"
                  align="left"
                  style={{ color: "#2A3254" }}
                >
                  {client.mission}
                </Typography>
              </div>
              {renderRatingStar()}
            </div>
          </Grid>
          <Grid items xs={12} align="left">
            <Typography
              component="span"
              align="left"
              style={{ color: "#AEB2C3" }}
            >
              {client.rating_quantity} {text.Dashboard.evaluation}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid items xs={12} sm={1} />
    </Grid>
  );
};

export default ClientCard;
