import CommonButton from "../../../Components/FormComponents/CommonButton";
import text from "../../../utils/text";
import {
  Grid,
  Typography,
  Card,
  makeStyles,
  Button,
  IconButton,
  Tooltip,
  Box,
} from "@material-ui/core";
import moment from "moment";
import * as R from "ramda";
import { useState, Fragment, useEffect } from "react";
import grey from "@material-ui/core/colors/grey";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CloseIcon from "@material-ui/icons/Close";
import AddHoursMenu from "./AddHoursMenu";
import AddWeekDialog from "./AddWeekDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
  dayRow: {
    backgroundColor: grey[200],
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.5, 1),
    minHeight: 62,
  },
  addBtn: {
    width: "100%",
    padding: theme.spacing(1, 2),
    borderColor: grey[700],
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: theme.palette.common.white,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  clipboardBtn: {
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    background: "none",
    border: 0,
    color: grey[300],
    cursor: "pointer",
    transition: "color .2s",
    "&:hover": {
      color: "#FD4953",
    },
    "&:disabled:hover": {
      color: grey[200],
    },
  },
  shift: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  shiftsContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(-1),
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
  },
}));

const defaultMenuTime = {
  startHour: -1,
  endHour: -1,
  startMinute: -1,
  endMinute: -1,
};

const Step3 = ({
  setStep,
  startingDate,
  endingDate,
  onSubmit,
  shiftsLoaded,
}) => {
  const classes = useStyles();

  const [currentWeek, setCurrentWeek] = useState(0);
  const [shifts, setShifts] = useState(
    shiftsLoaded.length
      ? shiftsLoaded.map((s) => ({
          day: moment(s.start_date).startOf("day"),
          start_date: moment(s.start_date),
          end_date: moment(s.end_date),
        }))
      : []
  );
  const [menuAnchiorEl, setMenuAnchiorEl] = useState(null);
  const [overlapError, setOverlapError] = useState(false);
  const [addMenutime, setAddMennuTime] = useState({ ...defaultMenuTime });
  const [activeDate, setActiveDate] = useState(null);
  const [clipboard, setClipboard] = useState(null);
  const [addWeekDialogOpen, setAddWeekDialogOpen] = useState(false);
  const isLongTerm = moment(endingDate).diff(moment(startingDate), "days") > 7;

  useEffect(() => {
    if (R.any(R.equals(-1), R.values(addMenutime)) || !activeDate) {
      return;
    }

    const _shifts = shifts.filter((s) => s.day?.isSame(activeDate, "day"));
    const start_date = moment(activeDate)
      .hour(addMenutime.startHour)
      .minute(addMenutime.startMinute);
    const end_date = moment(activeDate)
      .hour(addMenutime.endHour)
      .minutes(addMenutime.startMinute);

    if (
      R.any(
        (s) => start_date.isBetween(s.start_date, s.end_date, "minutes", "[]"),
        _shifts
      ) ||
      R.any(
        (s) => end_date.isBetween(s.start_date, s.end_date, "minutes", "[]"),
        _shifts
      )
    ) {
      setOverlapError(true);
    } else {
      setOverlapError(false);
    }
  }, [addMenutime, activeDate, shifts]);

  const handleSubmit = () => {
    onSubmit(
      shifts.map((s) => ({
        start_date: s.start_date.toISOString(),
        end_date: s.end_date.toISOString(),
      }))
    );
    setStep(4);
  };

  const openMenu = (e, date) => {
    setMenuAnchiorEl(e.currentTarget);
    setActiveDate(date);
  };

  const closeMenu = () => {
    setOverlapError(false);
    setMenuAnchiorEl(null);
    setAddMennuTime({ ...defaultMenuTime });
    setActiveDate(null);
  };

  const addShift = () => {
    if (R.any(R.equals(-1), R.values(addMenutime)) || !activeDate) {
      return;
    }

    const startMoment = moment(activeDate)
      .hour(addMenutime.startHour)
      .minute(addMenutime.startMinute);
    const endMoment = moment(activeDate)
      .hour(addMenutime.endHour)
      .minute(addMenutime.endMinute);

    setShifts([
      ...shifts,
      {
        day: moment(activeDate),
        start_date: startMoment,
        end_date: endMoment,
      },
    ]);

    closeMenu();
  };

  const startMoment = moment(startingDate).hour(0).minute(0);
  const endMoment = moment(endingDate).hour(0).minute(0);

  const lastSunday = moment(endMoment).endOf("week");
  const firstMonday = moment(startMoment).startOf("week");

  const totalDays = lastSunday.diff(firstMonday, "days");

  const weeks = R.splitEvery(
    7,
    R.times(R.identity, totalDays + 1).map((i) =>
      moment(firstMonday).add(i, "days")
    )
  );

  const handleRemoveShift = (shift) =>
    setShifts(
      shifts.filter(
        (s) =>
          !s.start_date?.isSame(shift.start_date) &&
          !s.end_date?.isSame(shift.end_date)
      )
    );

  return (
    <>
      <AddWeekDialog
        open={addWeekDialogOpen}
        onClose={() => setAddWeekDialogOpen(false)}
        onSubmit={(s) =>
          setShifts(
            weeks.flatMap((week) =>
              week
                .filter((day) =>
                  day.isBetween(startMoment, endMoment, "days", "[]")
                )
                .flatMap((day) =>
                  s[day.isoWeekday().toString()]?.map((shift) => ({
                    day: moment(day),
                    start_date: moment(day)
                      .hour(shift.start_date.hour())
                      .minute(shift.start_date.minute()),
                    end_date: moment(day)
                      .hour(shift.end_date.hour())
                      .minute(shift.end_date.minute()),
                  }))
                )
                .filter(Boolean)
            )
          )
        }
      />
      <AddHoursMenu
        anchiorEl={menuAnchiorEl}
        onClose={closeMenu}
        onAddShift={addShift}
        addMenuTime={addMenutime}
        setAddMenuTime={setAddMennuTime}
        activeDate={activeDate}
        overlapError={overlapError}
      />
      <Grid container>
        <Grid items xs={12}>
          <Typography component="h1" variant="h5">
            <Typography component="h1" variant="h5">
              {text.createMission.step1Title}
            </Typography>
          </Typography>
        </Grid>
        <Grid items xs={12}>
          <Card style={{ padding: 20 }}>
            <Typography variant="h6">
              {text.missions.du} {moment(startingDate).format("DD/MM/YYYY")}{" "}
              {text.missions.au} {moment(endingDate).format("DD/MM/YYYY")}
            </Typography>
            <Grid container>
              <Grid item xs={12} className={classes.container}>
                {weeks[currentWeek].map((day) => {
                  const _shifts = shifts.filter((s) =>
                    s.day?.isSame(day, "day")
                  );

                  const isMissionDay = day.isBetween(
                    startMoment,
                    endMoment,
                    "days",
                    "[]"
                  );

                  return (
                    <Fragment key={day.unix()}>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              fontWeight: 700,
                            }}
                          >
                            {day.format("dddd DD/MM")}
                          </Typography>
                        </Grid>
                        {isMissionDay && (
                          <Grid item>
                            <button
                              disabled={_shifts.length === 0}
                              className={classes.clipboardBtn}
                              onClick={() =>
                                setClipboard(
                                  _shifts.map((s) => ({
                                    startHour: s.start_date.hour(),
                                    startMinute: s.start_date.minute(),
                                    endHour: s.end_date.hour(),
                                    endMinute: s.end_date.minute(),
                                  }))
                                )
                              }
                            >
                              {text.createMission.copy}
                            </button>
                            <button
                              onClick={() =>
                                setShifts([
                                  ...shifts,
                                  ...clipboard.map((c) => ({
                                    start_date: moment(day)
                                      .hour(c.startHour)
                                      .minute(c.startMinute),
                                    end_date: moment(day)
                                      .hour(c.endHour)
                                      .minute(c.endMinute),
                                    day: moment(day),
                                  })),
                                ])
                              }
                              disabled={!clipboard}
                              className={classes.clipboardBtn}
                            >
                              {text.createMission.paste}
                            </button>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.dayRow}
                      >
                        <Grid item xs={12} sm={9}>
                          <div className={classes.shiftsContainer}>
                            {_shifts
                              .sort((a, b) => a.start_date.diff(b.start_date))
                              .map((s, index) => (
                                <div key={index} className={classes.shift}>
                                  <Typography>
                                    {s.start_date.format("HH:mm")} -{" "}
                                    {s.end_date.format("HH:mm")}
                                  </Typography>
                                  <Tooltip title={text.createMission.remove}>
                                    <IconButton
                                      onClick={() => handleRemoveShift(s)}
                                      size="small"
                                      edge="end"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                          {_shifts.length === 0 && (
                            <Box marginTop={1}>
                              <Typography style={{ color: grey[500] }}>
                                {text.missions.pasDePlageHorair}
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                        <Grid xs={12} sm={3} item>
                          {isMissionDay && (
                            <button
                              className={classes.addBtn}
                              onClick={(e) => openMenu(e, day)}
                              type="button"
                            >
                              <ScheduleIcon />
                              <Typography>{text.General.add}</Typography>
                            </button>
                          )}
                        </Grid>
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Button
                      startIcon={<ArrowBackIosIcon />}
                      disabled={currentWeek === 0}
                      onClick={() => setCurrentWeek((w) => w - 1)}
                    >
                      {text.createMission.prevWeek}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      endIcon={<ArrowForwardIosIcon />}
                      disabled={currentWeek === weeks.length - 1}
                      onClick={() => setCurrentWeek((w) => w + 1)}
                    >
                      {text.createMission.nextWeek}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {isLongTerm && (
                <Grid items xs={12}>
                  <CommonButton
                    text={text.createMission.addHoursWeek}
                    loading={false}
                    onClick={() => setAddWeekDialogOpen(true)}
                    isGreyStyle={true}
                  />
                </Grid>
              )}
              <Grid items xs={12}>
                <CommonButton
                  text={text.General.forward}
                  loading={false}
                  onClick={handleSubmit}
                  disabled={!shifts?.length}
                  isGreyStyle={!shifts?.length}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Step3;
