import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import text from "../../utils/text";
import Background from "../../Assets/Images/Illu-8.png";

const RecoverPasswordStep1 = ({ continueAction, checkTokenError }) => {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography component="h1" variant="h5">
          {text.RecoverPassword.step1Title}
        </Typography>
        <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
          <Typography component="span" style={{ color: "#3F53AA" }}>
            {checkTokenError
              ? text.RecoverPassword.step1Error
              : text.RecoverPassword.step1Description1}
          </Typography>
        </Grid>

        {!checkTokenError && (
          <Button
            onClick={() => continueAction()}
            variant="contained"
            color="primary"
            size="large"
            className={"buttonGeneralStyle"}
            style={{ marginTop: 20 }}
          >
            {text.RecoverPassword.step1Button}
          </Button>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: 40 }}>
        <img src={Background} style={{ width: "100%" }} alt="img" />
      </Grid>
    </Grid>
  );
};

export default RecoverPasswordStep1;
