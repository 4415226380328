import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import text from "../../utils/text";
import TextFieldCustom from "../FormComponents/TextFieldCustom";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { ResetPassword } from "../../Axios/Call/ClientCredential";
import AlertGeneral from "../Commons/AlertGeneral/AlertGeneral";
import CommonButton from "../FormComponents/CommonButton";
import { makeStyles } from "@material-ui/core";

import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";

const pwdLengths = {
  0: { color: "white", width: "0%" },
  1: { color: red[500], width: "25%" },
  2: { color: yellow[500], width: "50%" },
  99: { color: orange[500], width: "75%" },
  3: { color: green[500], width: "100%" },
};

const useStyles = makeStyles((theme) => ({
  pwsInput: ({ strength }) => ({
    position: "relative",
    "&::before": {
      content: "' '",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: pwdLengths[strength].width,
      height: theme.spacing(1),
      backgroundColor: pwdLengths[strength].color,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: strength === 4 ? theme.shape.borderRadius : 0,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      transition: "width 1s, background-color .5s",
    },

    "&::after": {
      display: strength ? "block" : "none",
      content: "' '",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: theme.spacing(1),
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      borderTopStyle: "solid",
    },
  }),
}));

const RecoverPasswordStep2 = () => {
  const { token } = useParams();
  const history = useHistory();
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const classes = useStyles({ strength: passwordStrength });
  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendResetPasswordForm = (data) => {
    if (passwordStrength !== 3) {
      return;
    }
    setLoadingApi(true);
    ResetPassword({ reset_token: token, ...data })
      .then((response) => {
        history.push("/");
        setLoadingApi(false);
        setSuccess(true);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  useEffect(() => {
    if (watch("password")) {
      let countComplexity = 0;
      if (/[0-9]/.test(watch("password"))) {
        console.log("hasnumber");
        countComplexity += 1;
      }
      if (/[a-zA-Z]/.test(watch("password"))) {
        console.log("hastext");
        countComplexity += 1;
      }
      if (watch("password").length > 7) {
        countComplexity += 1;
      }

      setPasswordStrength(countComplexity);
    } else {
      setPasswordStrength(0);
    }
  }, [watch("password")]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.RecoverPassword.step2AlertError}
        />
      )}
      {success && (
        <AlertGeneral
          color="success"
          setTrigger={setSuccess}
          description={text.RecoverPassword.step2AlertSuccess}
        />
      )}
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={10}>
          <form
            onSubmit={handleSubmit(sendResetPasswordForm)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  {text.RecoverPassword.step2Title}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography component="span" style={{ color: "#868CA6" }}>
                  {text.RecoverPassword.step2Description1}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="span" style={{ color: "#868CA6" }}>
                  {text.RecoverPassword.step2Description2}
                </Typography>
              </Grid>

              <Grid item xs={12} style={{ marginTop: 30 }} align="center">
                <TextFieldCustom
                  variant="outlined"
                  labelCustom="Mot de passe"
                  type="password"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={"password"}
                  error={errors.password}
                  InputProps={{
                    className: classes.pwsInput,
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={10} style={{ marginTop: 10 }} align="left">
                <Typography
                  component="span"
                  style={{ color: "#8F96A4", fontSize: 12 }}
                >
                  {!watch("password") && text.Registration.password0}
                  {passwordStrength === 1 && text.Registration.password1}
                  {passwordStrength === 2 && text.Registration.password2}
                  {passwordStrength === 3 && text.Registration.password3}
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <CommonButton
                  text={text.General.continue}
                  loading={loadingApi}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 30, marginBottom: 30 }}>
                <Typography component="span" style={{ color: "#3F53AA" }}>
                  {text.RecoverPassword.step2FooterText}
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default RecoverPasswordStep2;
