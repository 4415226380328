import React from "react";
//---------------- Leaflet ---------------------------
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.js";
import L from "leaflet";

// import marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png",
  //draggable:true,
});

const MapShow = ({ latitude, longitude }) => {
  let mapLatitude = 42.565073194587;
  let mapLongitude = 12.649406606152;

  if (latitude) {
    mapLatitude = latitude;
  }
  if (longitude) {
    mapLongitude = longitude;
  }

  return (
    <Map
      style={{ width: "100%", height: "200px", zIndex: 1 }}
      center={[mapLatitude, mapLongitude]}
      zoom={latitude ? 16 : 13}
      zoomControl={false}
      scrollWheelZoom={false}
      touchZoom={false}
      doubleClickZoom={false}
      boxZoom={false}
      keyboard={false}
      draggable={false}
      dragging={false}
      tap={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>contributors'
      />

      {latitude && longitude ? (
        <Marker position={[latitude, longitude]}>
          {/*<Popup>
            <p>{props.address}</p>
          </Popup>*/}
        </Marker>
      ) : null}
    </Map>
  );
};

export default MapShow;
