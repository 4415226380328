import axios from "axios";

//const baseURL =
//  process.env.REACT_APP_API_ENDPOINT ?? "https://apichat.manciomarket.com/api/";

export const clientCredentialAxios = axios.create({
  baseURL: (axios.defaults.baseURL = "https://apichat.manciomarket.com/api/"),
});

export const authApiAxios = axios.create({
  baseURL: (axios.defaults.baseURL = "https://apichat.manciomarket.com/api/"),
});

authApiAxios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token");
//https://apichat.manciomarket.com/api/
//http://127.0.0.1:8000/api/
