import React from "react";
import Container from "@material-ui/core//Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import text from "../../../utils/text";
import { ChevronRight, Annonce } from "../../../utils/icons";
import { useLocation } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import classes from "./Mission.module.css";

const CreateMissionLayout = ({ md, lg, children, step, setStep }) => {
  const location = useLocation();
  const screenWidth = useWindowWidth();

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <Container>
          <Grid
            container
            spacing={3}
            direction="row"
            style={{
              padding: "20px 20px 20px 20px",
            }}
          >
            <Grid item xs={12} md={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: screenWidth > 960 ? "flex-start" : "center",
                }}
              >
                <div>
                  <Annonce fill="#2A3254" />
                </div>
                <div style={{ padidngLeft: 10, paddingTop: 2 }}>
                  <Typography component="span" style={{ color: "#2A3254" }}>
                    {text.createMission.createMission}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={8} align={"center"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Typography
                    component="span"
                    style={{
                      color: step === 1 ? "#3F53AA" : "#AEB2C3",
                      cursor: step > 1 ? "pointer" : "",
                    }}
                    onClick={() => step > 1 && setStep(1)}
                  >
                    {`${text.createMission.step1Header}`}
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingTop: 2,
                  }}
                >
                  <ChevronRight fill={"#AEB2C3"} />
                </div>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Typography
                    component="span"
                    style={{
                      color: step === 2 ? "#3F53AA" : "#AEB2C3",
                      cursor: step > 2 ? "pointer" : "",
                    }}
                    onClick={() => step > 2 && setStep(2)}
                  >
                    {`${text.createMission.step2Header}`}
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingTop: 2,
                  }}
                >
                  <ChevronRight fill={"#AEB2C3"} />
                </div>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Typography
                    component="span"
                    style={{
                      color: step === 3 ? "#3F53AA" : "#AEB2C3",
                      cursor: step > 3 ? "pointer" : "",
                    }}
                    onClick={() => step > 3 && setStep(3)}
                  >
                    {text.createMission.step3Header}
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingTop: 2,
                  }}
                >
                  <ChevronRight fill={"#AEB2C3"} />
                </div>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Typography
                    component="span"
                    style={{
                      color: step === 4 ? "#3F53AA" : "#AEB2C3",
                      cursor: step > 4 ? "pointer" : "",
                    }}
                    onClick={() => step > 4 && setStep(4)}
                  >
                    {text.createMission.step4Header}
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingTop: 2,
                  }}
                >
                  <ChevronRight fill={"#AEB2C3"} />
                </div>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Typography
                    component="span"
                    style={{
                      color: step === 5 ? "#3F53AA" : "#AEB2C3",
                    }}
                  >
                    {text.createMission.step5Header}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              align={screenWidth > 960 ? "right" : "center"}
            >
              <Link
                to="/"
                style={{
                  padding: "10px 15px 10px 15px",
                  border: "1px solid #D7D9E1",
                  borderRadius: 10,
                  textDecoration: "none",
                }}
              >
                <Typography
                  component="span"
                  variant="span"
                  style={{ color: "#353F6A" }}
                >
                  {text.CreateBusiness.anull}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#353F6A",
          opacity: "10%",
        }}
      />
      <div className={"containerStyleBusiness"}>
        <Container>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ paddingTop: 50 }}
          >
            <Grid
              item
              xs={12}
              md={md}
              lg={lg}
              className={classes.ChildrenContainerStyle}
            >
              {children}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default CreateMissionLayout;
