import React from "react";
import LoadingButton from "../Commons/LoadingButton/LoadingButton";
import Button from "@material-ui/core/Button";

const CommonButton = ({ onClick, loading, text, disabled, isGreyStyle }) => {
  return (
    <>
      {loading ? (
        <LoadingButton />
      ) : (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          className={
            isGreyStyle ? "buttonGeneralStyleGrey" : "buttonGeneralStyle"
          }
          style={{ marginTop: 20 }}
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </Button>
      )}
    </>
  );
};

export default CommonButton;
