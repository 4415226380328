import React, { useState, useEffect } from "react";
import CreateBusinessLayout from "../../../Components/BusinessComponents/CreateBusinessLayout";
import Step1 from "../../../Components/BusinessComponents/CreateMission/Step1";
import Step2 from "../../../Components/BusinessComponents/CreateMission/Step2";
import Step3 from "../../../Components/BusinessComponents/CreateMission/Step3";
import Step4 from "../../../Components/BusinessComponents/CreateMission/Step4";
import Step5 from "../../../Components/BusinessComponents/CreateMission/Step5";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import { Grid, Typography } from "@material-ui/core";
import {
  getJobType,
  getSectorActivity,
} from "../../../Axios/Call/ClientCredential";
import { getMissions, getMissionDetail } from "../../../Axios/Call/AuthApi";
import text from "../../../utils/text";
import CreateMissionLayout from "../../../Components/BusinessComponents/Missions/CreateMissionLayout";
import AuthLayout from "../../../Components/AuthComponents/AuthLayout";
import Step6 from "../../../Components/BusinessComponents/CreateMission/Step6";
import Step7 from "../../../Components/BusinessComponents/CreateMission/Step7";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import Step8 from "../../../Components/BusinessComponents/CreateMission/Step8";

const CreateMission = () => {
  const [step, setStep] = useState(1);
  const [mission, setMission] = useState({});
  const [sectors, setSectors] = useState([]);
  const [templateMission, setTemplateMission] = useState([]);
  const [choosenTemplate, setChoosenTemplate] = useState({});
  const [shifts, setShifts] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [errorApiLoadMission, setErrorApiLoadMission] = useState(false);
  const { missionId } = useParams();
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();

  useEffect(() => {
    window.scroll(0, 0);
    setLoadingApi(true);
    getJobType()
      .then((response) => {
        setJobs(response.data);
        getSectorActivity()
          .then((response) => {
            setSectors(response.data);
            getMissions("templates")
              .then((response) => {
                setTemplateMission(response.data);
                setLoadingApi(false);
              })
              .catch((error) => {
                setErrorApi(true);
                setLoadingApi(false);
              });
          })
          .catch((error) => {
            setErrorApi(true);
            setLoadingApi(false);
          });
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  }, []);

  useEffect(() => {
    if (
      Object.keys(userData).length &&
      userData.type === "responsable" &&
      !userData.can_publish_job
    ) {
      history.push("/dashboard");
    }

    if (userData.has_to_pay) {
      history.push("/dashboard");
    }
  }, [userData]);

  useEffect(() => {
    if (missionId) {
      getMissionDetail(missionId)
        .then((response) => {
          if (response.data.is_template && !response.data.is_draft) {
            setChoosenTemplate(response.data);
          } else if (response.data.is_draft) {
            setMission(response.data);
            setShifts(
              response.data.shifts.map((shift) => {
                return {
                  start_date: moment(shift.start_date),
                  end_date: moment(shift.end_date),
                  day: moment(shift.start_date),
                };
              })
            );
          } else {
            setErrorApiLoadMission(true);
          }
        })
        .catch(() => {
          setErrorApiLoadMission(true);
        });
    }
  }, [missionId]);

  console.log(shifts);

  return step !== 6 && step !== 7 && step !== 8 ? (
    <CreateMissionLayout md={10} lg={6} step={step} setStep={setStep}>
      {errorApiLoadMission && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApiLoadMission}
          description={text.Login.alertError}
        />
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      <Grid container>
        {loadingApi ? (
          <LoadingButton />
        ) : errorApi ? (
          <Typography component="span"></Typography>
        ) : (
          <>
            {step === 1 && (
              <Step1
                setStep={setStep}
                mission={mission}
                setMission={setMission}
                choosenTemplate={choosenTemplate}
              />
            )}
            {step === 2 && (
              <Step2
                setStep={setStep}
                jobs={jobs}
                sectors={sectors}
                mission={mission}
                setMission={setMission}
                templateMission={templateMission}
                setChoosenTemplate={setChoosenTemplate}
                choosenTemplate={choosenTemplate}
                setShifts={setShifts}
              />
            )}
            {step === 3 && (
              <Step3
                setStep={setStep}
                startingDate={mission.starting_date}
                endingDate={mission.ending_date}
                shiftsLoaded={shifts}
                onSubmit={(_shifts) => setShifts(_shifts)}
                mission={mission}
              />
            )}
            {step === 4 && (
              <Step4
                setStep={setStep}
                choosenTemplate={choosenTemplate}
                setMission={setMission}
                mission={mission}
              />
            )}
            {step === 5 && (
              <Step5 setStep={setStep} mission={mission} shifts={shifts} />
            )}
          </>
        )}
      </Grid>
    </CreateMissionLayout>
  ) : (
    <>
      <AuthLayout md={12} lg={6}>
        {step === 6 && <Step6 />}
        {step === 8 && <Step8 />}
        {step === 7 && (
          <Step7 setStep={setStep} mission={mission} shifts={shifts} />
        )}
      </AuthLayout>
    </>
  );
};

export default CreateMission;
