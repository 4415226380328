import React from "react";
import { Typography, Grid, Card } from "@material-ui/core";
import { Check, Ban } from "../../../utils/icons";
import text from "../../../utils/text";
import moment from "moment";

const MissionDetailLeft = ({ mission }) => {
  const isLongTerm =
    moment(mission.ending_date).diff(moment(mission.starting_date), "days") > 7;
  return (
    <Card style={{ padding: 20 }}>
      <Grid container>
        <Grid items xs={12} align="left">
          <Typography component="h1" variant="h5">
            {mission?.title}
          </Typography>
        </Grid>
        <Grid items xs={12} align="left" style={{ marginTop: 20 }}>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
              {mission?.is_long_term_contract ? (
                <Check fill={"#3F53AA"} />
              ) : (
                <Ban fill={"#FD4953"} />
              )}
            </div>
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
              <Typography component="span" style={{ color: "#3F53AA" }}>
                {mission?.is_long_term_contract
                  ? text.MissionDetailByClient.longTerm
                  : text.MissionDetailByClient.notLongTerm}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid items xs={12} align="left">
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
              {mission?.is_cloths_available ? (
                <Check fill={"#3F53AA"} />
              ) : (
                <Ban fill={"#FD4953"} />
              )}
            </div>
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
              <Typography component="span" style={{ color: "#3F53AA" }}>
                {mission?.is_cloths_available
                  ? text.MissionDetailByClient.areClothsAvailable
                  : text.MissionDetailByClient.notAreClothsAvailable}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid
          items
          xs={12}
          style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 10 }}
        />
        <Grid items xs={4} style={{ marginTop: 20 }} align="left">
          <Grid container>
            <Grid items xs={12}>
              <Typography
                component="span"
                style={{ color: "#2A3254", fontWeight: "bolder" }}
              >
                {text.MissionDetailByClient.jobType}
              </Typography>
            </Grid>
            <Grid items xs={12}>
              <Typography component="span" style={{ color: "#868CA6" }}>
                {mission?.job_type?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid items xs={8} style={{ marginTop: 20 }} align="left">
          <Grid container>
            <Grid items xs={12}>
              <Typography
                component="span"
                style={{ color: "#2A3254", fontWeight: "bolder" }}
              >
                {text.MissionDetailByClient.datesMission}
              </Typography>
            </Grid>
            <Grid items xs={12}>
              <Typography component="span" style={{ color: "#868CA6" }}>
                {`${moment(mission?.starting_date).format(
                  "dddd DD MMMM yy"
                )} - ${moment(mission?.ending_date).format("dddd DD MMMM yy")}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          items
          xs={12}
          style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 20 }}
        />
      </Grid>
      <Grid container>
        {isLongTerm && (
          <>
            <Grid item xs={12} md={6}>
              <Grid container>
                {mission?.weekend_frequency && (
                  <>
                    <Grid xs={12} align="left" style={{ marginTop: 10 }}>
                      <Typography
                        component="span"
                        style={{ fontWeight: "bolder" }}
                      >
                        {text.createMission.weekendFrequency}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} align="left">
                      <Typography component="span">
                        {mission?.weekend_frequency}
                      </Typography>
                    </Grid>
                  </>
                )}
                {mission?.weekend_frequency_other && (
                  <Grid item xs={12} align="left">
                    <Typography component="span">
                      {mission?.weekend_frequency_other}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                {mission?.split_time_x_week && (
                  <>
                    <Grid xs={12} align="left" style={{ marginTop: 10 }}>
                      <Typography
                        component="span"
                        style={{ fontWeight: "bolder" }}
                      >
                        {text.createMission.splitTimeLabel}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} align="left">
                      <Typography component="span">
                        {mission?.split_time_x_week}
                      </Typography>
                    </Grid>
                  </>
                )}
                {mission?.split_time_x_week_other && (
                  <Grid item xs={12} align="left">
                    <Typography component="span">
                      {mission?.split_time_x_week_other}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                {mission?.job_frequency && (
                  <>
                    <Grid xs={12} align="left" style={{ marginTop: 10 }}>
                      <Typography
                        component="span"
                        style={{ fontWeight: "bolder" }}
                      >
                        {text.createMission.jobTypeLength}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} align="left">
                      <Typography component="span">
                        {mission?.job_frequency === "full-time"
                          ? text.createMission.fullTime
                          : text.createMission.partTime}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                {mission?.hours_x_week && (
                  <>
                    <Grid xs={12} align="left" style={{ marginTop: 10 }}>
                      <Typography
                        component="span"
                        style={{ fontWeight: "bolder" }}
                      >
                        {text.createMission.hoursWeekly}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} align="left">
                      <Typography component="span">
                        {mission?.hours_x_week}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              items
              xs={12}
              style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 20 }}
            />
          </>
        )}
      </Grid>
      {mission.description ? (
        <>
          <Grid items xs={12} align="left" style={{ marginTop: 20 }}>
            <Typography component="span" style={{ fontWeight: "bolder" }}>
              {text.MissionDetailByClient.descriptionLabel}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
            <Typography component="span">{mission?.description}</Typography>
          </Grid>
        </>
      ) : null}
      {mission.access_modality ? (
        <>
          <Grid items xs={12} align="left" style={{ marginTop: 20 }}>
            <Typography component="span" style={{ fontWeight: "bolder" }}>
              {text.MissionDetailByClient.accessModalityLabel}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
            <Typography component="span">{mission.access_modality}</Typography>
          </Grid>
        </>
      ) : null}
      {mission.security_description ? (
        <>
          <Grid items xs={12} align="left" style={{ marginTop: 20 }}>
            <Typography component="span" style={{ fontWeight: "bolder" }}>
              {text.MissionDetailByClient.securiteLabel}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
            <Typography component="span">
              {mission.security_description}
            </Typography>
          </Grid>
        </>
      ) : null}
    </Card>
  );
};

export default MissionDetailLeft;
