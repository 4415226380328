import React from "react";
import { Typography, Grid, Card } from "@material-ui/core";
import Map from "../../Commons/Map/Map";
import { Check } from "../../../utils/icons";
import text from "../../../utils/text";

const IntentionLetterRight = ({ mission, client }) => {
  return (
    <Card style={{ padding: 20 }}>
      <Grid container>
        <Grid items xs={12} align="left">
          <Typography
            component="span"
            style={{ color: "#868CA6", fontSize: 11 }}
          >
            {text?.MissionDetailByClient?.business}
          </Typography>
        </Grid>
        <Grid
          items
          xs={12}
          style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 10 }}
        />
        <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
          <Typography component="span" style={{ fontWeight: "bolder" }}>
            {mission?.business?.name}
          </Typography>
        </Grid>
        <Grid items xs={12} align="left">
          <Typography component="span">{mission?.business?.address}</Typography>
        </Grid>
      </Grid>
      {Object.keys(client)?.length ? (
        <>
          <Grid items xs={12} align="left" style={{ marginTop: 30 }}>
            <Typography
              component="span"
              style={{ color: "#868CA6", fontSize: 11 }}
            >
              {text.MissionDetailByClient.professionel}
            </Typography>
          </Grid>
          <Grid
            items
            xs={12}
            style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 10 }}
          />
          <Grid items xs={12} align="left" style={{ marginTop: 20 }}>
            <Typography component="span" style={{ fontWeight: "bolder" }}>
              {`${client?.name} ${client?.surname}`}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left">
            <Typography component="span">{mission?.business?.name}</Typography>
          </Grid>
          <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
            <Typography component="span" style={{ color: "#868CA6" }}>
              {client?.email}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left">
            <Typography component="span" style={{ color: "#868CA6" }}>
              {client?.mobile_phone} {client?.fixed_phone}
            </Typography>
          </Grid>
          <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
            <Typography component="span" style={{ color: "#868CA6" }}>
              {mission?.business?.address}
            </Typography>
          </Grid>
          <Grid
            items
            xs={12}
            style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 10 }}
          />
        </>
      ) : null}
    </Card>
  );
};

export default IntentionLetterRight;
