import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import fr from "date-fns/locale/fr";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import text from "../../utils/text";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontWeight: 700,
    color: "#2A3254",
  },
  input: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontSize: 14,
  },
  datePicker: {
    "& .MuiToolbar-root": {
      backgroundColor: "#fd4953 !important",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#fd4953 !important",
    },
    "& .MuiButton-label": {
      color: "#fd4953 !important",
    },
  },
  toolbar: {
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    background: "#fd4953",
  },
}));

const CommonDateTimePicker = ({ field, name, error, label, labelCustom }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        {labelCustom && (
          <Grid items xs={12} align="left">
            <Typography component="span" className={classes.label}>
              {labelCustom}
            </Typography>
          </Grid>
        )}
        <Grid items xs={12}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={fr}
            variant="outlined"
          >
            <KeyboardDatePicker
              ToolbarComponent={(_props) => {
                console.log(_props);
                return (
                  <div className={classes.toolbar}>
                    <Typography gutterBottom variant="subtitle1">
                      {moment(_props.date).format("YYYY")}
                    </Typography>
                    <Typography variant="h6">
                      {moment(_props.date).format("dddd DD MMMM")}
                    </Typography>
                  </div>
                );
              }}
              cancelLabel={text.General.anull}
              margin="normal"
              name={name}
              DialogProps={{ className: classes.datePicker }}
              id="date-picker-dialog"
              label={label}
              format="dd/MM/yyyy"
              value={field.value}
              onChange={(date) => field.onChange(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              error={!!error}
              invalidDateMessage={
                <Typography component="span" style={{ color: "red" }}>
                  {text.General.errorDate}
                </Typography>
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Typography component="span" style={{ color: "red" }}>
        {error?.message}
      </Typography>
    </>
  );
};

export default CommonDateTimePicker;
