import React, { useEffect, useState } from "react";
import Container from "@material-ui/core//Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../../Assets/Images/Logo.svg";
import classes from "./BusinessComponents.module.css";
import text from "../../utils/text";
import { MenuDown, Notification } from "../../utils/icons";
import { useDispatch } from "react-redux";
import * as actionCreator from "../../store/action/index";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useWindowWidth } from "@react-hook/window-size";
import { getNotifications, readNotification } from "../../Axios/Call/AuthApi";
import ModalGeneral from "../Commons/ModalGeneral/ModalGeneral";

const BusinessLayout = ({ children, md, lg }) => {
  const location = useLocation();
  const userData = useSelector((state) => state.user.userData);
  const isToPay = useSelector((state) => state.dashboard.isToPay);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const screenWidth = useWindowWidth();
  const [myAccount, setMyAccount] = useState([
    {
      text: text.navBar.editProfile,
      action: () => history.push("/profile/profile"),
    },
    {
      text: text.navBar.parameterProfile,
      action: () => history.push("/profile/account"),
    },
    {
      text: text.navBar.logout,
      action: () => (
        dispatch(actionCreator.cleanUserData()), history.push("/")
      ),
    },
  ]);
  const [notifications, setNotifications] = useState([]);

  const refreshNotifications = () =>
    getNotifications()
      .then((response) => {
        setNotifications(response.data);
      })
      .catch((error) => {});

  useEffect(() => {
    refreshNotifications();
    const messageHandler = (e) => {
      if (e.data.msg === "new-notification") {
        refreshNotifications();
      }
    };
    navigator.serviceWorker.addEventListener("message", messageHandler);

    const interval = window.setInterval(refreshNotifications, 30000);

    return () => {
      window.clearInterval(interval);
      navigator.serviceWorker.removeEventListener("message", messageHandler);
    };
  }, []);

  const isOwnerWithNoBusiness = () => {
    if (userData?.type === "owner" && !userData?.businesses?.length) {
      return false;
    }

    return true;
  };

  const isResponsableWithNoRight = () => {
    if (userData?.type === "responsable" && !userData?.can_publish_job) {
      return true;
    }

    return false;
  };

  const isResponsable = () => {
    if (userData?.type === "owner") {
      return false;
    }

    return true;
  };

  const onClickNotificationHandler = async (item) => {
    setNotifications(
      notifications.map((not) =>
        not.id === item.id ? { ...not, isNew: false } : { ...not }
      )
    );
    history.push(item.redirect);

    await readNotification(item.id);
    refreshNotifications();
  };

  return (
    <>
      {isToPay && (
        <ModalGeneral
          isOpenModal={isToPay}
          title={"Votre abonnement"}
          description={
            "Toutes les fonctionnalités de Kisoigne seront accessibles une fois le règlement de votre abonnement reçu par nos services. N'hésitez pas à nous contacter à hello@kisoigne.com pour toute question."
          }
          button1Text={"Ok"}
          onClick1={() => dispatch(actionCreator.setModalToPay(false))}
          button2Text={"Anull"}
          onClick2={() => dispatch(actionCreator.setModalToPay(false))}
        />
      )}
      <div style={{ backgroundColor: "white" }}>
        <Container>
          <Grid
            container
            direction="row"
            style={{
              padding: "20px 20px 20px 20px",
            }}
          >
            <Grid item xs={12} md={2}>
              <a href="https://www.kisoigne.com" target="_blank">
                <img src={Logo} className={classes.imageStyle} alt="logo" />
              </a>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.marginTopMobile}
              >
                <Grid
                  item
                  xs={4}
                  sm={isResponsableWithNoRight() ? 3 : 2}
                  style={{ position: "relative" }}
                >
                  <Link to="/dashboard" style={{ textDecoration: "none" }}>
                    <Typography
                      component="span"
                      style={{
                        color:
                          location.pathname === "/dashboard" ||
                          location.pathname === "/profile"
                            ? "#2A3254"
                            : "#AEB2C3",
                      }}
                    >
                      {text.navBar.dashboard}
                    </Typography>
                  </Link>
                  {location.pathname === "/dashboard" ||
                  location.pathname === "/profile" ? (
                    <div className={classes.borderDashboardStyle} />
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={isResponsableWithNoRight() ? 3 : 2}
                  style={{ position: "relative" }}
                >
                  {!isOwnerWithNoBusiness() ? (
                    <Typography
                      component="span"
                      style={{
                        color:
                          location.pathname === "/announces"
                            ? "#2A3254"
                            : "#AEB2C3",
                      }}
                    >
                      {text.navBar.mission}
                    </Typography>
                  ) : (
                    <Link to="/announces" style={{ textDecoration: "none" }}>
                      <Typography
                        component="span"
                        style={{
                          color:
                            location.pathname === "/announces"
                              ? "#2A3254"
                              : "#AEB2C3",
                        }}
                      >
                        {text.navBar.mission}
                      </Typography>
                    </Link>
                  )}
                  {location.pathname === "/announces" ? (
                    <div className={classes.borderDashboardStyle} />
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={isResponsableWithNoRight() ? 3 : 2}
                  style={{ position: "relative" }}
                >
                  {!isOwnerWithNoBusiness() ? (
                    <Typography
                      component="span"
                      style={{
                        color:
                          location.pathname === "/professionist" ||
                          location.pathname.includes("/professionist-dettail")
                            ? "#2A3254"
                            : "#AEB2C3",
                      }}
                    >
                      {text.navBar.clients}
                    </Typography>
                  ) : (
                    <Link
                      to="/professionist"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        component="span"
                        style={{
                          color:
                            location.pathname === "/professionist" ||
                            location.pathname.includes("/professionist-dettail")
                              ? "#2A3254"
                              : "#AEB2C3",
                        }}
                      >
                        {text.navBar.clients}
                      </Typography>
                    </Link>
                  )}
                  {location.pathname === "/professionist" ||
                  location.pathname.includes("/professionist-dettail") ? (
                    <div className={classes.borderDashboardStyle} />
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2}
                  style={{ position: "relative" }}
                  className={classes.marginTopMobile}
                >
                  {!isOwnerWithNoBusiness() ? (
                    <Typography
                      component="span"
                      style={{
                        color:
                          location.pathname === "/businesses/detail"
                            ? "#2A3254"
                            : "#AEB2C3",
                      }}
                    >
                      {text.navBar.businesses}
                    </Typography>
                  ) : !isResponsable() ? (
                    <Link
                      to="/businesses/detail"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        component="span"
                        style={{
                          color:
                            location.pathname === "/businesses/detail" ||
                            location.pathname.includes("/business-detail")
                              ? "#2A3254"
                              : "#AEB2C3",
                        }}
                      >
                        {text.navBar.businesses}
                      </Typography>
                    </Link>
                  ) : null}
                  {location.pathname === "/businesses/detail" ||
                  location.pathname.includes("/business-detail") ? (
                    <div className={classes.borderDashboardStyle} />
                  ) : null}
                </Grid>
                {!isResponsableWithNoRight() ? (
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    style={{
                      borderBottom:
                        location.pathname === "/create-mission"
                          ? "5px solid red"
                          : "none",
                      marginTop: screenWidth > 575 ? -5 : 10,
                    }}
                    className={`${classes.overFunction}`}
                  >
                    {!isOwnerWithNoBusiness() ? (
                      <Typography
                        component="span"
                        className={classes.overfunctiontext}
                      >
                        {text.navBar.createMission}
                      </Typography>
                    ) : (
                      /*<Link
                        to="/create-mission"
                        style={{ textDecoration: "none" }}
                      >*/
                      <Typography
                        component="span"
                        className={classes.overfunctiontext}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (!userData.has_to_pay) {
                            history.push("/create-mission");
                          } else {
                            dispatch(actionCreator.setModalToPay(true));
                          }
                        }}
                      >
                        {text.navBar.createMission}
                      </Typography>
                      //</Link>
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} className={classes.marginTopMobile}>
              <div
                style={{
                  display: "flex",
                  justifyContent: screenWidth > 925 ? "flex-end" : "center",
                }}
              >
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onClick={(event) =>
                    setOpenNotification(
                      openNotification
                        ? false
                        : notifications.length
                        ? event.currentTarget
                        : false
                    )
                  }
                >
                  <Notification fill={"#353F6A"} />
                  {notifications.filter((n) => n.isNew).length ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 10,
                        height: 10,
                        backgroundColor: "#FD4953",
                        top: 0,
                        right: 0,
                        borderRadius: 100,
                      }}
                    />
                  ) : null}
                </div>
                <Menu
                  id="simple-menu"
                  anchorEl={openNotification}
                  keepMounted
                  open={Boolean(openNotification)}
                  onClose={() => setOpenNotification(false)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  style={{ marginTop: 30 }}
                >
                  {notifications?.map((item) => {
                    return (
                      <MenuItem
                        onClick={() => onClickNotificationHandler(item)}
                        style={{
                          whiteSpace: "normal",
                          width: 350,
                          borderBottom: "1px solid #D7D9E1",
                          padding: 15,
                          borderLeft: item.isNew ? "2px solid red" : "",
                        }}
                      >
                        <Typography
                          component="span"
                          style={{
                            cursor: "pointer",
                            color: item.isNew ? "#2A3254" : "#868CA6",
                          }}
                        >
                          {item.text}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Menu>
                <div
                  style={{
                    paddingLeft: 20,
                    paddingTop: 1,
                  }}
                >
                  <Typography component="span">
                    {text.navBar.myAccount}
                  </Typography>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(event) =>
                    setOpenMenu(openMenu ? false : event.currentTarget)
                  }
                >
                  <MenuDown fill={"#353F6A"} />
                </div>

                <Menu
                  id="simple-menu"
                  anchorEl={openMenu}
                  keepMounted
                  open={Boolean(openMenu)}
                  onClose={() => setOpenMenu(false)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  style={{ marginTop: 30 }}
                >
                  {myAccount.map((item) => {
                    return (
                      <MenuItem onClick={item.action}>
                        <Typography component="span">{item.text}</Typography>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={"containerStyle"}>
        <Container>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ paddingTop: 50 }}
          >
            <Grid item xs={12} md={md} lg={lg}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default BusinessLayout;
