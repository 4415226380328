import React, { useEffect, useState } from "react";
import CreateBusinessLayout from "../../../Components/BusinessComponents/CreateBusinessLayout";
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import text from "../../../utils/text";
import {
  getBusiness,
  addResponsable,
  editResponsable,
  deleteResponsable,
} from "../../../Axios/Call/AuthApi";
import { getRoles } from "../../../Axios/Call/ClientCredential";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import ModalGeneral from "../../../Components/Commons/ModalGeneral/ModalGeneral";
import { useHistory, useParams } from "react-router-dom";
import FormAddContact from "../../../Components/BusinessComponents/BusinessContact/FormAddContant";
import { FaEdit } from "react-icons/fa";
import { IoTrashBinSharp } from "react-icons/io5";

const BusinessesContact = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApiPost, setLoadingApiPost] = useState(false);
  const [errorApiPost, setErrorApiPost] = useState(false);
  const [businessesData, setBusinessesData] = useState({});
  const [roles, setRoles] = useState([]);
  const [isEmailError, setIsEmailError] = useState(false);
  const [choosenContact, setChoosenContact] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [cleanData, setCleanData] = useState(false);
  const history = useHistory();
  const { uuid } = useParams();

  const sendFormSector = (data) => {
    setLoadingApiPost(true);
    if (Object.keys(choosenContact).length) {
      editResponsable(choosenContact.id, {
        ...data,
        role_id: data.role,
        business_id: businessesData.id,
        //can_publish_job: data.can_publish_job === "true",
      })
        .then((response) => {
          setLoadingApiPost(false);
          setCleanData(true);
          setBusinessesData({
            ...businessesData,
            contacts: businessesData.contacts.map((contact) =>
              contact.id === choosenContact.id ? { ...data } : { ...contact }
            ),
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setIsEmailError(true);
            setLoadingApiPost(false);
          } else {
            setLoadingApiPost(false);
            setErrorApiPost(true);
          }
        });
    } else {
      addResponsable({
        ...data,
        role_id: data.role,
        business_id: businessesData.id,
        //can_publish_job: data.can_publish_job === "true",
      })
        .then((response) => {
          setLoadingApiPost(false);
          setBusinessesData({
            ...businessesData,
            contacts: businessesData.contacts.concat({ ...response.data }),
          });
          setCleanData(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setIsEmailError(true);
            setLoadingApiPost(false);
          } else {
            setLoadingApiPost(false);
            setErrorApiPost(true);
          }
        });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    setLoadingApi(true);
    getBusiness(uuid)
      .then((response1) => {
        getRoles()
          .then((response2) => {
            setRoles(response2.data);
            setBusinessesData(response1.data);
            setLoadingApi(false);
          })
          .catch(() => {
            setErrorApi(true);
            setLoadingApi(false);
          });
      })
      .catch(() => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  }, []);

  const onDeleteResponsableHandler = () => {
    deleteResponsable(openDeleteModal)
      .then((response) => {
        setBusinessesData({
          ...businessesData,
          contacts: businessesData.contacts.filter(
            (contact) => contact.id !== openDeleteModal
          ),
        });
        setOpenDeleteModal(false);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  return (
    <>
      {openDeleteModal && (
        <ModalGeneral
          title={text.CreateBusiness.modalTitleDeleteContact}
          description={text.CreateBusiness.modalDescriptionDeleteContact}
          isOpenModal={openDeleteModal}
          button1Text={text.General.yes}
          button2Text={text.General.no}
          onClick1={() => onDeleteResponsableHandler()}
          onClick2={() => setOpenDeleteModal(false)}
          isPassword
        />
      )}
      <CreateBusinessLayout md={10} lg={8}>
        {errorApi && (
          <AlertGeneral
            color="danger"
            setTrigger={setErrorApi}
            description={text.General.alertGeneralError}
          />
        )}
        {errorApiPost && (
          <AlertGeneral
            color="danger"
            setTrigger={setErrorApiPost}
            description={text.General.alertGeneralError}
          />
        )}
        <Grid container direction="row">
          {loadingApi ? (
            <Grid items xs={12} align="center">
              <LoadingButton />
            </Grid>
          ) : (
            <>
              <Grid items xs={12} align="left">
                <Typography component="h1" variant="h5" align="left">
                  {`${text.CreateBusiness.contactTitle} ${businessesData.name}`}
                </Typography>
              </Grid>
              <Grid items xs={12} md={6} align="left" style={{ marginTop: 10 }}>
                <Typography
                  component="span"
                  align="left"
                  style={{ color: "#868CA6" }}
                >
                  {text.CreateBusiness.contactDescription}
                </Typography>
              </Grid>
              <Grid items xs={12} md={6} align="left"></Grid>
              <FormAddContact
                sendFormContact={sendFormSector}
                roles={roles}
                loadingApiPost={loadingApiPost}
                isEmailError={isEmailError}
                choosenContact={choosenContact}
                setCleanData={setCleanData}
                cleanData={cleanData}
              />
              <Grid items xs={12} md={6} style={{ marginTop: 30 }}>
                <div
                  style={{
                    width: "100%",
                    height: businessesData?.contacts?.length ? "100%" : "40%",
                    backgroundColor: "#EFF0F3",
                    alignItems: "center",
                  }}
                >
                  {businessesData?.contacts?.length ? (
                    <Grid container style={{ padding: 10 }}>
                      <Grid
                        item
                        xs={12}
                        align="left"
                        style={{ marginBottom: 10 }}
                      >
                        <Typography
                          component="span"
                          style={{
                            fontWeight: "700",
                            color: "#2A3254",
                            fontSize: 18,
                          }}
                        >
                          {text.CreateBusiness.contacts}
                        </Typography>
                      </Grid>
                      {businessesData?.contacts.map((contact) => {
                        return (
                          <>
                            <Grid xs={8} items align="left" key={contact.id}>
                              <Typography
                                component="span"
                                style={{ fontWeight: "700", color: "#2A3254" }}
                              >
                                {contact.name} {contact.surname}
                              </Typography>
                            </Grid>
                            <Grid xs={3} item align="right">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                >
                                  <IoTrashBinSharp
                                    color="#FD4953"
                                    onClick={() =>
                                      setOpenDeleteModal(contact.id)
                                    }
                                    style={{ cursor: "pointer" }}
                                    size={18}
                                  />
                                </div>
                                <div
                                  style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginLeft: 10,
                                  }}
                                >
                                  <FaEdit
                                    color="#2A3254"
                                    size={18}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setChoosenContact(contact)}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid xs={12} items align="left">
                              <Typography
                                component="span"
                                style={{ color: "#2A3254" }}
                              >
                                {contact.role_name}
                              </Typography>
                            </Grid>
                            <Grid
                              xs={12}
                              items
                              align="left"
                              style={{ marginTop: 5 }}
                            >
                              <Typography
                                component="span"
                                style={{ color: "#868CA6" }}
                              >
                                {contact.email}
                              </Typography>
                            </Grid>
                            <Grid xs={12} items align="left">
                              <Typography
                                component="span"
                                style={{ color: "#868CA6" }}
                              >
                                {contact.mobile_phone}
                              </Typography>
                            </Grid>
                            <Grid
                              xs={12}
                              items
                              style={{
                                height: 1,
                                backgroundColor: "#868CA6",
                                marginTop: 10,
                                opacity: "50%",
                                marginBottom: 10,
                              }}
                            ></Grid>
                          </>
                        );
                      })}
                    </Grid>
                  ) : (
                    <div
                      style={{
                        padding: 10,
                        justifyContent: "flex-start",
                        textAlign: "left",
                      }}
                    >
                      <Typography component="span" style={{ color: "#868CA6" }}>
                        {text.CreateBusiness.noContactText}
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </CreateBusinessLayout>
    </>
  );
};

export default BusinessesContact;
