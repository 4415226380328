import React, { useState, useEffect } from "react";
import { Typography, Grid, Card } from "@material-ui/core";
import text from "../../../utils/text";
import HeaderSection from "../../../Components/BusinessComponents/HeaderSection";
import MissionDetailLeft from "../../../Components/BusinessComponents/ProfessionistMissionDetail/MissionDetailLeft";
import MissionDetailRight from "../../../Components/BusinessComponents/ProfessionistMissionDetail/MissionDetailRight";
import classes from "./MissionDetail.module.css";
import moment from "moment";
import IntentionLetter from "../ProfessionistMissionDetail/IntentionLetter";
import IntentionLetterRight from "../ProfessionistMissionDetail/IntentionLetterRight";
import Calendar from "../../Commons/Calendar/Calendar";
import { Back } from "../../../utils/icons";
import { useHistory } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import ClientCard from "../../../Components/BusinessComponents/BusinessDetail/ClientCard";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";

const MissionDetail = ({
  mission,
  client,
  isToBeAccetpted,
  isDetailMissionClient,
  changeProfessionistHanlder,
  resetMissionToLive,
  clients,
  loadingCalendar,
  clientsApproved,
}) => {
  const [section, setSection] = useState("detail");
  const [currentCustomer, setCurrentCustomer] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const [header, setHeader] = useState([
    {
      action: "detail",
      text: text.MissionDetailByClient.header1,
    },
    {
      action: "hours",
      text: text.MissionDetailByClient.header2,
    },
    {
      action: "intention",
      text: text.MissionDetailByClient.header3,
    },
  ]);

  /*useEffect(() => {
    if (isToBeAccetpted) {
      setHeader([
        {
          action: "hours",
          text: text.MissionDetailByClient.header2,
        },
        {
          action: "detail",
          text: text.MissionDetailByClient.header1,
        },
        {
          action: "intention",
          text: text.MissionDetailByClient.header3,
        },
      ]);

      setSection("hours");
    }
  }, [isToBeAccetpted]);*/

  useEffect(() => {
    if (mission.status === "not_started" && !isDetailMissionClient) {
      setHeader([
        {
          action: "detail",
          text: text.MissionDetailByClient.header1,
        },
        {
          action: "hours",
          text: text.MissionDetailByClient.header2,
        },
        {
          action: "intereseted",
          text: text.MissionDetailByClient.header4,
        },
        {
          action: "intention",
          text: text.MissionDetailByClient.header3,
        },
        {
          action: "approved",
          text: text.MissionDetailByClient.header5,
        },
      ]);

      setSection("hours");
    }
  }, [mission.status]);

  const renderStatusMission = () => {
    let missionStatus = "Annonces en ligne";

    if (mission.status === "completed") {
      missionStatus = "Annonces passées";
    }

    if (mission.status === "active") {
      missionStatus = "Annonces en cours";
    }

    return missionStatus;
  };

  const onSetNewClientHandler = (action) => {
    //console.log("length", clients.length);
    //console.log("currentCustomer", currentCustomer);
    //console.log("clients", clients);
    if (action === "add") {
      if (currentCustomer === clients?.length) {
        setCurrentCustomer(0);
        setSelectedCustomer({});
        resetMissionToLive();
      } else {
        setCurrentCustomer(currentCustomer + 1);
      }
    } else {
      if (currentCustomer) {
        setCurrentCustomer(currentCustomer - 1);
      } else {
        //setCurrentCustomer(clients?.length);
        setSelectedCustomer({});
        resetMissionToLive();
        setCurrentCustomer(clients?.length);
        /*if (currentCustomer === 1) {
          setCurrentCustomer(clients?.length);
          setSelectedCustomer({});
          resetMissionToLive();
        } else {*/
        /*}*/
      }
    }
  };

  useEffect(() => {
    if (currentCustomer) {
      setSelectedCustomer(clients[currentCustomer - 1]);
      changeProfessionistHanlder(clients[currentCustomer - 1]?.id);
    }
  }, [currentCustomer]);

  return (
    <Grid
      container
      style={{
        padding: "20px 20px 20px 20px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          onClick={() => history.push("/announces")}
          style={{ cursor: "pointer" }}
        >
          <Back fill="#AEB2C3" />
        </div>
        <div>
          <Typography
            component="span"
            style={{
              textTransform: "uppercase",
              fontSize: 11,
              color: "#868CA6",
            }}
          >
            {text.MissionDetailByClient.announces}
          </Typography>
        </div>
      </div>
      <Grid items xs={12} align="left" style={{ marginBottom: 30 }}>
        <Grid container>
          <Grid items xs={12} md={8}>
            <Typography component="h1" variant="h5" style={{ fontSize: 38 }}>
              {mission?.title}
            </Typography>
          </Grid>
          <Grid
            items
            xs={12}
            md={4}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              paddingLeft: 20,
              paddingTop: windowWidth > 575 ? 0 : 5,
            }}
            align={windowWidth > 575 ? "right" : "center"}
          >
            <Typography
              component="span"
              style={{
                padding: "10px 15px 10px 15px",
                backgroundColor: "#D7D9E1",
                borderRadius: 20,
                color: "#353F6A",
              }}
            >
              {renderStatusMission()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <HeaderSection
        xs={6}
        md={3}
        setSection={setSection}
        section={section}
        header={header}
      />
      <Grid container style={{ marginTop: 20 }} justify="center">
        {section === "detail" && (
          <>
            <Grid
              items
              xs={12}
              md={8}
              className={classes.GridCardStyle}
              style={{ marginTop: 20 }}
            >
              <MissionDetailLeft mission={mission} />
            </Grid>
            <Grid items xs={12} md={4} style={{ marginTop: 20 }}>
              <MissionDetailRight mission={mission} />
            </Grid>
          </>
        )}
        {section === "hours" && (
          <>
            <Grid
              items
              xs={12}
              md={8}
              className={classes.GridCardStyle}
              style={{ marginTop: 20 }}
              align="left"
            >
              <Card className={classes.cardStyle}>
                <Calendar
                  startingDate={mission.starting_date}
                  endingDate={mission.ending_date}
                  shifts={mission.shifts}
                  isDetailMissionClient={isDetailMissionClient}
                  resetMissionToLive={resetMissionToLive}
                  setCurrentCustomer={setCurrentCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                  clientShift={
                    isDetailMissionClient
                      ? isToBeAccetpted
                        ? client?.bookings?.length
                          ? client?.bookings[client?.bookings?.length - 1]
                              .shifts
                          : []
                        : client?.accepted_booking?.shifts
                      : null
                    //client?.bookings?.length
                    //  ? client?.bookings[0]?.shifts
                    //  : null
                  }
                />
              </Card>
            </Grid>
          </>
        )}
        {section === "intention" && (
          <>
            <Grid
              items
              xs={12}
              md={8}
              className={classes.GridCardStyle}
              style={{ marginTop: 20 }}
            >
              <IntentionLetter
                intention={
                  client?.bookings?.length &&
                  client?.bookings[client?.bookings?.length - 1]
                    .intention_letter
                    ? client?.bookings[client?.bookings?.length - 1]
                        .intention_letter
                    : mission?.intention_letter?.description
                }
              />
            </Grid>
            <Grid items xs={12} md={4} style={{ marginTop: 20 }}>
              <IntentionLetterRight mission={mission} client={client} />
            </Grid>
          </>
        )}
        {section === "intereseted" && (
          <>
            {/*
            <Grid items xs={12} align="left">
              <Typography component="h1" variant="h5">
                {text.ProfessionistIterested.title}
              </Typography>
            </Grid>
            {clients?.map((client) => {
              return (
                <Grid items xs={12} md={6} style={{ marginTop: 30 }}>
                  <ClientCard
                    mission={{ ...mission, clients: clients }}
                    section="iterest"
                    client={client}
                  />
                </Grid>
              );
            })}
          */}
            <Grid
              items
              xs={12}
              md={8}
              className={classes.GridCardStyle}
              style={{ marginTop: 20 }}
              align="left"
            >
              <Card className={classes.cardStyle}>
                <Grid container>
                  {mission.status === "not_started" && clients?.length ? (
                    <>
                      <Grid item xs={6} sm={2}>
                        <div>
                          <ArrowBackIosIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => onSetNewClientHandler("decrease")}
                          />
                        </div>
                        <div>
                          <Typography component="span" style={{ fontSize: 12 }}>
                            Professionnel précédent
                          </Typography>
                        </div>
                      </Grid>
                      {windowWidth > 575 && (
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            paddingBottom: 20,
                          }}
                          align="center"
                        >
                          <Typography
                            component="span"
                            style={{ fontSize: windowWidth < 576 ? 12 : 14 }}
                          >
                            {Object.keys(selectedCustomer).length ? (
                              <ClientCard
                                mission={{ ...mission, clients: clients }}
                                section="iterest"
                                client={selectedCustomer}
                              />
                            ) : (
                              "Parcourir les professionnels intéressés"
                            )}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={6} sm={2} align="right">
                        <ArrowForwardIosIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => onSetNewClientHandler("add")}
                          size={14}
                        />
                        <div>
                          <Typography component="span" style={{ fontSize: 12 }}>
                            Professionnel suivant
                          </Typography>
                        </div>
                      </Grid>
                      {windowWidth < 576 && (
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            paddingBottom: 20,
                          }}
                          align="center"
                        >
                          <Typography
                            component="span"
                            style={{ fontSize: windowWidth < 576 ? 12 : 14 }}
                          >
                            {Object.keys(selectedCustomer).length ? (
                              <ClientCard
                                mission={{ ...mission, clients: clients }}
                                section="iterest"
                                client={selectedCustomer}
                              />
                            ) : (
                              "Parcourir les professionnels intéressés"
                            )}
                          </Typography>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Typography>
                        Pas encore de professionnels intéressés
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                    {loadingCalendar ? (
                      <LoadingButton />
                    ) : currentCustomer ? (
                      <Calendar
                        startingDate={mission.starting_date}
                        endingDate={mission.ending_date}
                        shifts={mission.shifts}
                        isToBeAccetpted={isToBeAccetpted}
                        isDetailMissionClient={isDetailMissionClient}
                        resetMissionToLive={resetMissionToLive}
                        setCurrentCustomer={setCurrentCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        booking={
                          isToBeAccetpted
                            ? client?.bookings?.length
                              ? client?.bookings[client?.bookings?.length - 1]
                              : []
                            : client?.accepted_booking
                        }
                        clientShift={
                          isToBeAccetpted
                            ? client?.bookings?.length
                              ? client?.bookings[client?.bookings?.length - 1]
                                  .shifts
                              : []
                            : client?.accepted_booking?.shifts
                          //client?.bookings?.length
                          //  ? client?.bookings[0]?.shifts
                          //  : null
                        }
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </>
        )}
        {section === "approved" &&
          clientsApproved.map((client) => {
            return (
              <Grid md={6}>
                <ClientCard
                  mission={{ ...mission, clients: clientsApproved }}
                  section="iterest"
                  client={client}
                  isPhone
                />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default MissionDetail;
