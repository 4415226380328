import React, { useEffect, useState } from "react";
import CreateBusinessLayout from "../../../Components/BusinessComponents/CreateBusinessLayout";
import { Button, Grid, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { useForm, Controller } from "react-hook-form";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import ChooseAddress from "../../../Components/Commons/ChoosingAddress/ChoosingAddress";
import {
  businesses,
  editBusiness,
  createBusiness,
  deleteBusiness,
} from "../../../Axios/Call/AuthApi";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalGeneral from "../../../Components/Commons/ModalGeneral/ModalGeneral";
import { useWindowWidth } from "@react-hook/window-size";
import { Localisation, User } from "../../../utils/icons";
import { FaEdit } from "react-icons/fa";
import { IoTrashBinSharp } from "react-icons/io5";
import classes from "./Businesses.module.css";
import ContactModal from "../../../Components/BusinessComponents/Businesses/ContactModal";

const Businesses = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [errorCreate, setErrorCreate] = useState(false);
  const [loadingApiPost, setLoadingApiPost] = useState(false);
  const [errorApiPost, setErrorApiPost] = useState(false);
  const [businessesData, setBusinessesData] = useState([]);
  const [choosenBusiness, setChoosenBusiness] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalContact, setModalContact] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();
  const screenWidth = useWindowWidth();
  const { register, handleSubmit, errors, control, setError, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormCreateBusiness = (data) => {
    setErrorCreate(false);
    if (!data?.address) {
      setError("address", {
        message: text.General.required,
      });

      return;
    }

    if (choosenBusiness) {
      setLoadingApiPost(true);
      editBusiness(choosenBusiness.uuid, { name: data.name, ...data.address })
        .then((response) => {
          setLoadingApiPost(false);
          history.push(`/business/${response.data.uuid}/add-sector`);
        })
        .catch((error) => {
          setLoadingApiPost(false);
          setErrorApiPost(true);
        });
    } else {
      if (businessesData.length > 5 && !userData.can_add_more_then_five) {
        setErrorCreate(true);
      } else {
        setLoadingApiPost(true);
        createBusiness({ name: data.name, ...data.address })
          .then((response) => {
            setLoadingApiPost(false);
            history.push(`/business/${response.data.uuid}/add-sector`);
          })
          .catch((error) => {
            setLoadingApiPost(false);
            setErrorApiPost(true);
          });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingApi(true);
    businesses()
      .then((response) => {
        setLoadingApi(false);
        setBusinessesData(response.data);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  }, []);

  useEffect(() => {
    if (choosenBusiness) {
      reset({
        name: choosenBusiness.name,
        address: {
          address: choosenBusiness.address,
          longitude: choosenBusiness.longitude,
          latitude: choosenBusiness.latitude,
          city: choosenBusiness.city,
          cap: choosenBusiness.cap,
          region: choosenBusiness.region,
        },
      });
    }
  }, [choosenBusiness]);

  const onDeleteBusinessHandler = () => {
    deleteBusiness(openDeleteModal)
      .then(() => {
        setOpenDeleteModal(false);
        setBusinessesData(
          businessesData.filter((business) => business.uuid !== openDeleteModal)
        );
      })
      .catch(() => {
        setErrorApi(true);
        setOpenDeleteModal(false);
      });
  };

  return (
    <CreateBusinessLayout md={10} lg={8}>
      {modalContact && (
        <ContactModal
          isOpenModal={modalContact}
          onCloseModal={() => setModalContact(false)}
        />
      )}
      {openDeleteModal && (
        <ModalGeneral
          title={text.CreateBusiness.modalTitleDelete}
          description={text.CreateBusiness.modalDescriptionDelete}
          isOpenModal={openDeleteModal}
          button1Text={text.General.yes}
          button2Text={text.General.no}
          onClick1={() => onDeleteBusinessHandler()}
          onClick2={() => setOpenDeleteModal(false)}
          isPassword
        />
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {errorApiPost && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApiPost}
          description={text.CreateBusiness.errorAlert}
        />
      )}
      {errorCreate && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorCreate}
          description={text.CreateBusiness.tooMuchBusiness}
        />
      )}
      <Grid container>
        <Grid item xs={12} md={6} align="left">
          <Grid container>
            <Grid item xs={12} align="left">
              <Typography component="h1" variant="h5" align="left">
                {text.CreateBusiness.estabilishmentDetail}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
              <Typography
                component="span"
                align="left"
                style={{ color: "#868CA6" }}
              >
                {text.CreateBusiness.estabilishmentDescription}
              </Typography>
            </Grid>
            {!userData?.can_add_more_then_five && (
              <Grid
                item
                xs={12}
                align={screenWidth > 960 ? "left" : "center"}
                style={{ marginTop: 20 }}
              >
                <Typography
                  component="span"
                  className={classes.noMoreThen5}
                  onClick={() => setModalContact(true)}
                >
                  {text.CreateBusiness.noMoreThen5}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={12} align="left">
              <form
                onSubmit={handleSubmit(sendFormCreateBusiness)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                    <TextFieldCustom
                      labelCustom={text.CreateBusiness.estabilishmentLabel}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="name"
                      error={errors.name}
                    />
                  </Grid>

                  <Grid item xs={12} md={10} align="left">
                    <Controller
                      render={(field) => (
                        <ChooseAddress field={field} error={errors.address} />
                      )}
                      name="address"
                      defaultValue={null}
                      rules={{
                        required: text.General.required,
                      }}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Typography component="span">
                      {text.CreateBusiness.addressInfo}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={10}
                    align={screenWidth > 960 ? "left" : "center"}
                  >
                    <CommonButton
                      text={text.General.forward}
                      loading={loadingApiPost}
                    />
                  </Grid>
                  {/*
              <Grid
                item
                xs={12}
                md={10}
                align={screenWidth > 960 ? "left" : "center"}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={"buttonGeneralStyleEmpty"}
                  style={{ marginTop: 20 }}
                  onClick={() => history.push("/dashboard")}
                >
                  {text.General.finish}
                </Button>
              </Grid>
              */}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} align="left">
            <Typography component="h1" variant="h5" align="left">
              {text.CreateBusiness.myBusiness}
            </Typography>
          </Grid>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#EFF0F3",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            {loadingApi ? (
              <div style={{ paddingTop: "30%", paddingBottom: "30%" }}>
                <LoadingButton />
              </div>
            ) : businessesData.length ? (
              <Grid container style={{ padding: 10 }}>
                {businessesData.map((business) => {
                  return (
                    <>
                      <Grid xs={6} item align="left">
                        <Typography
                          component="span"
                          onClick={() => setChoosenBusiness(business)}
                          style={{
                            color: "#2A3254",
                            cursor: "pointer",
                            fontWeight: "700",
                          }}
                        >
                          {business.name}
                        </Typography>
                      </Grid>
                      <Grid xs={3} item align="right">
                        <Typography
                          component="span"
                          style={{ color: "#2A3254" }}
                        >
                          {`${business.total_bed} ${text.CreateBusiness.sits}`}
                        </Typography>
                      </Grid>
                      <Grid xs={3} item align="right">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            <IoTrashBinSharp
                              color="#FD4953"
                              onClick={() => setOpenDeleteModal(business.uuid)}
                              style={{ cursor: "pointer" }}
                              size={18}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: 10,
                            }}
                          >
                            <FaEdit
                              color="#2A3254"
                              size={18}
                              style={{ cursor: "pointer" }}
                              onClick={() => setChoosenBusiness(business)}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid xs={12} item align="left" style={{ marginTop: 10 }}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <Localisation fill={"#868CA6"} />
                          </div>
                          <div>
                            <Typography
                              component="span"
                              style={{ color: "#868CA6" }}
                            >
                              {business.address}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid xs={12} item align="left">
                        <div style={{ display: "flex" }}>
                          <div>
                            <User fill={"#868CA6"} />
                          </div>
                          <div>
                            <Typography
                              component="span"
                              style={{ color: "#868CA6" }}
                            >
                              {userData?.name} {userData?.surname}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        xs={12}
                        item
                        style={{
                          backgroundColor: "grey",
                          height: 1,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      ></Grid>
                    </>
                  );
                })}
              </Grid>
            ) : (
              <div style={{ paddingTop: "30%", paddingBottom: "30%" }}>
                <Typography component="span" style={{ color: "#868CA6" }}>
                  {text.CreateBusiness.yourBusiness}
                </Typography>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </CreateBusinessLayout>
  );
};

export default Businesses;
