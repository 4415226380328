import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import text from "../../../utils/text";
import Background from "../../../Assets/Images/Illu-4.png";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import AuthLayout from "../../../Components/AuthComponents/AuthLayout";
import { useParams, useHistory } from "react-router-dom";
import { confirmEmail } from "../../../Axios/Call/ClientCredential";

const EmailConfirmation = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const { token } = useParams();
  const history = useHistory();

  const checkEmailConfirmation = () => {
    setLoadingApi(true);
    confirmEmail({ email_token: token })
      .then((response) => {
        setLoadingApi(false);
        setEmailConfirmed(true);
        const time = setTimeout(
          () => history.push(`/${response.data.email}`),
          2000
        );
        return () => {
          clearTimeout(time);
        };
      })
      .catch(() => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  };

  return (
    <AuthLayout md={8} lg={8}>
      {emailConfirmed && (
        <AlertGeneral
          color="success"
          setTrigger={setEmailConfirmed}
          description={text.ConfirmEmail.alertSuccess}
        />
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.ConfirmEmail.alertError}
        />
      )}
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={8} lg={7}>
          <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
            {text.ConfirmEmail.title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={7} style={{ marginTop: 40 }}>
          <Typography component="span" style={{ color: "#3F53AA" }}>
            {text.ConfirmEmail.description1}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={7}>
          <Typography component="span" style={{ color: "#3F53AA" }}>
            {text.ConfirmEmail.description2}
          </Typography>
        </Grid>

        <Grid item xs={12} md={8} lg={7} style={{ marginTop: 20 }}>
          <CommonButton
            text={text.ConfirmEmail.buttonText}
            loading={loadingApi}
            onClick={() => checkEmailConfirmation()}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 40 }}>
        <img src={Background} style={{ width: "100%" }} alt="img" />
      </Grid>
    </AuthLayout>
  );
};

export default EmailConfirmation;
