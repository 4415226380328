import React, { useEffect, useState } from "react";
import BusinessLayout from "../../../Components/BusinessComponents/BusinessLayout";
import { Grid, Card, Typography } from "@material-ui/core";
import { businesses } from "../../../Axios/Call/AuthApi";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import text from "../../../utils/text";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const BusinessesDetail = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [businessesData, setBusinessesData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scroll(0, 0);
    setLoadingApi(true);
    businesses()
      .then((response) => {
        setLoadingApi(false);
        setBusinessesData(response.data);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  }, []);

  return (
    <BusinessLayout xs={12} md={12} lg={8}>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      <Grid
        container
        spacing={3}
        style={{
          padding: "20px 20px 20px 20px",
        }}
      >
        <Grid item xs={12} md={6} align="left">
          <CommonButton
            text={text.BusinessDetail.addEditBusiness}
            onClick={() => history.push("/businesses")}
          />
        </Grid>
        {loadingApi ? (
          <Grid item xs={12}>
            <LoadingButton />
          </Grid>
        ) : (
          businessesData.map((business) => {
            return (
              <Grid item xs={12} style={{ marginTop: 20 }} align="left">
                <Card style={{ padding: 20 }}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      padding: "20px 20px 20px 20px",
                    }}
                  >
                    <Grid items xs={10}>
                      <Link
                        to={`/business-detail/${business.uuid}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography component="h1" variant="h5">
                          {business.name}
                        </Typography>
                      </Link>
                    </Grid>
                    {/*<Grid items xs={2} align="right">
                      <Typography
                        component="span"
                        onClick={() => history.push("/businesses")}
                        style={{ cursor: "pointer", color: "#AEB2C3" }}
                      >
                        {text.General.modify}
                      </Typography>
                  </Grid>*/}
                    <Grid items xs={12}>
                      <Typography component="span" style={{ color: "#868CA6" }}>
                        {`${business.address}, ${business.total_bed} ${text.CreateBusiness.sits}`}
                      </Typography>
                    </Grid>

                    <Grid items xs={12} md={6} style={{ marginTop: 20 }}>
                      {business.sector.map((sector) => {
                        return (
                          <Grid
                            container
                            style={{ marginTop: 10 }}
                            key={sector.id}
                          >
                            <Grid items xs={8}>
                              <Typography component="span">
                                {sector.sector.name}
                              </Typography>
                            </Grid>
                            <Grid items xs={4} align="right">
                              <Typography component="span">
                                {sector.bed_number}
                              </Typography>
                            </Grid>
                            <Grid
                              items
                              xs={12}
                              style={{
                                height: 1,
                                backgroundColor: "#D7D9E1",
                                marginTop: 10,
                              }}
                            ></Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid items xs={12} md={1} />
                    <Grid items xs={12} md={5}>
                      <Grid container>
                        <Grid items xs={12}>
                          <Typography
                            component="span"
                            style={{ color: "#FD4953" }}
                          >
                            {business.on_going_mission}{" "}
                            {text.BusinessDetail.onGoingMission}
                          </Typography>
                        </Grid>
                        <Grid items xs={12}>
                          <Typography
                            component="span"
                            style={{ color: "#FD4953" }}
                          >
                            {business.open_mission}{" "}
                            {text.BusinessDetail.missionPublish}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: 10 }}>
                        <Grid
                          items
                          xs={12}
                          style={{ backgroundColor: "#F9F9FA", padding: 20 }}
                        >
                          {business.contacts.map((contact) => {
                            return (
                              <Grid container key={contact.id}>
                                <Grid items xs={12}>
                                  <Typography component="span">
                                    {contact.name} {contact.surname}
                                  </Typography>
                                </Grid>
                                <Grid items xs={12}>
                                  <Typography
                                    component="span"
                                    style={{ color: "#868CA6" }}
                                  >
                                    {contact.mobile_phone}
                                  </Typography>
                                </Grid>
                                <Grid items xs={12}>
                                  <Typography
                                    component="span"
                                    style={{ color: "#868CA6" }}
                                  >
                                    {contact.email}
                                  </Typography>
                                </Grid>
                                <Grid
                                  items
                                  xs={10}
                                  style={{
                                    height: 1,
                                    backgroundColor: "#D7D9E1",
                                    marginTop: 10,
                                  }}
                                ></Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            );
          })
        )}
      </Grid>
    </BusinessLayout>
  );
};

export default BusinessesDetail;
