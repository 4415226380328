import React from "react";
import text from "../../../utils/text";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const BusinessCard = ({ business }) => {
  return (
    <Grid container style={{ marginTop: 10 }}>
      <Grid items xs={12} align="left">
        <Link
          to={`/business-detail/${business.uuid}`}
          style={{ textDecoration: "none" }}
        >
          <Typography
            component="span"
            style={{ color: "#2A3254", fontWeight: 700, cursor: "pointer" }}
          >
            {business?.name}
          </Typography>
        </Link>
      </Grid>
      <Grid items xs={12} align="left">
        <Typography component="span" style={{ color: "#2A3254" }}>
          {business?.address}
        </Typography>
      </Grid>
      <Grid items xs={12} align="left">
        <Typography
          component="span"
          style={{ color: "#AEB2C3" }}
        >{`${business.total_bed} ${text.CreateBusiness.sits}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default BusinessCard;
