import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ScheduleIcon from "@material-ui/icons/Schedule";
import grey from "@material-ui/core/colors/grey";
import text from "../../../utils/text";
import CommonButton from "../../FormComponents/CommonButton";
import moment from "moment";
import { useState } from "react";
import { Fragment } from "react";
import AddHoursMenu from "./AddHoursMenu";
import * as R from "ramda";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
  dayRow: {
    backgroundColor: grey[200],
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0.5, 1),
    minHeight: 62,
  },
  addBtn: {
    width: "100%",
    padding: theme.spacing(1, 2),
    borderColor: grey[700],
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: theme.palette.common.white,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  clipboardBtn: {
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    background: "none",
    border: 0,
    color: grey[300],
    cursor: "pointer",
    transition: "color .2s",
    "&:hover": {
      color: "#FD4953",
    },
    "&:disabled:hover": {
      color: grey[200],
    },
  },
  shift: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  shiftsContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(-1),
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
  },
}));

const defaultMenuTime = {
  startHour: -1,
  endHour: -1,
  startMinute: -1,
  endMinute: -1,
};

export default function AddWeekDialog({ open, onClose, onSubmit }) {
  const classes = useStyles();
  const [shifts, setShifts] = useState([]);
  const [clipboard, setClipboard] = useState(null);
  const [menuAnchiorEl, setMenuAnchiorEl] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const [addMenutime, setAddMennuTime] = useState({ ...defaultMenuTime });
  const [overlapError, setOverlapError] = useState(false);

  const week = [0, 1, 2, 3, 4, 5, 6].map((d) =>
    moment().startOf("week").add(d, "days")
  );

  const handleRemoveShift = (shift) =>
    setShifts(
      shifts.filter(
        (s) =>
          !s.start_date?.isSame(shift.start_date) &&
          !s.end_date?.isSame(shift.end_date)
      )
    );

  const openMenu = (e, date) => {
    setMenuAnchiorEl(e.currentTarget);
    setActiveDate(date);
  };

  const closeMenu = () => {
    setOverlapError(false);
    setMenuAnchiorEl(null);
    setAddMennuTime({ ...defaultMenuTime });
    setActiveDate(null);
  };

  const addShift = () => {
    if (R.any(R.equals(-1), R.values(addMenutime)) || !activeDate) {
      return;
    }

    const startMoment = moment(activeDate)
      .hour(addMenutime.startHour)
      .minute(addMenutime.startMinute);
    const endMoment = moment(activeDate)
      .hour(addMenutime.endHour)
      .minute(addMenutime.endMinute);

    setShifts([
      ...shifts,
      {
        day: moment(activeDate),
        start_date: startMoment,
        end_date: endMoment,
      },
    ]);

    closeMenu();
  };

  useEffect(() => {
    if (R.any(R.equals(-1), R.values(addMenutime)) || !activeDate) {
      return;
    }

    const _shifts = shifts.filter((s) => s.day?.isSame(activeDate, "day"));
    const start_date = moment(activeDate)
      .hour(addMenutime.startHour)
      .minute(addMenutime.startMinute);
    const end_date = moment(activeDate)
      .hour(addMenutime.endHour)
      .minutes(addMenutime.startMinute);

    if (
      R.any(
        (s) => start_date.isBetween(s.start_date, s.end_date, "minutes", "[]"),
        _shifts
      ) ||
      R.any(
        (s) => end_date.isBetween(s.start_date, s.end_date, "minutes", "[]"),
        _shifts
      )
    ) {
      setOverlapError(true);
    } else {
      setOverlapError(false);
    }
  }, [addMenutime, activeDate, shifts]);

  const handleClose = () => {
    setShifts([]);
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(R.groupBy((shift) => shift.day.isoWeekday().toString(), shifts));
    handleClose();
  };

  return (
    <>
      <AddHoursMenu
        anchiorEl={menuAnchiorEl}
        onClose={closeMenu}
        onAddShift={addShift}
        addMenuTime={addMenutime}
        setAddMenuTime={setAddMennuTime}
        activeDate={activeDate}
        overlapError={overlapError}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{text.AddWeekDialog.title}</DialogTitle>
        <DialogContent>
          {week.map((day) => {
            const _shifts = shifts.filter((s) => s.day?.isSame(day, "day"));

            return (
              <Fragment key={day.unix()}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 700,
                      }}
                    >
                      {day.format("dddd")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <button
                      disabled={_shifts.length === 0}
                      className={classes.clipboardBtn}
                      onClick={() =>
                        setClipboard(
                          _shifts.map((s) => ({
                            startHour: s.start_date.hour(),
                            startMinute: s.start_date.minute(),
                            endHour: s.end_date.hour(),
                            endMinute: s.end_date.minute(),
                          }))
                        )
                      }
                    >
                      {text.createMission.copy}
                    </button>
                    <button
                      onClick={() =>
                        setShifts([
                          ...shifts,
                          ...clipboard.map((c) => ({
                            start_date: moment(day)
                              .hour(c.startHour)
                              .minute(c.startMinute),
                            end_date: moment(day)
                              .hour(c.endHour)
                              .minute(c.endMinute),
                            day: moment(day),
                          })),
                        ])
                      }
                      disabled={!clipboard}
                      className={classes.clipboardBtn}
                    >
                      {text.createMission.paste}
                    </button>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" className={classes.dayRow}>
                  <Grid item xs={12} sm={9}>
                    <div className={classes.shiftsContainer}>
                      {_shifts
                        .sort((a, b) => a.start_date.diff(b.start_date))
                        .map((s, index) => (
                          <div key={index} className={classes.shift}>
                            <Typography>
                              {s.start_date.format("HH:mm")} -{" "}
                              {s.end_date.format("HH:mm")}
                            </Typography>
                            <Tooltip title={text.createMission.remove}>
                              <IconButton
                                onClick={() => handleRemoveShift(s)}
                                size="small"
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ))}
                    </div>
                    {_shifts.length === 0 && (
                      <Box marginTop={1}>
                        <Typography style={{ color: grey[500] }}>
                          {text.missions.pasDePlageHorair}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid xs={12} sm={3} item>
                    <button
                      className={classes.addBtn}
                      onClick={(e) => openMenu(e, day)}
                      type="button"
                    >
                      <ScheduleIcon />
                      <Typography>{text.General.add}</Typography>
                    </button>
                  </Grid>
                </Grid>
              </Fragment>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CommonButton
                text={text.General.anull}
                loading={false}
                onClick={handleClose}
                isGreyStyle={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CommonButton
                text={text.General.forward}
                loading={false}
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
