import React from "react";
import { Typography, Grid, Card } from "@material-ui/core";
import Map from "../../Commons/Map/Map";
import { Check } from "../../../utils/icons";
import text from "../../../utils/text";

const MissionDetailRight = ({ mission }) => {
  return (
    <Card style={{ padding: 20 }}>
      <Grid container>
        <Grid items xs={12} align="left">
          <Typography
            component="span"
            style={{ color: "#868CA6", fontSize: 11 }}
          >
            {text.MissionDetailByClient.business}
          </Typography>
        </Grid>
        <Grid
          items
          xs={12}
          style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 10 }}
        />
        <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
          <Typography component="span" style={{ fontWeight: "bolder" }}>
            {mission?.business?.name}
          </Typography>
        </Grid>
        <Grid items xs={12} align="left">
          <Typography component="span">{mission?.business?.address}</Typography>
        </Grid>
        <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
          <Map
            latitude={mission?.business?.latitude}
            longitude={mission?.business?.longitude}
          />
        </Grid>
      </Grid>
      <Grid items xs={12} align="left" style={{ marginTop: 30 }}>
        <Typography component="span" style={{ color: "#868CA6", fontSize: 11 }}>
          {text.MissionDetailByClient.contactRef}
        </Typography>
      </Grid>
      <Grid
        items
        xs={12}
        style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 10 }}
      />
      <Grid items xs={12} align="left" style={{ marginTop: 20 }}>
        <Typography component="span" style={{ fontWeight: "bolder" }}>
          {`${mission?.responsable?.name} ${mission?.responsable?.surname}`}
        </Typography>
      </Grid>
      <Grid items xs={12} align="left">
        <Typography component="span">
          {mission?.responsable?.role?.name}
        </Typography>
      </Grid>
      <Grid items xs={12} align="left" style={{ marginTop: 10 }}>
        <Typography component="span" style={{ color: "#868CA6" }}>
          {mission?.responsable?.email}
        </Typography>
      </Grid>
      <Grid items xs={12} align="left">
        <Typography component="span" style={{ color: "#868CA6" }}>
          {mission?.responsable?.mobile_phone}{" "}
          {mission?.responsable?.fixed_phone}
        </Typography>
      </Grid>
      <Grid
        items
        xs={12}
        style={{ height: 1, backgroundColor: "#D7D9E1", marginTop: 10 }}
      />
    </Card>
  );
};

export default MissionDetailRight;
