import React, { useEffect } from "react";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import { formatErrorEmail } from "../../../utils/formValidation";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { useForm, Controller } from "react-hook-form";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import text from "../../../utils/text";
import { useHistory, useParams } from "react-router-dom";

const FormAddContact = ({
  sendFormContact,
  roles,
  loadingApiPost,
  isEmailError,
  isModal,
  setAddNewResponsable,
  choosenContact,
  cleanData,
  setCleanData,
}) => {
  const { register, handleSubmit, errors, control, setError, reset, watch } =
    useForm({
      mode: "onBlur",
    }); // initialise the hook
  const history = useHistory();

  useEffect(() => {
    if (isEmailError) {
      setError("email", {
        message: text.General.emailTooken,
      });
    }
  }, [isEmailError]);

  console.log("Publicare job", watch("can_publish_job"));

  useEffect(() => {
    if (choosenContact && Object.keys(choosenContact).length) {
      reset({ ...choosenContact, role: choosenContact?.role_id?.toString() });
    }
  }, [choosenContact]);

  useEffect(() => {
    if (cleanData) {
      reset({});
      setCleanData(false);
    }
  }, [cleanData]);
  return (
    <Grid items xs={12} md={isModal ? 12 : 6} align="left">
      <form
        onSubmit={handleSubmit(sendFormContact)}
        style={{ marginTop: 20 }}
        noValidate
      >
        <Grid container spacing={isModal ? 2 : 0}>
          <Grid item xs={12} md={isModal ? 6 : 10} style={{ marginTop: 10 }}>
            <TextFieldCustom
              labelCustom={text.CreateBusiness.nameLabel}
              type="input"
              ref={register({
                required: text.General.required,
              })}
              defaultValue=""
              name="surname"
              error={errors.surname}
            />
          </Grid>
          <Grid item xs={12} md={isModal ? 6 : 10} style={{ marginTop: 10 }}>
            <TextFieldCustom
              labelCustom={text.CreateBusiness.surnameLabel}
              type="input"
              ref={register({
                required: text.General.required,
              })}
              defaultValue=""
              name="name"
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={isModal ? 6 : 10} style={{ marginTop: 10 }}>
            <TextFieldCustom
              labelCustom={text.CreateBusiness.emailLabel}
              type="email"
              ref={register({
                required: text.General.required,
                validate: {
                  formatErrorEmail,
                },
              })}
              defaultValue=""
              name="email"
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12} md={isModal ? 6 : 10} style={{ marginTop: 10 }}>
            <TextFieldCustom
              labelCustom={text.CreateBusiness.phoneLabel}
              type="input"
              ref={register({
                required: text.General.required,
              })}
              defaultValue=""
              name="mobile_phone"
              error={errors.mobile_phone}
            />
          </Grid>
          {isModal && <Grid md={3} />}
          <Grid items xs={12} md={isModal ? 6 : 10} style={{ marginTop: 10 }}>
            <Controller
              render={(field) => (
                <CommonSelect
                  field={field}
                  name={"role"}
                  labelCustom={text.CreateBusiness.roleLabel}
                  options={roles}
                  error={errors?.role}
                />
              )}
              name="role"
              defaultValue={""}
              rules={{
                required: text.General.required,
              }}
              control={control}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{ marginTop: 10 }}
            align={isModal ? "center" : "left"}
          >
            <FormControlLabel
              control={
                <Controller
                  render={(field) => (
                    <Checkbox
                      name="can_publish_job"
                      color="primary"
                      value={true}
                      onChange={(event) =>
                        field.onChange(event?.target?.checked)
                      }
                      //defaultChecked={choosenContact?.can_publish_job}
                    />
                  )}
                  defaultValue={false}
                  //choosenContact?.can_publish_job
                  name={"can_publish_job"}
                  control={control}
                />
              }
              label={text.CreateBusiness.authorizePublish}
            />
          </Grid>

          {isModal ? (
            <>
              <Grid items xs={12} md={3} />
              <Grid items xs={12} md={6} align="center">
                <CommonButton
                  text={text.CreateBusiness.addContact}
                  loading={loadingApiPost}
                />
              </Grid>
              <Grid items xs={12} md={3} />
              <Grid items xs={12} md={3} />
              <Grid items xs={12} md={6} align="center">
                <CommonButton
                  text={text.General.anull}
                  isGreyStyle
                  onClick={() => setAddNewResponsable(false)}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid items xs={12} md={10} align="left">
                <CommonButton
                  text={
                    Object.keys(choosenContact).length
                      ? text.CreateBusiness.editContact
                      : text.CreateBusiness.addContact
                  }
                  loading={loadingApiPost}
                  isGreyStyle
                />
              </Grid>
              <Grid items xs={12} md={10} align="left">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={"buttonGeneralStyleGrey"}
                  style={{ marginTop: 20 }}
                  onClick={() => history.push("/businesses")}
                >
                  {text.CreateBusiness.addAnotherBusiness}
                </Button>
              </Grid>
              <Grid items xs={12} md={10} align="left">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={"buttonGeneralStyle"}
                  style={{ marginTop: 20 }}
                  onClick={() => history.push("/dashboard")}
                >
                  {text.General.finish}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Grid>
  );
};

export default FormAddContact;
