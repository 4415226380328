import * as actionsTypes from "./actionTypes";

export const populateDashboard = (data) => {
  return {
    type: actionsTypes.POPULATE_DASHBOARD,
    dashboard: data,
  };
};

export const setModalToPay = (data) => {
  return {
    type: actionsTypes.SET_MODAL_PAY,
    isToPay: data,
  };
};
