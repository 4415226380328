import React from "react";
import { Grid, Typography, Card } from "@material-ui/core";
import text from "../../../utils/text";
import { Link } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";

const CardMissionDraftTemplate = ({ mission, section }) => {
  const windowWidth = useWindowWidth();
  return (
    <Card style={{ padding: "10px 20px 10px 20px" }}>
      <Grid container>
        <Grid items xs={12} sm={6}>
          <Grid container>
            <Grid items xs={12} align="left">
              <Typography component="h1" variant="h5">
                {mission?.title}
              </Typography>
            </Grid>
            <Grid items xs={12} align="left">
              <Typography component="span">
                {mission?.business?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          items
          xs={12}
          sm={6}
          style={{ marginTop: "auto", marginBottom: "auto", paddingTop: 10 }}
          align={windowWidth > 575 ? "right" : "center"}
        >
          <Link
            to={`/create-mission/${mission.id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              component="span"
              style={{
                padding: "5px 10px 5px 10px",
                border: "1px solid grey",
                borderRadius: 10,
                color: "#353F6A",
              }}
            >
              {section === "draft"
                ? text.missions.restart
                : text.missions.useThisModel}
            </Typography>
          </Link>
        </Grid>
        {section === "draft" && (
          <>
            <Grid items xs={6} align="left" style={{ marginTop: 10 }}>
              <Typography component="span" style={{ color: "#FD4953" }}>
                {text.missions.advancement}
              </Typography>
            </Grid>
            <Grid items xs={6} align="right" style={{ marginTop: 10 }}>
              <Typography component="span" style={{ color: "#FD4953" }}>
                {mission.completed}
              </Typography>
            </Grid>
            <Grid
              items
              xs={12}
              style={{
                marginTop: 2,
                borderRadius: 10,
                backgroundColor: "#D7D9E1",
              }}
            >
              <div
                style={{
                  width: mission.completed,
                  height: 3,
                  backgroundColor: "#FD4953",
                }}
              ></div>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default CardMissionDraftTemplate;
